import React from 'react';
import Icon24Camera from "@vkontakte/icons/dist/24/camera";

const mapTypeToDesc = new Map()
    .set("tiles", "Рекомендуемый размер изображения 480x720 пикселей")
    .set("tiles_square", "Рекомендуемый размер изображения 480x480 пикселей");

const Image = (props) => {
    const elem = props.elem;
    const styleClass = props.styleClass;

    const NonIconComponent = () => {
        return <div
            onClick={() => {
                props.onClick()
            }}
            className={styleClass}>
            <div style={{textAlign: "center"}}>
                <Icon24Camera style={{margin: "0 auto"}}/>
                {mapTypeToDesc.get(props.type)}
            </div>
        </div>
    };

    const IconComponent = () => {
        return <img
            onClick={() => {
                props.onClick()
            }}
            className={styleClass}
            style={{borderRadius: "4px"}}
            src={elem.icon_url}
            alt={""}
        />
    };

    const UserIconComponent = () => {
        return <img
            onClick={() => {
                props.onClick()
            }}
            className={styleClass}
            src={elem.icon_url}
            alt={""}
        />
    };

    if (!elem.icon_id) {
        return NonIconComponent();
    }
    if (elem.icon_id[0] === "i" || elem.icon_id[0] === "c")
        return UserIconComponent();

    return IconComponent();
};
export default Image;
