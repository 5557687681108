import React from 'react';
import {Button, Placeholder, Text} from "@vkontakte/vkui";
import {Icon24Add, Icon56UsersOutline} from "@vkontakte/icons";
import {PAGES} from "../../../../config/routes";
import {useIsAdmin} from "../../../../redux/selectors";
import {useRouter} from "@happysanta/router";

export const NoSubscriptions = () => {
    const router = useRouter();
    const isAdmin = useIsAdmin();

    if (isAdmin) {
        return (
            <Placeholder
                icon={<Icon56UsersOutline/>}
                header={"Подписные страницы отсутствуют"}
                action={<Button size="m"
                                before={<Icon24Add/>}
                                onClick={() => router.pushPage(PAGES.SUBSCRIPTION_EDITOR)}>
                    <Text weight={"regular"}>Создать страницу</Text>
                </Button>}
            >
                Нажмите кнопку ниже, чтобы создать страницу прямо в приложении
            </Placeholder>
        );
    }

    return (
        <Placeholder
            icon={<Icon56UsersOutline/>}
            header="Рассылки пока отсутствуют"
        >
            Скоро здесь появится много полезного
        </Placeholder>
    );
};