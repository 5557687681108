import React, {useEffect, useState} from "react";
import {Spinner} from "@vkontakte/vkui";
import * as PropTypes from "prop-types";

const VideoPlayer = (props) => {
    const {config} = props;
    const [loaded, setLoaded] = useState(false);
    const [ready, setReady] = useState(false);
    const [player, setPlayer] = useState(null);
    const isPlayerjsInWindow = () => !!window.Playerjs;

    const loadPlayerjs = () => {
        const script = document.createElement("script");
        script.src = './libs/playerjs.js';
        script.async = true;
        document.body.appendChild(script);
        script.onload = () => {
            setLoaded(true);
        }
    }

    const onReady = () => {
        window.playerReady = null;
        setReady(true);
    }

    const initPlayer = () => {
        setReady(false);
        window.playerReady = () => onReady();
        let newPlayer = new window.Playerjs({
            id: 'player',
            'ready': "playerReady",
        });

        setPlayer(newPlayer);
    }

    useEffect(() => {
        if (isPlayerjsInWindow()) {
            setLoaded(true);
            setReady(true);
            return;
        }
        loadPlayerjs();

        return () => {
            setReady(false);
        }
    }, []);

    useEffect(() => {
        if (!loaded) return;
        initPlayer();
    }, [loaded]);

    useEffect(() => {
        if (!player || !ready) return;
        props.onPlayerRendered();

        player.api("file", config.file);
        player.api("loop", config.loop ? 1 : 0);
        player.api(config.mute ? "mute" : "unmute");
        player.api(config.play ? "play" : "stop");
    }, [config.file, ready]);

    if (!loaded) return <Spinner/>;

    return <div id="player"/>;
}

VideoPlayer.propTypes = {
    config: PropTypes.shape({
        file: PropTypes.string.isRequired,
        mute: PropTypes.bool.isRequired,
        loop: PropTypes.bool.isRequired,
        play: PropTypes.bool.isRequired,
    }),
    onPlayerRendered: PropTypes.func,
}

export default VideoPlayer;