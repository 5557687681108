import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    Alert,
    Avatar,
    Button,
    Div,
    File,
    FormItem,
    FormLayout,
    Group,
    Input,
    Radio,
    ScreenSpinner,
    Separator,
    Spacing,
    Text,
    withModalRootContext
} from "@vkontakte/vkui";
import {Icon24Document, Icon24Gallery, Icon24User} from '@vkontakte/icons';
import {hidePopout, setActiveModal, showPopout} from "../../../../redux/root/rootReducer";
import {setRowIconId, setRowIconType, setRowIconUrl} from "../../../../redux/parts/widgetListReducer";
import s from "../../panels/widgetStyles.module.css";
import WidgetsService from "../../../../common/logic/WidgetsService";
import {widgetsAPI} from "../../../../api/widgets/widgetsAPI";
import {AlertText, AlertTitle} from "../../../../common/components/Alerts/Components";

const mapTypeToDesc = new Map()
    .set("list", "Добавьте изображение. Рекомендуемый размер 150х150 пикселей.")
    .set("compact_list", "Добавьте изображение. Рекомендуемый размер 150х150 пикселей.")
    .set("tiles", "Добавьте изображение. Рекомендуемый размер изображения 480x720 пикселей.")
    .set("tiles_square", "Добавьте изображение. Рекомендуемый размер изображения 480x480 пикселей.");

const ModalContentUploadPhoto = (props) => {
    const widgetType = useSelector(state => state.widget);
    const dispatch = useDispatch();
    // true - file , false - by ID
    const [activeForm, setActiveForm] = useState(true);
    const type = WidgetsService.getWidgetType(widgetType);

    const showPopoutMessage = (title, message = '') => {
        const onClose = () => dispatch(hidePopout());

        dispatch(showPopout(<Alert
            actions={[{
                title: 'Продолжить',
                autoclose: true,
                mode: 'cancel'
            }]}
            onClose={onClose}
        >
            <AlertTitle>{title}</AlertTitle>
            <Spacing size={12}/>
            <AlertText>{message}</AlertText>
        </Alert>));
    };

    return (
        <Group>
            <div className={s.modalContainer}>
                <div
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        setActiveForm(true);
                        props.updateModalHeight();
                    }}
                    className={`${s.modalContainerIcon}`}
                >
                    <Avatar size={72} className={activeForm && 'border-accent'} src=""><Icon24Gallery style={{color: "black"}}/></Avatar>
                    <span>Фото</span>
                </div>
                <div
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        setActiveForm(false);
                        props.updateModalHeight();
                    }}
                    className={`${s.modalContainerIcon}`}
                >
                    <Avatar size={72} className={!activeForm && 'border-accent'}>
                        <Icon24User style={{color: "black"}}/>
                    </Avatar>
                    <span>по ID</span>
                </div>
            </div>
            <br/>
            <Separator/>
            {activeForm ?
                <UploadIconFromFile
                    onSave={props.onSave}
                    type={type}
                    showPopoutMessage={showPopoutMessage}
                /> :
                <UploadIconById
                    onSave={props.onSave}
                    type={type}
                    updateModalHeight={props.updateModalHeight}
                    showPopoutMessage={showPopoutMessage}
                />
            }

        </Group>
    )
};
export default withModalRootContext(ModalContentUploadPhoto);

const UploadIconFromFile = (props) => {
    const type = props.type;
    const currentRow = useSelector(state => state.listWidget.currentRow);
    const dispatch = useDispatch();
    const showPopoutMessage = props.showPopoutMessage;

    const onChange = async (event, size = '0') => {
        try {
            dispatch(showPopout(<ScreenSpinner/>));

            const targetFile = event.target.files[0];
            const allowTypes = ['image/png', 'image/jpeg'];

            if (!targetFile) {
                showPopoutMessage('Файл не выбран');
                return;
            }
            const isAllowFileType = allowTypes.includes(targetFile.type);

            if (!isAllowFileType) {
                showPopoutMessage('Недопустимый файл', 'Для загрузки файла выберите изображение в формате (jpg, jpeg, png)');
                return;
            }

            try {
                const response = await widgetsAPI.uploadIcon({file: targetFile, size, type});
                dispatch(hidePopout());
                if (type) {
                    dispatch(setRowIconId(currentRow, response.icon));
                    dispatch(setRowIconUrl(currentRow, response.images[2].url));
                    dispatch(setRowIconType(currentRow, null));
                    dispatch(setActiveModal(null))
                }
                return true;
            } catch (e) {
                showPopoutMessage('Ошибка', e.text);
            }
        } catch (e) {
            console.log(e);
        }
    };

    return <FormLayout>
        <FormItem style={{marginBottom: "10px"}}>
            <Div style={{textAlign: "center"}}>
                {mapTypeToDesc.get(type)}
                {mapTypeToDesc.get(type) && <br/>}
                Изображения должны быть <i>одного типа</i>.
                <br/>
                {!mapTypeToDesc.get(type) ? <>
                        Изображения являются <span style={{fontWeight: 700}}>обязательным</span> элементом<br/>
                    </> :
                    <>
                        Изображения должны быть у <span style={{fontWeight: 700}}>каждого элемента
                        </span> или <span style={{fontWeight: 700}}>не быть ни у одного из них</span>.
                    </>
                }

                <File
                    accept='image/jpeg,image/png'
                    before={<Icon24Document/>}
                    className={s.buttonFileChoose}
                    mode="secondary"
                    onChange={onChange}
                />
            </Div>
        </FormItem>
    </FormLayout>
};

const UploadIconById = (props) => {

    const [additionalId, setAdditionalId] = useState("");
    const [radioValue, setRadioValue] = useState("user_avatar");
    const showPopoutMessage = props.showPopoutMessage;

    const checkFunc = () => {
        if ((radioValue !== "user_avatar" && radioValue !== "group_avatar") && additionalId === null) {
            showPopoutMessage('Ошибка', 'Введите ID');
        } else props.onSave(radioValue, additionalId);
    };

    return (
        <FormLayout>
            <div>
                <Radio
                    name="radio"
                    value="user_avatar"
                    description="Отобразит аватар посетителя"
                    onClick={(e) => {
                        setRadioValue(e.target.value);
                        props.updateModalHeight();
                    }}
                    defaultChecked
                >
                    Посетитель
                </Radio>
                <Radio
                    name="radio"
                    value="some_user_avatar"
                    onClick={(e) => {
                        setRadioValue(e.target.value);
                        props.updateModalHeight();
                    }}
                    description="Отобразит аватар указанного пользователя"
                >
                    Пользователь
                </Radio>
                <Radio
                    name="radio"
                    value="group_avatar"
                    onClick={(e) => {
                        setRadioValue(e.target.value);
                        props.updateModalHeight();

                    }}
                    description="Отобразит аватар вашего сообщества"
                >
                    Сообщество
                </Radio>
                <Radio
                    name="radio"
                    value="some_group_avatar"
                    onClick={(e) => {
                        setRadioValue(e.target.value);
                        props.updateModalHeight();
                    }}
                    description="Отобразит аватар другого сообщества"
                >
                    Другое сообщество
                </Radio>

                {(radioValue === "some_group_avatar" || radioValue === "some_user_avatar") &&
                <Input
                    style={{marginBottom: 15, marginTop: 10, marginLeft: 16, marginRight: 16}}
                    value={additionalId}
                    onChange={(e) => {
                        const value = e.target.value;
                        if (value.length > 0 && !value.match(/^\d+$/)) return;
                        setAdditionalId(value.trim().toString());
                        props.updateModalHeight();
                    }}
                />}

                <FormItem>
                    <Button size="l" stretched onClick={checkFunc}>
                        <Text weight={"regular"}>Сохранить</Text>
                    </Button>
                </FormItem>
            </div>
        </FormLayout>
    )
};

