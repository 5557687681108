import React, {useEffect} from 'react';
import useCheckConnection from "./hooks/useCheckConnection";
import {getHashObject} from "./helpers/functions";
import {PAGES} from "../config/routes";
import useLoadSettings from "./hooks/useLoadSettings";
import {useRouter} from "@happysanta/router";

const BaseInit = props => {
    const checkConnection = useCheckConnection();
    const loadSettings = useLoadSettings();
    const router = useRouter();

    const init = async () => {
        const res = await checkConnection();
        if (!res) {
            try {
                router.start();
            } catch (e) {
            }
            return;
        }

        await loadSettings();
        try {
            router.start();
        } catch (e) {
        }
        const hash = getHashObject();
        if (Object.keys(hash)[0] === '/') {
            router.replacePage(PAGES.SUBSCRIPTIONS);
        }
    }

    useEffect(() => {
        init();
    }, [])

    return null;
};

BaseInit.propTypes = {

};

export default BaseInit;
