import {widgetsAPI} from "../../api/widgets/widgetsAPI";
import {removeSpanFromField} from "../helpers/functions";
import {CURRENT_WIDGET, mapWidgetToType} from "../../redux/parts/widgetReducer";
import {getWidgetToken} from "../helpers/bridge";

class WidgetsService {
    static async getToken() {
        const data = await getWidgetToken();
        await widgetsAPI.saveToken(data.access_token);
    };

    static getWidgetType = (widgetState) => {
        const widgetType = widgetState.type;
        if (widgetType === CURRENT_WIDGET)
            return widgetState.current.type;
        else
            return mapWidgetToType.get(widgetType)
    };

    static async saveTextWidgetOnServer(widgetState, appState, status = false) {
        const data = {
            ...this.createFormWidget(widgetState, appState, "text"),
            text: removeSpanFromField(widgetState.text),
            description: removeSpanFromField(widgetState.description),
            id: widgetState.id,
        };
        if (status) {
            data.status = 0
        }
        await widgetsAPI.save(data)
    };

    static async saveNONTextWidgetOnServer(widgetState, widgetListState, app, type, status) {
        const data = {
            ...this.createFormWidget(widgetState, app, type),
            rows: JSON.stringify(widgetListState.rows),
            id: widgetState.id,
        };
        if (status) {
            data.status = 0
        }
        await widgetsAPI.save(data)
    };

    static createFormWidget(widgetState, appState, type) {
        return {
            id: widgetState.id,
            type,
            name: widgetState.name,
            title: widgetState.title,
            title_counter: widgetState.titleCount,
            title_url: widgetState.linkTitle !== "" ? widgetState.linkTitle : undefined,
            more: widgetState.footerText,
            more_url: widgetState.footerLink
        };
    };

    static async uploadCoverListIcon(file) {
        return await widgetsAPI.uploadIcon({
            file,
            size: 0,
            type: 'cover_list'
        });
    };
}

export default WidgetsService;