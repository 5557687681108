import React, {useState} from "react";
import {Button, FormItem, FormLayout, Group, Panel, Text, Textarea} from "@vkontakte/vkui";
import THPanelHeader from "../../../../common/components/THPanelHeader";
import {string} from "prop-types";
import useUpdateSettings from "../../../../common/hooks/useUpdateSettings";
import {useSelectorGroup, useSelectorSettings} from "../../../../redux/selectors";
import {isValid, remBr, unescapeHtml} from "../../../../common/helpers/functions";
import BannerUploadField from "../../../../common/components/forms/BannerUploadField";
import {showSnackbar} from "../../../../redux/root/rootReducer";
import {useDispatch} from "react-redux";

const Setting = props => {
    const dispatch = useDispatch();
    const group = useSelectorGroup();
    const settings = useSelectorSettings();
    const updateSettings = useUpdateSettings();
    const [banner, setBanner] = useState(settings?.banner ?? '');
    const [loading, setLoading] = useState(false);
    const [subTitle, setSubTitle] = useState(group?.rawSubTitle ? unescapeHtml(remBr(group.rawSubTitle)) : '');

    const validationRules = [
        subTitle.length <= 200,
    ]

    const onSubmit = async () => {
        if (!isValid(validationRules)) {
            dispatch(showSnackbar({
                text: "Проверьте правильность заполнения полей"
            }));
            setLoading(false);
            return;
        }
        await updateSettings({
            subTitle,
            banner,
        }, {setLoading});
    }
    return (
        <Panel id={props.id}>
            <THPanelHeader/>
            <Group>
                <FormLayout>
                    <BannerUploadField
                        title={"Баннер для страницы рассылок"}
                        banner={banner}
                        setBanner={setBanner}
                    />
                    <FormItem
                        top="Описание для главной страницы"
                        status={subTitle.length > 200 ? "error" : ""}
                        bottom={subTitle.length + "/200"}
                    >
                        <Textarea
                            value={subTitle ? subTitle : ""}
                            maxLength={200}
                            onChange={(e) => {
                                setSubTitle(e.target.value)
                            }}
                        />
                    </FormItem>
                    <FormItem>
                        <Button size="l" stretched onClick={onSubmit} loading={loading}>
                            <Text weight={"regular"}>Сохранить</Text>
                        </Button>
                    </FormItem>
                </FormLayout>
            </Group>
        </Panel>
    )
}

Setting.propTypes = {
    id: string.isRequired,
}

export default Setting;