import {appAPI} from "../../api/app/appAPI";
import {APIErrorCodes} from "../../config/api/constant";
import {PAGES} from "../../config/routes";
import {setCheckedConnection, showSnackbar} from "../../redux/root/rootReducer";
import {useDispatch} from "react-redux";
import {useRouter} from "@happysanta/router";

const useCheckConnection = () => {
    const dispatch = useDispatch();
    const router = useRouter();

    return async () => {
        try {
            await appAPI.checkConnectedGroup();
        } catch (e) {
            switch (e.code) {
                case APIErrorCodes.VIEWER_NOT_SPECIFIED:
                    router.replacePage(PAGES.HELLO);
                    return false;
                case APIErrorCodes.MISSED_SEARCH_PARAMS:
                    router.replacePage(PAGES.HOME);
                    return false;
                case APIErrorCodes.GROUP_NOT_SPECIFIED:
                    router.replacePage(PAGES.HELLO);
                    return false;
                case APIErrorCodes.GROUP_NOT_CONNECTED:
                    router.replacePage(PAGES.GROUP_CONNECTION);
                    return false;
                default:
                    dispatch(showSnackbar({text: "Произошла непредвиденная ошибка. Попробуйте позже"}))
                    return false;
            }
        }

        dispatch(setCheckedConnection(true));
        return true;
    }
};

export default useCheckConnection;