import React from "react";
import {CardGrid, List} from "@vkontakte/vkui";
import * as PropTypes from "prop-types";
import SubListItem from "./SubListItem";

const SubList = (props) => {
    const {subscriptions, onDeleteItem, onSub} = props;
    return (
        <List style={{paddingTop: '8px', paddingBottom: '8px'}}>
            <CardGrid size="l">
                {
                    subscriptions.map((item, i) =>
                        <SubListItem
                            key={i}
                            onDelete={onDeleteItem}
                            onSub={onSub}
                            item={item}
                        />
                    )
                }
            </CardGrid>
        </List>
    );
}

SubList.propTypes = {
    mode: PropTypes.string,
    forUser: PropTypes.bool,
    unsubAllBtn: PropTypes.bool,
}

export default SubList;