import React, {useEffect, useState} from 'react';
import {Panel, Tabs, TabsItem, Text} from "@vkontakte/vkui";
import {useIsAdmin, useSelectorApp} from "../../../../redux/selectors";
import SubListTab from "./tabs/SubListTab";
import SubControlTab from "./tabs/SubControlTab";
import THPanelHeader from "../../../../common/components/THPanelHeader";
import {resizeWindowToDefault} from "../../../../common/helpers/bridge";
import * as PropTypes from "prop-types";
import {setActiveTab} from "../../../../redux/root/rootReducer";
import {connect, useDispatch} from "react-redux";
import {HIDDEN, PUBLIC} from "../../../../config/SubscriptionTypes";
import {dropCache} from "../../../../redux/parts/subscriptionsReducer";

const SubscriptionsList = (props) => {
    const app = useSelectorApp();
    const dispatch = useDispatch();
    const isAdmin = useIsAdmin();
    const [separator, setSeparatorState] = useState(true);

    const setSeparator = (flag) => {
        if (isAdmin) return;
        setSeparatorState(flag);
    }

    const TABS = {
        'public': {title: 'Публичные', component: <SubListTab mode={PUBLIC} setSeparator={setSeparator}/>},
        'hidden': {title: 'Скрытые', component: <SubListTab mode={HIDDEN} setSeparator={setSeparator}/>},
        'control': {title: 'Настройки', component: <SubControlTab/>}
    }

    const tabs = Object.keys(TABS);
    const [tab, setTab] = useState(tabs.includes(app.activeTab) ? app.activeTab : PUBLIC);

    useEffect(() => {
        resizeWindowToDefault()
        if (!app.activeTab) {
            dispatch(dropCache());
            dispatch(setActiveTab(PUBLIC));
        }
    }, []);

    const onTabChange = (key) => {
        setTab(key);
        dispatch(setActiveTab(key));
    }

    const activeTab = TABS[tab].component;
    const adminView = isAdmin && (
        <Tabs>
            {
                Object.keys(TABS).map(key => (
                    <TabsItem selected={tab === key} onClick={() => onTabChange(key)} key={key}>
                        <Text weight={"regular"}>{TABS[key].title}</Text>
                    </TabsItem>
                ))
            }
        </Tabs>
    );

    return (
        <Panel id={props.id}>
            <THPanelHeader
                hasHomeBtn={true}
                title={isAdmin ? 'Подписные страницы' : 'Список рассылок'}
                separator={separator}
            />
            {adminView}
            {activeTab}
        </Panel>
    );
}

SubscriptionsList.propTypes = {
    id: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    hashObject: state.router.hashObject,
})

/*
 Без connect при переходе между панелями есть ошибка Cannot update a component
 Как её фиксить - хз. С connect её нет, поэтому оставил так
 */
export default connect(mapStateToProps)(SubscriptionsList);