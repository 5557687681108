import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Checkbox,
    Footer,
    FormItem,
    FormLayout,
    FormLayoutGroup,
    Group,
    Input,
    Link,
    NativeSelect,
    Panel,
    SegmentedControl,
    Spacing,
    Text,
    Textarea
} from "@vkontakte/vkui";
import {subscriptionsAPI} from "../../../../api/subscriptions/subscriptionsAPI";
import THPanelHeader from "../../../../common/components/THPanelHeader";
import * as PropTypes from "prop-types";
import {setActiveTab, showSnackbar} from "../../../../redux/root/rootReducer";
import {
    cleanTextareaText,
    getSearchObject,
    isValid,
    printNotificationMaxSymbols,
    remBr,
    unescapeHtml
} from "../../../../common/helpers/functions";
import THConfig from "../../../../config/THConfig";
import {dropCache} from "../../../../redux/parts/subscriptionsReducer";
import {HIDDEN, PUBLIC} from "../../../../config/SubscriptionTypes";
import BannerUploadField from "../../../../common/components/forms/BannerUploadField";
import useIsDesktop from "../../../../common/hooks/useIsDesktop";
import {vkLinkPattern} from "../../../../common/helpers/regexp-patterns";
import useGoBack from "../../../../common/hooks/useGoBack";
import useIgnoreChangeFragmentOnAndroid from "../../../../common/hooks/useIgnoreChangeFragmentOnAndroid";

const SubscriptionEditor = (props) => {
    const {item} = props;
    const goBack = useGoBack();
    const dispatch = useDispatch();
    const isDesktop = useIsDesktop();
    const ignoreChangeFragmentOnAndroid = useIgnoreChangeFragmentOnAndroid();
    const [saving, setSaving] = useState(false);
    const [subId,] = useState(item?._id.$oid ?? '');
    const [banner, setBanner] = useState(item?.banner ?? '');
    const [name, setName] = useState(unescapeHtml(item?.name) ?? 'Название');
    const [title, setTitle] = useState(unescapeHtml(item?.title) ?? '');
    const [rank, setRank] = useState(item?.rank ?? 1);
    const [desc, setDesc] = useState(item?.description ? unescapeHtml(remBr(item.description)) : '');
    const [descAlign, setDescAlign] = useState(item?.description_align ?? 'left');
    const [btnSubText, setBtnSubText] = useState(unescapeHtml(item?.subscribe_text) ?? 'Подписаться');
    const [btnUnsubText, setBtnUnsubText] = useState(unescapeHtml(item?.unsubscribe_text) ?? 'Отписаться');
    const [urlOnSub, setUrlOnSub] = useState(item?.redirect_url_subscription ?? '');
    const [urlOnUnSub, setUrlOnUnSub] = useState(item?.redirect_url_unsubscribe ?? '');
    const [visibility, setVisibility] = useState(item?.is_hidden ? HIDDEN : PUBLIC);
    const [hideCount, setHideCount] = useState(item?.hide_count === undefined ? true : !!item.hide_count);

    const linkOnSub = () => {
        return "https://vk.com/im?sel=-" + getSearchObject().vk_group_id;
    }

    const urlValidator = (url) => {
        return url.length > 0 ? url.match(vkLinkPattern) : true
    }

    const printStatusUrlField = (url) => {
        if (!url.trim().length) return "";
        if (url.length > 1000) return "error";
        if (!urlValidator(url)) return "error";

        return "";
    }

    const printNotificationForUrlField = (url) => {
        url = url.trim();
        let str = printNotificationMaxSymbols(url, 1000);
        if (str.length > 0) return str;

        if (!urlValidator(url)) {
            return 'В приложении можно указывать ссылки только на VK';
        }
        return "Например, ссылка на диалог с вашим сообществом: " + linkOnSub();
    }

    const printNotificationTitleField = title => {
        title = title.trim();
        let str = printNotificationMaxSymbols(title, 140)
        if (str.length > 0) return str;

        return "Если заголовок должен совпадать с названием, оставьте поле пустым";
    }

    const validationRules = [
        name.trim().length,
        name.trim().length <= 140,
        title.trim().length <= 140,
        desc.trim().length <= 4000,
        btnSubText.trim().length,
        btnSubText.trim().length <= 140,
        btnUnsubText.trim().length,
        btnUnsubText.trim().length <= 140,
        urlOnSub.trim().length <= 1000,
        urlOnUnSub.trim().length <= 1000,
        rank > 0,
        rank <= 10000,
        urlValidator(urlOnSub.trim()),
        urlValidator(urlOnUnSub.trim()),
    ];

    const onSubmit = async () => {
        setSaving(true);
        try {
            if (!isValid(validationRules)) {
                dispatch(showSnackbar({
                    text: "Проверьте правильность заполнения полей"
                }));
                setSaving(false);
                return;
            }
            await subscriptionsAPI.saveSubscription({
                id: subId,
                banner: cleanTextareaText(banner),
                name,
                title,
                desc,
                btnSubText,
                btnUnsubText,
                visibility,
                urlOnSub,
                urlOnUnSub,
                hideCount,
                descAlign,
                rank,
            })
            dispatch(showSnackbar({
                success: true,
                text: "Страница успешно сохранена"
            }));
            dispatch(dropCache());
            dispatch(setActiveTab(visibility));
            goBack();
            setSaving(false);
        } catch (e) {
            setSaving(false);
        }
    }

    return (
        <Panel id={props.id}>
            <THPanelHeader title={props.pageTitle}/>
            <Group>
                <FormLayout>
                    <FormItem
                        top="Название страницы"
                        status={name && name.length <= 140 ? "" : "error"}
                        bottom={
                            name
                                ? printNotificationMaxSymbols(name, 140)
                                : "Укажите название"
                        }
                    >
                        <Input
                            type="text"
                            name="name"
                            value={name ? name : ""}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                        />
                    </FormItem>
                    <BannerUploadField
                        title={"Баннер"}
                        banner={banner}
                        setBanner={setBanner}
                    />
                    <FormItem
                        top="Заголовок страницы"
                        status={title.length <= 140 ? "" : "error"}
                        bottom={printNotificationTitleField(title)}
                    >
                        <Input
                            type="text"
                            name="title"
                            value={title ? title : ""}
                            onChange={(e) => {
                                setTitle(e.target.value)
                            }}
                        />
                    </FormItem>
                    <FormItem
                        top="Описание"
                        status={desc && desc.length > 4000 ? "error" : ""}
                        bottom={desc.length + "/4000"}
                    >
                        <Textarea
                            type="desc"
                            name="desc"
                            maxLength={4000}
                            value={desc ? desc : ""}
                            onChange={(e) => {
                                setDesc(e.target.value)
                            }}
                        />
                    </FormItem>
                    <FormItem top="Выравнивание описания">
                        <NativeSelect value={descAlign} onChange={(e) => {
                            setDescAlign(e.target.value);
                        }}>
                            <option value="left">По левому краю</option>
                            <option value="center">По центру</option>
                            <option value="right">По правому краю</option>
                        </NativeSelect>
                    </FormItem>
                    <FormLayoutGroup mode={isDesktop ? "horizontal" : "vertical"}>
                        <FormItem
                            top="Текст кнопки «Подписаться»"
                            status={btnSubText && btnSubText.length <= 140 ? "" : "error"}
                            bottom={
                                btnSubText
                                    ? printNotificationMaxSymbols(btnSubText, 140)
                                    : "Укажите текст"
                            }
                        >
                            <Input
                                type="text"
                                name="btnSubText"
                                value={btnSubText ? btnSubText : ""}
                                onChange={(e) => {
                                    setBtnSubText(e.target.value)
                                }}
                            />
                        </FormItem>
                        <FormItem
                            top="Текст кнопки «Отписаться»"
                            status={btnUnsubText && btnUnsubText.length <= 140 ? "" : "error"}
                            bottom={
                                btnUnsubText
                                    ? printNotificationMaxSymbols(btnUnsubText, 140)
                                    : "Укажите текст"
                            }
                        >
                            <Input
                                type="text"
                                name="btnUnsubText"
                                value={btnUnsubText ? btnUnsubText : ""}
                                onChange={(e) => {
                                    setBtnUnsubText(e.target.value)
                                }}
                            />
                        </FormItem>
                    </FormLayoutGroup>
                    <FormItem>
                        <Checkbox
                            checked={hideCount}
                            onChange={(e) => {
                                setHideCount(e.target.checked)
                            }}
                        >Не показывать количество подписчиков на странице</Checkbox>
                    </FormItem>
                    <FormItem
                        top="Страница после подписки"
                        status={printStatusUrlField(urlOnSub)}
                        bottom={printNotificationForUrlField(urlOnSub)}
                    >
                        <Input
                            type="text"
                            name="urlOnSub"
                            value={urlOnSub ? urlOnSub : ""}
                            onChange={(e) => {
                                setUrlOnSub(e.target.value)
                            }}
                        />
                    </FormItem>
                    <FormItem
                        top="Страница после отписки"
                        status={printStatusUrlField(urlOnUnSub)}
                        bottom={printNotificationForUrlField(urlOnUnSub)}
                    >
                        <Input
                            type="text"
                            name="urlOnUnSub"
                            value={urlOnUnSub ? urlOnUnSub : ""}
                            onChange={(e) => {
                                setUrlOnUnSub(e.target.value)
                            }}
                        />
                    </FormItem>
                    <FormItem
                        top={"Видимость"}
                        bottom={"Если выбрать вариант «Скрытая», то подписная не будет отображаться в общем списке. Доступ к ней будет только по ссылке"}
                    >
                        <SegmentedControl
                            name="visibility"
                            defaultValue={visibility}
                            onChange={(value) => {
                                setVisibility(value.toString())
                            }}
                            options={[
                                {
                                    label: "Публичная",
                                    value: PUBLIC,
                                    "aria-label": "Публичная",
                                },
                                {
                                    label: "Скрытая",
                                    value: HIDDEN,
                                    "aria-label": "Скрытая",
                                },
                            ]}
                        />
                    </FormItem>
                    <FormItem
                        top="Ранг"
                        status={+rank < 1 || +rank > 10000 ? "error" : ""}
                        bottom={"Чем выше значение, тем выше эта страница будет в общем списке. От 1 до 10 000"}
                    >
                        <Input
                            type="number"
                            name="rank"
                            value={rank ? rank : ""}
                            max={10000}
                            min={1}
                            onChange={(e) => {
                                setRank(e.target.value)
                            }}
                        />
                    </FormItem>
                    <FormItem>
                        <Button size="l" stretched onClick={onSubmit} loading={saving}>
                            <Text weight={"regular"}>Сохранить</Text>
                        </Button>
                    </FormItem>
                </FormLayout>
            </Group>
            <Spacing size={16} separator={"bottom"}/>
            <Footer className={"text-muted"}>
                В <Link href={THConfig.system.url} target={"_blank"} onClick={ignoreChangeFragmentOnAndroid}>полной версии сервиса</Link> вы можете создать рассылку или чат-бота,
                который будет запускаться после того, как пользователь нажмет кнопку «Подписаться» или «Отписаться»
            </Footer>
        </Panel>
    )
}

SubscriptionEditor.propTypes = {
    id: PropTypes.string.isRequired,
    pageTitle: PropTypes.string.isRequired,
    item: PropTypes.object,
};

export default SubscriptionEditor;