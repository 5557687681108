import React from 'react';
import s from "../styles/WidgetCommonStyles.module.css";
import {isNotEmptyString} from "../../../../common/helpers/functions";

const WidgetFooter = (props) => {
    const emptyText = () => {
        return <span><i>Пусто</i></span>
    };
    const footerText = isNotEmptyString(props.footerText);
    return (
        <div onClick={props.openModalFooter}
             style={{borderTop: "1px solid var(--separator_common)"}}
             className={s.footerContainer}>
            <span className={s.footerText}>{footerText ? footerText : emptyText()}</span>
        </div>
    )
};

export default WidgetFooter;
