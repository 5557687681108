import React from "react";
import {ConfigProvider, IOS} from "@vkontakte/vkui";

const withIOSPlatform = (Component) => {
    return (props) => (
        <ConfigProvider platform={IOS} scheme="inherit">
            <Component {...props} />
        </ConfigProvider>
    )
}

export default withIOSPlatform;