import "core-js/features/map";
import "core-js/features/set";

import React from "react";
import {render} from 'react-dom';
import App from "./App";
import "./styles/App.css";
import {Provider} from "react-redux";
import store from "./redux/store";
import Launcher from "./common/Launcher";
import {RouterContext} from '@happysanta/router';
import {router} from './router';

(async () => {
    const launcher = new Launcher();
    await launcher.init();
    launcher.initRouter(router);

    render((
        <RouterContext.Provider value={router}>
            <Provider store={store}>
                <App/>
            </Provider>
        </RouterContext.Provider>
    ), document.getElementById("root"));
})();

if (process.env.NODE_ENV === "development") {
    import("./eruda").then(({default: eruda}) => {
    }); //runtime download
}