import React from 'react';
import s from "../styles/Subscription.module.css";
import VideoPlayer from "../../../../common/components/VideoPlayer/VideoPlayer";
import PropTypes from "prop-types";

const SubscriptionBanner = (props) => {
    const {item} = props;
    const file = item && (item.banner || '')
    const isVideo = item && item.isVideo;
    if (!file) {
        return null;
    }

    if (isVideo) {
        const mute = item && !!item.mute;
        const play = item && !!item.play;
        const loop = item && !!item.loop;
        return <VideoPlayer
            config={{file, play, loop, mute}}
            onPlayerRendered={props.onLoad}
        />
    }

    return (
        <div className={s.banner}>
            <img src={file} onLoad={props.onLoad} alt="Баннер"/>
        </div>
    )
}

SubscriptionBanner.propTypes = {
    item: PropTypes.shape({
        banner: PropTypes.string,
        isVideo: PropTypes.bool,
        mute: PropTypes.bool,
        loop: PropTypes.bool
    }),
    onLoad: PropTypes.func
}


export default SubscriptionBanner;