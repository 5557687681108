import React from 'react';
import {Alert, Text} from "@vkontakte/vkui";
import {hidePopout} from "../../../redux/root/rootReducer";
import {AlertTitle} from "../Alerts/Components";

const PopoutErrorWidget = (dispatch, code) => {
    return <Alert
        actions={[{
            title: 'OK',
            mode: 'cancel',
            autoclose: true,
            action: () => {
                dispatch(hidePopout())
            }
        }]}
        onClose={() => {
            dispatch(hidePopout())
        }}
    >
        <AlertTitle>Внимание</AlertTitle>
        <Text style={{fontSize: '14px'}} weight="regular">
            {code.map((elem, index) => <p key={index}><b>{index + 1}.</b> {elem}</p>)}
        </Text>
    </Alert>
};
export default PopoutErrorWidget;
