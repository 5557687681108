import {applyMiddleware, combineReducers, createStore} from "redux";
import thunk from "redux-thunk";
import rootReducer from "./root/rootReducer";
import routerReducer from "./router/routerReducer";
import widgetReducer from "./parts/widgetReducer";
import widgetListReducer from "./parts/widgetListReducer";
import subscriptionsReducer from "./parts/subscriptionsReducer";

const reducers = combineReducers({
    app: rootReducer,
    router: routerReducer,
    subscriptions: subscriptionsReducer,
    widget: widgetReducer,
    listWidget: widgetListReducer,
});

const store = createStore(reducers, applyMiddleware(thunk));

export default store;