import {Page} from "@happysanta/router";

export const VIEWS = {
    GUEST: 'guest',
    MAIN: 'main',
    SUBSCRIPTIONS: 'subscriptions',
    WIDGETS: 'widgets'
}

// Для уникального адреса панели. Используется в url hash
export const PANELS = {
    HELLO: 'hello',
    GROUP_CONNECTION: 'connect',
    LOADING: 'loading',
    HOME: 'home',
    SUBSCRIPTION: 's',
    SUBSCRIPTIONS: 'subscriptions',
    USER_SUBSCRIPTIONS: 'mySubscriptions',
    SUBSCRIPTION_EDITOR: 'subscriptionsEditor',
    WIDGETS: 'widgets',
    WIDGETS_SELECT: 'selectWidget',
    WIDGET: 'widget',
    SETTINGS_ANALYTICS: 'settingsAnalytics',
    SETTINGS: 'settings',
}

export const PAGES = {
    MAIN: "/",
    HELLO: '/hello',

    HOME: '/home',
    GROUP_CONNECTION: '/connect',
    SETTINGS_ANALYTICS: '/settingsAnalytics',
    SETTINGS: '/settings',

    SUBSCRIPTION: '/s=:hash',
    SUBSCRIPTIONS: '/subscriptions',
    SUBSCRIPTION_EDITOR: '/subscriptionEditor',
    USER_SUBSCRIPTIONS: '/mySubscriptions',

    WIDGETS: '/widgets',
    WIDGET: '/widget',
    WIDGETS_SELECT: '/selectWidget',
};

export const PAGES_INFO = {
    [PAGES.HELLO]: {
        headerTitle: 'Добро пожаловать',
        isAnimate: false,
    },
    [PAGES.HOME]: {
        headerTitle: 'Главная',
    },
    [PAGES.GROUP_CONNECTION]: {
        headerTitle: 'Подключение',
        isAnimate: false,
    },
    [PAGES.SETTINGS_ANALYTICS]: {
        headerTitle: 'Аналитика',
        isAdmin: true,
    },
    [PAGES.SETTINGS]: {
        headerTitle: 'Настройки',
        isAdmin: true,
    },
    [PAGES.SUBSCRIPTION]: {
        panel: PANELS.SUBSCRIPTION,
    },
    [PAGES.SUBSCRIPTIONS]: {
        headerTitle: 'Список рассылок',
    },
    [PAGES.SUBSCRIPTION_EDITOR]: {
        headerTitle: 'Добавить подписную',
    },
    [PAGES.USER_SUBSCRIPTIONS]: {
        headerTitle: 'Мои подписки',
    },
    [PAGES.WIDGETS]: {
        headerTitle: 'Виджеты',
        isAdmin: true,
    },
    [PAGES.WIDGET]: {
        hideHash: true,
        headerTitle: 'Выберите виджет',
        isAdmin: true,
    },
    [PAGES.WIDGETS_SELECT]: {
        hideHash: true,
        headerTitle: 'Виджет',
        isAdmin: true,
    },
}

export const routes = {
    [PAGES.HELLO]: new Page(PANELS.HELLO, VIEWS.GUEST),
    [PAGES.GROUP_CONNECTION]: new Page(PANELS.GROUP_CONNECTION, VIEWS.GUEST),

    [PAGES.MAIN]: new Page(PANELS.LOADING, VIEWS.MAIN),
    [PAGES.HOME]: new Page(PANELS.HOME, VIEWS.MAIN),
    [PAGES.SETTINGS_ANALYTICS]: new Page(PANELS.SETTINGS_ANALYTICS, VIEWS.MAIN),
    [PAGES.SETTINGS]: new Page(PANELS.SETTINGS, VIEWS.MAIN),

    [PAGES.SUBSCRIPTIONS]: new Page(PANELS.SUBSCRIPTIONS, VIEWS.SUBSCRIPTIONS),
    [PAGES.SUBSCRIPTION]: new Page(PANELS.SUBSCRIPTION, VIEWS.SUBSCRIPTIONS),
    [PAGES.SUBSCRIPTION_EDITOR]: new Page(PANELS.SUBSCRIPTION_EDITOR, VIEWS.SUBSCRIPTIONS),
    [PAGES.USER_SUBSCRIPTIONS]: new Page(PANELS.USER_SUBSCRIPTIONS, VIEWS.SUBSCRIPTIONS),

    [PAGES.WIDGETS]: new Page(PANELS.WIDGETS, VIEWS.WIDGETS),
    [PAGES.WIDGET]: new Page(PANELS.WIDGET, VIEWS.WIDGETS),
    [PAGES.WIDGETS_SELECT]: new Page(PANELS.WIDGETS_SELECT, VIEWS.WIDGETS),
};