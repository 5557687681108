import React from 'react';
import {Div, Group, UsersStack} from '@vkontakte/vkui';
import s from './UserStack.module.css';
import {declOfNum} from "../../helpers/functions";
import * as PropTypes from "prop-types";

const UserStack = (props) => {
    const {item} = props;
    const {count, usersStackPhoto} = item;
    const suffix = declOfNum(count, ['подписчик', 'подписчика', 'подписчиков']);

    return (
        <Group>
            <Div className={s.wrapper}>
                <UsersStack photos={usersStackPhoto} size="s">
                    {item.count} {suffix}
                </UsersStack>
            </Div>
        </Group>
    );
}

UserStack.propTypes = {
    item: PropTypes.object.isRequired
}

export default UserStack;