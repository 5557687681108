import React from "react";
import {AdaptivityProvider, AppRoot, ConfigProvider, IOS, SplitCol, SplitLayout} from "@vkontakte/vkui";
import {connect} from "react-redux";

const RootLayout = props => {
    return (
        <ConfigProvider appearance={props.appearance} platform={IOS}>
            <AdaptivityProvider>
                <AppRoot>
                    <SplitLayout popout={props.popout} style={{justifyContent: 'center'}} modal={props.modal}>
                        <SplitCol animate={props.isAnimate} maxWidth={750}>
                            {props.children}
                        </SplitCol>
                    </SplitLayout>
                </AppRoot>
            </AdaptivityProvider>
        </ConfigProvider>
    );
}

const mapStateToProps = state => ({
    appearance: state.app.appearance,
    popout: state.app.popout,
    modal: state.app.modal,
    isAnimate: state.router.isAnimate,
})

export default connect(mapStateToProps)(RootLayout);