import React from 'react';
import {connect} from "react-redux";
import TextWidgetPanel from "./TextWidgetPanel";
import {
    publishWidgetSettings,
    setDescription,
    setFooter,
    setLinkTitle,
    setName,
    setText,
    setTitle,
    setTitleCount
} from "../../../../redux/parts/widgetReducer";
import {setActiveModal} from "../../../../redux/root/rootReducer";
import withOpenModals from "../../../../common/hoc/withOpenModals";

const TextWidgetPanelContainer = (props) => {

    const openModalFooter = () => {
        props.setActiveModal("TEXT_footer");
    };

    const openModalTitle = () => {
        props.setActiveModal("TEXT_title");
    };

    return <TextWidgetPanel
        id={props.id}
        {...props}
        openModals={props.openModals}
        openModalTitle={openModalTitle}
        openModalFooter={openModalFooter}
    />

};

let mapStateToProps = (state) => ({
    name: state.widget.name,
    title: state.widget.title,
    titleCount: state.widget.titleCount,
    linkTitle: state.widget.linkTitle,
    text: state.widget.text,
    description: state.widget.description,
    footerText: state.widget.footerText,
    footerLink: state.widget.footerLink,
});

export default withOpenModals(connect(mapStateToProps, {
    setName,
    setTitle,
    setLinkTitle,
    setText,
    setDescription,
    setFooter,
    setTitleCount,
    saveWidgetSettings: publishWidgetSettings,
    setActiveModal,
})(TextWidgetPanelContainer));
