import React from "react";
import {PANELS} from "../../config/routes";
import {View} from "@vkontakte/vkui";
import LoadingPanel from "../../common/components/LoadingPanel";
import Home from "./panels/Home/Home";
import * as PropTypes from "prop-types";
import AnalyticsSettings from "./panels/Settings/AnalyticsSettings";
import MainPageSettings from "./panels/Settings/Settings";

const MainView = props => {
    return (
        <View id={props.id} activePanel={props.activePanel} onSwipeBack={props.onSwipeBack} history={props.history}>
            <LoadingPanel id={PANELS.LOADING}/>
            <Home id={PANELS.HOME}/>
            <AnalyticsSettings id={PANELS.SETTINGS_ANALYTICS}/>
            <MainPageSettings id={PANELS.SETTINGS}/>
        </View>
    )
}

MainView.propTypes = {
    id: PropTypes.string.isRequired,
    activePanel: PropTypes.string,
}

export default MainView;