import {requestAjaxPost} from "../../common/helpers/requests";

const widgetsAPI = {
    method: (method) => 'miniApp.widgets.' + method,

    async getList() {
        return requestAjaxPost(this.method('getList'));
    },

    async save(data) {
        return requestAjaxPost(this.method('save'), data);
    },

    async delete(id) {
        return requestAjaxPost(this.method('delete'), {id});
    },

    async unplug(id) {
        return requestAjaxPost(this.method('unplug'), {id});
    },

    async getVariablesCode(code) {
        return requestAjaxPost(this.method('getVariablesCode'), {code});
    },

    async uploadIcon(data) {
        return requestAjaxPost(this.method('uploadIcon'), data);
    },

    async saveToken(token) {
        return requestAjaxPost(this.method('saveToken'), {token});
    },

    async uploadBanner(file) {
        return await requestAjaxPost(this.method('uploadBanner'), {file});
    },
};

export {widgetsAPI};