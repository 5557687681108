import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {useToggleSubscribe} from "../../../common/hooks/useToggleSubscribe";
import {subscriptionsAPI} from "../../../../../api/subscriptions/subscriptionsAPI";
import {Button, Footer, Spinner, Text} from "@vkontakte/vkui";
import {NoSubscriptions} from "../NoSubscriptions";
import * as PropTypes from "prop-types";
import {showAlertAreYouSure, showSnackbar, toggleLoading} from "../../../../../redux/root/rootReducer";
import SubList from "./SubList";
import {useSelectorSubscriptions} from "../../../../../redux/selectors";
import {dropCache, setCachedList} from "../../../../../redux/parts/subscriptionsReducer";
import {FOR_USER} from "../../../../../config/SubscriptionTypes";

const SubListContainer = (props) => {
    const {mode, isFetching = false, setIsFetching, useCache = true, hashObject} = props;
    const forUser = mode === FOR_USER;
    const dispatch = useDispatch();
    const toggleSubscribe = useToggleSubscribe(hashObject);
    const subState = useSelectorSubscriptions();
    const [isLoading, setLoading] = useState(true);
    const [subscriptions, setSubscriptions] = useState([]);

    const fetchList = async ({isRefresh = false, ignoreCache = !useCache} = {}) => {
        const start = new Date().getTime();
        !isRefresh && setLoading(true);
        const getFromCache = !ignoreCache && subState?.cachedList[mode] !== undefined;

        const getList = async () => {
            if (getFromCache) {
                return subState.cachedList[mode];
            }
            const res = await subscriptionsAPI.getList({mode});
            !ignoreCache && dispatch(setCachedList(res, mode));
            return res;
        }

        const res = await getList();
        setSubscriptions(res);
        !isRefresh && setLoading(false);

        const end = new Date().getTime();
        if (setIsFetching) {
            setTimeout(() => setIsFetching(false), end - start < 500 ? 500 : 0);
        }
    }

    const onDeleteItem = (id, name) => {
        const onAccept = async () => {
            dispatch(toggleLoading(true));
            try {
                await subscriptionsAPI.deleteSubscription(id);
                dispatch(dropCache());
                await fetchList({ignoreCache: true});
                dispatch(toggleLoading(false));
            } catch (e) {
                dispatch(toggleLoading(false));
                dispatch(showSnackbar({text: "Произошла ошибка. Обновите страницу и попробуйте ещё раз"}));
            }
        };

        const message = `Вы уверены, что нужно удалить подписную страницу<br/><b>«${name}»</b>?
                <br/><br/>Это действие нельзя отменить.`;
        dispatch(showAlertAreYouSure({
            onAccept,
            acceptText: "Удалить",
            title: 'Удаление элемента',
            message
        }));
    }

    const unSubAll = async() => {
        const onAccept = async () => {
            setLoading(true);
            try {
                await subscriptionsAPI.unsubscribeAll();
                dispatch(dropCache());
                await fetchList({ignoreCache: true});
            } catch (e) {
            }
            dispatch(showSnackbar({
                success: true,
                text: 'Вы успешно отписались от всех рассылок'
            }));
            setLoading(false);
        }

        dispatch(showAlertAreYouSure({
            onAccept,
            message: "Вы уверены, что хотите отписаться от всех рассылок?"
        }))
    }

    useEffect(() => {
        fetchList();
    }, [mode]);

    useEffect(() => {
        if (!isFetching) return;
        fetchList({isRefresh: true, ignoreCache: true});
    }, [isFetching]);

    const onSub = async (item) => {
        const newItem = await toggleSubscribe(item);
        if (!newItem) {
            return;
        }
        const newSubscriptions = [...subscriptions];
        const changedItemIndex = newSubscriptions.findIndex(sub => sub._id.$oid === item._id.$oid);
        newSubscriptions[changedItemIndex] = newItem;
        setSubscriptions(newSubscriptions);
        if (useCache) {
            dispatch(setCachedList(newSubscriptions, mode));
        }
    }

    if (isLoading) {
        return <Spinner style={{paddingTop: "24px"}}/>;
    }

    if (!subscriptions || !subscriptions.length) {
        return <NoSubscriptions/>;
    }

    return (
        <>
            <SubList subscriptions={subscriptions} onSub={onSub} onDeleteItem={onDeleteItem}/>
            {
                forUser
                &&
                subscriptions.length
                &&
                <Footer>
                    <Button mode={"tertiary"} onClick={unSubAll}>
                        <Text weight={"regular"}>Отписаться от всех рассылок</Text>
                    </Button>
                </Footer>
            }
        </>
    );
}

SubListContainer.propTypes = {
    mode: PropTypes.string,
    isFetching: PropTypes.bool,
    setIsFetching: PropTypes.func,
    useCache: PropTypes.bool,
}

const mapStateToProps = state => ({
    hashObject: state.router.hashObject,
})

export default connect(mapStateToProps)(SubListContainer);