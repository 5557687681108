import React from 'react';
import {Card, Div, Spacing, Text, Title} from "@vkontakte/vkui";
import {limitText, removeSmiles, unescapeHtml} from "../../../../../common/helpers/functions";
import SubscriptionActionButton from "../../../common/components/SubscriptionActionButton";
import ItemAdminFeatures from "../ItemAdminFeatures";
import {useIsAdmin} from "../../../../../redux/selectors";
import {useRouter} from "@happysanta/router";
import {PAGES} from "../../../../../config/routes";

const SubListItem = (props) => {
    const {item, onDelete, onSub} = props;
    const isAdmin = useIsAdmin();
    const router = useRouter();

    const desc = item.description && <>
        <Spacing size={8}/>
        <Text
            dangerouslySetInnerHTML={{__html: limitText(removeSmiles(item.description), 140)}}
            weight={"regular"}/>
    </>;

    const goToItem = () => {
        router.pushPage(PAGES.SUBSCRIPTION, {hash: item._id.$oid});
    }

    const onClickSub = () => {
        onSub(item);
    }

    return (
        <Card mode={"shadow"}>
            <Div style={{padding: '16px'}}>
                <div style={{cursor: 'pointer'}} onClick={goToItem}>
                    <Title level={3} mode="primary">
                        {unescapeHtml(item.title || item.name)}
                    </Title>
                    {desc}
                </div>
                <Spacing size={16}/>
                <SubscriptionActionButton
                    item={item}
                    stretched={true}
                    onClick={onClickSub}
                    text={item.isSubscribed ? item.unsubscribe_text : item.subscribe_text}
                />
            </Div>
            {
                isAdmin
                &&
                <ItemAdminFeatures item={item} onDelete={onDelete}/>
            }
        </Card>
    )
};

SubListItem.propTypes = {};

export default SubListItem;
