import React from "react";
import {PANELS} from "../../config/routes";
import {View} from "@vkontakte/vkui";
import SubscriptionContainer from "./panels/Subscription/SubscriptionContainer";
import SubscriptionsList from "./panels/SubscriptionsList/SubscriptionsList";
import * as PropTypes from "prop-types";
import SubscriptionEditorContainer from "./panels/SubscriptionEditor/SubscriptionEditorContainer";
import UserSubscriptions from "./panels/UserSubscriptions/UserSubscriptions";

const SubscriptionsView = props => {
    return (
        <View id={props.id} activePanel={props.activePanel} onSwipeBack={props.onSwipeBack} history={props.history}>
            <SubscriptionsList id={PANELS.SUBSCRIPTIONS}/>
            <UserSubscriptions id={PANELS.USER_SUBSCRIPTIONS}/>
            <SubscriptionContainer id={PANELS.SUBSCRIPTION}/>
            <SubscriptionEditorContainer id={PANELS.SUBSCRIPTION_EDITOR}/>
        </View>
    )
}

SubscriptionsView.propTypes = {
    id: PropTypes.string.isRequired,
    activePanel: PropTypes.string,
}

export default SubscriptionsView;