import useIsAndroid from "./useIsAndroid";
import {setIgnoreChangeFragment} from "../../redux/root/rootReducer";
import {useDispatch} from "react-redux";

/**
 * Решение бага с тем, когда на Android открывается экран выбора файла/перехода по ссылке:
 * Приложение "сворачивается" и "разворачивается из кэша" после закрытия экрана
 */
const useIgnoreChangeFragmentOnAndroid = () => {
    const isAndroid = useIsAndroid();
    const dispatch = useDispatch();
    return () => {
        if (isAndroid) {
            dispatch(setIgnoreChangeFragment(true));
        }
    }
};

export default useIgnoreChangeFragmentOnAndroid;