import {requestAjaxPost} from "../../common/helpers/requests";

const appAPI = {
    method: (method) => 'miniApp.' + method,

    async loadSettings() {
        return requestAjaxPost(this.method('settings.load'));
    },

    async checkConnectedGroup() {
        return requestAjaxPost(this.method('group.checkConnect'), {}, false);
    },

    async connectGroup(groupInfo) {
        return requestAjaxPost(this.method('group.connect'));
    },

    async saveSettings(data) {
        return requestAjaxPost(this.method('settings.save'), data);
    },

    async uploadBanner(file) {
        return await requestAjaxPost(this.method('uploadBanner'), {file});
    },

    async hit(event) {
        return await requestAjaxPost(this.method('analytics.hit'), {event});
    },
};

export {appAPI};