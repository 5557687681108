import querystring from "query-string";

export const getHrefObject = () => {
    return querystring.parse(window.location.href);
};

export const getHashObject = (hash = window.location.hash) => {
    return querystring.parse(hash.replace(/&amp;/g, '&'), {
        sort: false
    });
};

export const getSearchObject = () => {
    return querystring.parse(window.location.search);
};

export const getCurrentAppUrl = () => {
    const search = getSearchObject();
    return "https://vk.com/app" + search.vk_app_id + '_-' + search.vk_group_id;
}

export function objectToFormData(object) {
    const fd = new FormData();

    Object.entries(object).forEach(([key, value]) => {
        fd.append(key, value);
    });

    return fd;
}

export function escapeHtml(unsafe)
{
    return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

export function unescapeHtml(text) {
    let patterns = [];

    if (typeof text !== 'string')
        return text;

    let map = {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&quot;': '"',
        "&#039;": "'"
    };

    patterns = [];
    for (let key in map) {
        patterns.push(`(${key})`);
    }

    let regExp = new RegExp(patterns.join('|'), 'g');

    let i = 0; // iterator, for check cycle
    do {
        if (!text.match(regExp)) {
            break;
        }

        text = text.replace(regExp, function (m) {
            return map[m];
        });
    } while (++i < 10);

    return text;
}

export const declOfNum = (number, titles) => {
    let cases_names = [2, 0, 1, 1, 1, 2];
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases_names[(number % 10 < 5) ? number % 10 : 5]];
}

export function limitText(text, limit) {
    text = text.trim();
    if (text.length <= limit)
        return text;
    text = text.slice(0, limit); // тупо отрезать по лимиту
    let lastSpace = text.lastIndexOf(" ");
    if (lastSpace > 0) { // нашлась граница слов, ещё укорачиваем
        text = text.substr(0, lastSpace);
    }
    return text + "...";
}

export function removeSmiles(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
}


export const getDate = (dateValue) => {
    const pad = (s, width, character) => {
        return new Array(width - s.toString().length + 1).join(character) + s;
    };

    const date = new Date(dateValue * 1000);
    const now = new Date();

    const defaultDate = pad(date.getDate(), 2, '0') +
        '.' + pad(date.getMonth() + 1, 2, '0') +
        '.' + date.getFullYear() +
        ' в ' + pad(date.getHours(), 2, '0') +
        ':' + pad(date.getMinutes(), 2, '0');

    if (+now.getMonth() - +date.getMonth() !== 0 || +now.getFullYear() - +date.getFullYear() !== 0) {
        return defaultDate;
    }

    if (now.getDate() - date.getDate() === 0) {
        return "Сегодня в " + pad(date.getHours(), 2, '0') + ':' + pad(date.getMinutes(), 2, '0');
    }
    if (now.getDate() - date.getDate() === 1) {
        return "Вчера в " + pad(date.getHours(), 2, '0') + ':' + pad(date.getMinutes(), 2, '0');
    }
    return defaultDate;
};

export const replaceQuotes = (str) => {
    return str
        .replace(/&quot;/g, '\\"')
};

export function br2nl(str) {
    return str.replace(/<br\s*\/?>/mg, "\n");
}

export function remBr(str) {
    return str.replace(/<br\s*\/?>/mg, "");
}

export const nl2br = (str) => {
    return str
        .replace(/\\n/g, '<br/>')
};

export function cleanTextareaText(text) {
    return text.replace("\r\n", "\n").replace("\r", "\n").trim()
}

export function isValid(rules) {
    for (let rule of rules)
        if (!rule) return false;
    return true;
}

export const fireEventClick = (link, target = '_top') => {
    const a = document.createElement('a');
    if (getSearchObject().vk_platform === 'mobile_iphone') {
        target = '_top';
    }
    a.href = link;
    a.target = target;

    a.dispatchEvent(new window.MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
    }));
};

export const checkVersion = (a, b) => {
    const [majorA, minorA] = String(a).split('.').map(v => Number.parseInt(v));
    const [majorB, minorB] = String(b).split('.').map(v => Number.parseInt(v));
    if (majorA !== majorB) {
        return majorA > majorB;
    }
    return minorA > minorB;
}

export const printNotificationMaxSymbols = (value, max) => {
    return value.length > max ? `Максимальное количество символов - ${max}. Сейчас: ${value.length}` : ""
}

export const isNotEmptyString = (text) => {
    // проверка строки на наличие в ней только таба, пробела, переноса.
    if (!text) {
        return false;
    }
    if (text.trim() === '') {
        return false
    } else {
        return unescapeHtml(text.trim());
    }
};

export const getStrWithCode = (str) => {
    return str
        .replace(/<span>/g, '')
        .replace(/<\/span>/g, '')
};

export const replaceSmilesToCode = (emojiArray, str) => {
    /* eslint-disable */
    let newStr = str;
    for (let i = 0; i < str.length; i++) {
        emojiArray.map((elem) => {
            if (str[i] + str[i + 1] === elem.alt) {
                newStr = newStr.replace(`${elem.alt}`, `${elem.code}`);
            }
            return 0;
        })
    }
    return newStr;
    /* eslint-enable */
};

export const removeSpanFromField = (str) => {
    return str
        .replace(/<div><br><\/div>/g, '\n')
        .replace(/<\/div><div>/g, '\\n')
        .replace(/<div>/g, '\\n')
        .replace(/<\/div>/g, '\\n')
        .replace(/<br>/g, '\\n')
        .replace(/<span>/g, '')
        .replace(/<\/span>/g, '')
        .replace(/<\/?span[^>]*>/g, '');
};