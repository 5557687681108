import React from 'react';
import {CellButton, Group} from "@vkontakte/vkui";
import Icon24Add from "@vkontakte/icons/dist/24/add";
import {Icon24GraphOutline} from "@vkontakte/icons";
import {useRouter} from "@happysanta/router";
import {PAGES} from "../../../../../config/routes";

const SubControlTab = (props) => {
    const router = useRouter();
    return (
        <Group>
            <CellButton
                size={'m'}
                before={<Icon24Add/>}
                onClick={() => router.pushPage(PAGES.SUBSCRIPTION_EDITOR)}
                centered
            >
                Добавить страницу
            </CellButton>
            <CellButton
                size={'m'}
                before={<Icon24GraphOutline/>}
                onClick={() => router.pushPage(PAGES.SETTINGS_ANALYTICS)}
                centered
            >
                Настроить аналитику
            </CellButton>
        </Group>
    );
}

export default SubControlTab;