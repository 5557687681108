import React from "react";
import {PANELS} from "../../config/routes";
import {View} from "@vkontakte/vkui";
import GroupConnection from "./panels/GroupConnection/GroupConnection";
import Hello from "./panels/Hello/Hello";
import * as PropTypes from "prop-types";

const GuestView = props => {
    return (
        <View id={props.id} activePanel={props.activePanel} onSwipeBack={props.onSwipeBack} history={props.history}>
            <GroupConnection id={PANELS.GROUP_CONNECTION}/>
            <Hello id={PANELS.HELLO}/>
        </View>
    )
}

GuestView.propTypes = {
    id: PropTypes.string.isRequired,
    activePanel: PropTypes.string,
}

export default GuestView;