import bridge from "@vkontakte/vk-bridge";
import store from "../redux/store";
import {
    changeAppearance,
    setBridgeInit,
    setCheckedConnection,
    setIgnoreChangeFragment,
    setODREnabled,
    setRequestScheme,
    setVKAppVersion,
    showSnackbar
} from "../redux/root/rootReducer";
import {asyncInitVKOpenAPI} from "./helpers/analytics";
import {checkVersion, getHashObject, getSearchObject} from "./helpers/functions";
import {PAGES, PAGES_INFO, PANELS} from "../config/routes";
import {router} from "../router";
import {setHashObject, setIsAnimate, setPanelHeaderTitle} from "../redux/router/routerReducer";
import {appAPI} from "../api/app/appAPI";
import {APIErrorCodes} from "../config/api/constant";

class Launcher {
    bridgeSubscribe() {
        bridge.subscribe(({detail: {type, data}}) => {
            switch (type) {
                case "VKWebAppUpdateConfig":
                    let theme = 'light';
                    if (data.appearance) {
                        theme = data.appearance
                    } else if (data.scheme === 'vkcom_dark') {
                        theme = 'dark';
                    }
                    store.dispatch(changeAppearance(theme));
                    break;
                case "VKWebAppChangeFragment":
                    if (typeof data.location === 'undefined') return;
                    let location = data.location ?? PANELS.SUBSCRIPTIONS;
                    if (location.length === 0) {
                        location = '/' + PANELS.SUBSCRIPTIONS;
                    }

                    let currentHash = window.location.hash.replace(/^#\/?/, '');
                    currentHash = currentHash.length > 0 ? currentHash : PANELS.SUBSCRIPTION;
                    if (location === currentHash) return;

                    const start = async () => {
                        try {
                            await appAPI.checkConnectedGroup();
                            store.dispatch(setCheckedConnection(true));
                        } catch (e) {
                            switch (e.code) {
                                case APIErrorCodes.VIEWER_NOT_SPECIFIED:
                                    router.onVKWebAppChangeFragment(PAGES.HELLO);
                                    return false;
                                case APIErrorCodes.MISSED_SEARCH_PARAMS:
                                    router.onVKWebAppChangeFragment(PAGES.HOME);
                                    return false;
                                case APIErrorCodes.GROUP_NOT_SPECIFIED:
                                    router.onVKWebAppChangeFragment(PAGES.HELLO);
                                    return false;
                                case APIErrorCodes.GROUP_NOT_CONNECTED:
                                    router.onVKWebAppChangeFragment(PAGES.GROUP_CONNECTION);
                                    return false;
                                default:
                                    store.dispatch(showSnackbar({text: "Произошла непредвиденная ошибка. Попробуйте позже"}))
                                    return false;
                            }
                        }

                        /**
                         * Решение бага с тем, когда на Android открывается экран выбора файла:
                         * Приложение "сворачивается" и "разворачивается из кэша" после выбора файла
                         */
                        if (!store.getState()?.app?.ignoreChangeFragment) {
                            router.onVKWebAppChangeFragment(location);
                        }
                        store.dispatch(setIgnoreChangeFragment(false));
                    };

                    start();
                    break;
            }
        })
    }

    initBridge() {
        bridge.send("VKWebAppInit").then(async (r) => {
            store.dispatch(setBridgeInit(true));
            if (bridge.supports("VKWebAppSetViewSettings")) {
                const appearance = store.getState()?.app?.appearance ?? 'light';
                const color = appearance === 'dark' ? "#19191a" : "#fff";
                const bar_style = appearance === 'dark' ? "light" : "dark";
                bridge.send("VKWebAppSetViewSettings", {
                    status_bar_style: bar_style, action_bar_color : color
                });
            }
        });
    }

    async initBridgeODR() {
        await bridge.send("VKWebAppInit")
        store.dispatch(setBridgeInit(true));
        const {version} = await bridge.send('VKWebAppGetClientVersion');
        if (checkVersion(version, '6.46')) {
            window.scheme = 'vkcors';
            store.dispatch(setRequestScheme('vkcors'));
        }
        store.dispatch(setVKAppVersion(version));
    }

    initScripts() {
        asyncInitVKOpenAPI();
    }

    async init() {
        this.bridgeSubscribe();
        const params = getSearchObject();
        if (+params?.odr_enabled === 1) {
            store.dispatch(setODREnabled(true));
            await this.initBridgeODR();
        } else {
            this.initBridge();
        }

        this.initScripts();
    }

    initRouter(router) {
        router.on('update', async (nextRoute, oldRoute) => {
            store.dispatch(setHashObject(getHashObject(nextRoute.getLocation().replace(/^\//, '#'))))
            const info = PAGES_INFO[nextRoute.getPageId()];
            if (!info) return;

            const isAnimate = 'isAnimate' in info ? info.isAnimate : true;
            info.headerTitle && store.dispatch(setPanelHeaderTitle(info.headerTitle));
            store.dispatch(setIsAnimate(!!isAnimate));
            const isAdmin = !!store.getState().app.viewer.isAdmin;

            if (info.isAdmin && !isAdmin) {
                router.replacePage("/");
            }
        });
    }
}

export default Launcher;