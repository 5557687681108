import {Div, Text, Title} from "@vkontakte/vkui";
import React from "react";

export const AlertTitle = ({children}) => (
    <Title style={{textAlign: 'center', fontSize: '18px', wordBreak: "break-word"}} level={2}>{children}</Title>
)

export const AlertText = ({children}) => (
    <Text style={{fontSize: '14px', wordBreak: "break-word"}} weight="regular" dangerouslySetInnerHTML={{__html: children}}/>
)

export const AlertIcon = ({children}) => (
    <Div style={{display: 'flex', justifyContent: 'center'}}>{children}</Div>
)