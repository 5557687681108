import React from 'react'
import s from '../../panels/widgetStyles.module.css';
import {isNotEmptyString} from "../../../../common/helpers/functions";

const WidgetTitle = (props) => {
    const emptyText = () => {
        return <span style={{color: "red"}}>Текст отсутствует</span>
    };
    const title = isNotEmptyString(props.title);
    return (
        <div onClick={props.openModalTitle}
             className={s.container__titleLink}
        >
            <div className={s.container__titleContent + ' ' + s.clearFix}>
                <span className={s.titleContent__text}>{title ? title : emptyText()}</span>
                <span className={s.titleContent__count}>{props.titleCount}</span>
            </div>
        </div>
    )
};
export default WidgetTitle;
