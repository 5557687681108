import React, {useState} from 'react';
import {Button, FormItem, FormLayout, Group, Input, Text, withModalRootContext} from "@vkontakte/vkui";
import FieldWithSmiles from "./FieldWithSmiles/FieldWithSmiles";

const ModalContent = (props) => {
    const [name, setName] = useState(props.name);
    const [link, setLink] = useState(props.link);
    return (
        <Group>
            <FormLayout>
                <FormItem top={`Текст для поля ${props.nameLabel}`}>
                    <FieldWithSmiles
                        updateModalHeight={props.updateModalHeight}
                        limit={props.limit}
                        text={name}
                        setText={setName}
                        type={props.type}
                        required={!!props.required || !!link.length}
                    />
                </FormItem>
                <FormItem top={`Ссылка для поля ${props.nameLabel}`}>
                    <Input
                        value={link}
                        required={!!name.length}
                        onChange={(e) => {
                            setLink(e.target.value)
                        }}
                    />
                </FormItem>
                <FormItem>
                    <Button
                        size="l"
                        stretched
                        onClick={() => props.onSave(name, link)}
                    >
                        <Text weight={"regular"}>Сохранить</Text>
                    </Button>
                    <br/>
                </FormItem>
            </FormLayout>
        </Group>
    )
};

export default withModalRootContext(ModalContent);

const ModalContentDescComponent = (props) => {
    const [name, setName] = useState(props.name);
    return (
        <Group>
            <FormLayout>
                <FormItem top={`Текст для описания`}>
                    <FieldWithSmiles
                        updateModalHeight={props.updateModalHeight}
                        limit={props.limit}
                        text={name}
                        setText={setName}
                        type={props.type}
                        required={true}
                    />
                </FormItem>
                <FormItem>
                    <Button
                        size="l"
                        stretched
                        onClick={() => props.onSave(name)}
                    >
                        <Text weight={"regular"}>Сохранить</Text>
                    </Button>
                </FormItem>
            </FormLayout>
        </Group>
    )
};
export const ModalContentDesc = withModalRootContext(ModalContentDescComponent);
