import React, {useEffect, useState} from 'react';
import stl from './CoverListWidgetStyles.module.css'
import s from './../../common/styles/WidgetCommonStyles.module.css'
import sList from '../../common/styles/WidgetList.module.css';
import {Cell, File, Spacing, Text} from "@vkontakte/vkui";
import '../../../../styles/css/index.css';
import {DivForText} from "../../common/components/DivForText/DivForText";
import {Icon16Add, Icon36Camera} from "@vkontakte/icons";
import DeleteButton from "../../common/components/Buttons/DeleteButton";
import FieldWithSmiles from "../../common/components/FieldWithSmiles/FieldWithSmiles";
import {unescapeHtml} from "../../../../common/helpers/functions";
import AddNewItemButton from "../../common/components/Buttons/AddNewItemButton";
import FlatButton from "../../common/components/Buttons/FlatButton";

const CoverListWidgetPanel = (props) => {

    const inputImgRef = React.createRef();
    const [active, setActive] = useState(
        props.rows.map(() =>
            ({
                title: false,
                titleCount: false,
                text: false,
                time: false,
                descr: false,
                footer: false,
            })
        )
    );
    const [items, setItems] = useState(props.rows);

    useEffect(() => {
        setActive(props.rows.map(() => ({
                title: false,
                titleCount: false,
                text: false,
                time: false,
                descr: false,
                footer: false,
            })
        ));
    }, [props.rows]);

    useEffect(() => {
        setItems(props.rows);
    }, [props.rows]);

    const setActiveField = (field, key, value) => {
        return () => {
            setActive(active.map((elem, i) => i === key ? {...elem, [field]: value} : elem))
        }
    };

    const canAddNewRow = () => {
        return items.length < 3
    };


    const emptyText = () => {
        return <span style={{color: "red"}}>Текст отсутствует</span>
    };

    const setRowDescr = (key) => {
        return (value) => {
            props.setRowDescr(key, value);
        }
    };

    const onBlurFunc = (field, key) => {
        return () => {
            setActiveField(`${field}`, key, false)();
        }
    };

    const onClick = (func, key) => {
        return () => {
            props.setCurrentRow(key);
            func();
        }
    };

    const rows = items.map((elem, key) => {
        const title = unescapeHtml(elem.title.trim());
        const descr = elem.descr.trim();
        const button = unescapeHtml(elem.button.trim());
        return (
            <div className={stl.listItem} key={key}>
                <File
                    className={stl.listItem__icon}
                    style={{
                        backgroundImage: `url(${elem.icon_url})`,
                        backgroundSize: `488px 128px`,
                    }}
                    onChange={(e) => {
                        props.onChangeImg(e, key);
                    }}
                    align={"center"}
                    accept='image/jpeg,image/png'
                >
                    {elem.icon_id ? null
                        : <>
                            <div className={stl.icon__info}>
                                <Icon36Camera/>
                            </div>
                            <Text weight={"regular"} className={"text-center"}>Добавьте изображение<br />Рекомендуемый размер 1530x384</Text>
                        </>}
                </File>
                <input
                    type={"file"}
                    className={stl.hidden}
                    ref={inputImgRef}
                    accept='image/jpeg,image/png'
                    onChange={(event) => {
                        props.onChangeImg(event)
                    }}
                />
                <Spacing size={10}/>
                <div
                    onClick={onClick(props.openModals.title, key)}
                    className={s.textLink}
                >
                    {title ? title : emptyText()}
                </div>
                {active[key].descr ?
                    <div style={{width: "100%", fontSize: "12px"}}>
                        <FieldWithSmiles
                            updateModalHeight={null}
                            limit={100}
                            text={descr}
                            setText={setRowDescr(key)}
                            type={"input"}
                            required={false}
                            onBlurFunc={onBlurFunc('descr', key)}
                        />
                    </div>
                    :
                    <DivForText
                        classname={s.textDescr}
                        text={elem.descr}
                        onClick={setActiveField("descr", key, true)}
                    />
                }
                <FlatButton
                    onClick={onClick(props.openModals.button, key)}
                >
                    {button ? button : <Icon16Add/>}
                </FlatButton>
            </div>
        )
    });

    return (
        <>
            <div className={sList.list}>
                {rows.map((item, index) => (
                    <Cell
                        className={sList.Cell}
                        key={index}
                        draggable
                        onDragFinish={({from, to}) => {
                            props.updateRows(from, to);
                        }}
                    >
                        {rows.length !== 1 &&
                        <DeleteButton styles={{top: 15, right: 20}} deleteFunc={props.deleteRow(index)}/>}
                        {item}
                    </Cell>
                ))
                }
            </div>
            {canAddNewRow() && <AddNewItemButton addRowItem={props.addRowItem}/>}
        </>
    )
};

export default CoverListWidgetPanel;
