import React from "react";
import {YMInitializer} from "react-yandex-metrika";
import * as PropTypes from "prop-types";

const YandexMetrika = props => {
    const {counter} = props;

    return (
        <YMInitializer accounts={[+counter]} version={"2"} options={{trackHash: !0}}/>
    );
}

YandexMetrika.propTypes = {
    counter: PropTypes.number
};

export default YandexMetrika;
