import React from 'react';
import PropTypes from 'prop-types';
import {Button, Div} from "@vkontakte/vkui";
import {Icon24Add} from "@vkontakte/icons";

const AddNewItemButton = props => {
    return (
        <Div className={"flex-centered"}>
            <Button
                size={"l"}
                style={{borderRadius: "50%"}}
                before={<Icon24Add/>}
                onClick={props.addRowItem}
            >
            </Button>
        </Div>
    );
};

AddNewItemButton.propTypes = {
    addRowItem: PropTypes.func.isRequired,
};

export default AddNewItemButton;
