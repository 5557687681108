import {useSelector} from "react-redux";

export const useSelectorApp = () => useSelector(state => state.app);
export const useSelectorRouter = () => useSelector(state => state.router);
export const useSelectorSubscriptions = () => useSelector(state => state.subscriptions);
export const useSelectorGroup = () => useSelector(state => state.app.group);
export const useSelectorSettings = () => useSelector(state => state.app.settings);
export const useSelectorWidget = () => useSelector(state => state.app.widget);
export const useViewer = () => useSelector(state => state.app.viewer);
export const useIsAdmin = () => useSelector(state => !!state.app.viewer.isAdmin);
export const useAnimationInProgress = () => useSelector(state => !!state.app.animationInProgress);
export const useIsCheckedConnection = () => useSelector(state => !!state.app.isCheckedConnection);