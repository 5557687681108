import {hidePopout, setActiveModal} from "../../../redux/root/rootReducer";
import {widgetsAPI} from "../../../api/widgets/widgetsAPI";

export const deleteWidgetFunction = async (dispatch, getState, widgetId) => {
    try {
        await widgetsAPI.delete(widgetId);
        const response = await widgetsAPI.getList();
        dispatch(hidePopout());
        dispatch(setActiveModal(null));
        return response;
    } catch (e) {
        dispatch(hidePopout());
        dispatch(setActiveModal(null));
        return false;
    }
};

export const deleteWidgetFunctionV2 = async (dispatch, getState, widgetIds) => {
    try {
        const result = await widgetsAPI.delete(widgetIds);
        dispatch(hidePopout());
        dispatch(setActiveModal(null));
        return result;
    } catch (e) {
        dispatch(hidePopout());
        dispatch(setActiveModal(null));
        return false;
    }
};