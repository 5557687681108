import React from "react";
import {Alert, ScreenSpinner, Spacing} from "@vkontakte/vkui";
import {AlertIcon, AlertText, AlertTitle} from "../../common/components/Alerts/Components";
import PopoutSnackbar from "../../common/components/popouts/PopoutSnackbar";
import {getHashObject, getSearchObject} from "../../common/helpers/functions";

const hashObject = getHashObject();
const searchObject = getSearchObject();

const CHANGE_APPEARANCE = 'CHANGE_APPEARANCE';
const SET_SETTINGS = 'SET_SETTINGS';
const SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL';
const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
const SET_BRIDGE_INIT = 'SET_BRIDGE_INIT';
const SET_LOADING = 'SET_LOADING';
const SET_POPOUT = 'SET_POPOUT';
const SET_MODAL = 'SET_MODAL';
const SET_SHOW_HEADER = 'SET_SHOW_HEADER';
const SET_ARRAY_EMOJI = 'SET_ARRAY_EMOJI';
const SET_ODR_ENABLED = 'SET_ODR_ENABLED';
const SET_VK_APP_VERSION = 'SET_VK_APP_VERSION';
const SET_REQUEST_SCHEME = 'SET_REQUEST_SCHEME';
const SET_IGNORE_CHANGE_FRAGMENT = 'SET_IGNORE_CHANGE_FRAGMENT';
const SET_CHECKED_CONNECTION = 'SET_CHECKED_CONNECTION';

const initialState = {
    hashObject,
    searchObject,
    ignoreChangeFragment: false,
    odrEnabled: false,
    vkAppVersion: null,
    requestScheme: 'https',
    isLoading: false,
    isBridgeInit: false,
    appearance: 'light',
    settings: [],
    group: [],
    viewer: {
        isAdmin: false
    },
    showHeader: true,
    popout: null,
    modal: null,
    activeModal: null,
    activeTab: null,
    emoji: [],
    isCheckedConnection: false,
}

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVE_MODAL:
            return {
                ...state,
                activeModal: action.modal
            };
        case SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.tab
            };
        case SET_SETTINGS: {
            const newState = {...state};
            if (action.settings) {
                newState.settings = action.settings;
            }

            if (action.group) {
                newState.group = action.group;
            }

            if (action.viewer) {
                newState.viewer = action.viewer;
            }
            return newState;
        }
        case SET_POPOUT:
            return {
                ...state,
                popout: action.popout
            }
        case SET_MODAL:
            return {
                ...state,
                modal: action.modal
            }
        case CHANGE_APPEARANCE:
            return {
                ...state,
                appearance: action.appearance
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.flag,
                popout: action.flag ? <ScreenSpinner/> : null
            };
        case SET_BRIDGE_INIT:
            return {
                ...state,
                isBridgeInit: action.flag
            };
        case SET_SHOW_HEADER:
            return {
                ...state,
                showHeader: action.flag,
            }
        case SET_ARRAY_EMOJI:
            return {
                ...state,
                emoji: action.arrayEmoji
            };
        case SET_VK_APP_VERSION:
            return {
                ...state,
                vkAppVersion: action.version
            };
        case SET_ODR_ENABLED:
            return {
                ...state,
                odrEnabled: action.flag
            };
        case SET_REQUEST_SCHEME:
            return {
                ...state,
                requestScheme: action.scheme
            };
        case SET_IGNORE_CHANGE_FRAGMENT:
            return {
                ...state,
                ignoreChangeFragment: action.flag
            };
        default:
            return state;
    }
}

export default rootReducer;

export const setModal = (modal) => ({type: SET_MODAL, modal});
export const setActiveModal = (modal) => ({type: SET_ACTIVE_MODAL, modal});
export const setActiveTab = (tab) => ({type: SET_ACTIVE_TAB, tab});
export const setSettings = (settings, group, viewer) => ({type: SET_SETTINGS, settings, group, viewer});
export const changeAppearance = (appearance) => ({type: CHANGE_APPEARANCE, appearance});
export const setShowHeader = (flag) => ({type: SET_SHOW_HEADER, flag});
export const setBridgeInit = (flag) => ({type: SET_BRIDGE_INIT, flag});
export const setLoading = (flag) => ({type: SET_LOADING, flag});
export const setArrayEmoji = (arrayEmoji) => ({type: SET_ARRAY_EMOJI, arrayEmoji});
export const setVKAppVersion = (version) => ({type: SET_VK_APP_VERSION, version});
export const setODREnabled = (flag) => ({type: SET_ODR_ENABLED, flag});
export const setIgnoreChangeFragment = (flag) => ({type: SET_IGNORE_CHANGE_FRAGMENT, flag});
export const setRequestScheme = (scheme) => ({type: SET_REQUEST_SCHEME, scheme});
export const setCheckedConnection = (flag) => ({type: SET_CHECKED_CONNECTION, flag});

const setPopout = (popout) => ({type: SET_POPOUT, popout});

export const showPopout = (Component) => {
    return (dispatch) => {
        dispatch(setPopout(Component));
    }
}

export const hidePopout = () => {
    return (dispatch) => {
        dispatch(setPopout(null));
    }
}

export const showSnackbar = ({text, success}, rest = {}) => {
    return (dispatch) => {
        dispatch(setPopout(<PopoutSnackbar text={text} success={success} {...rest} />));
    }
}

export const toggleLoading = (flag = undefined) => {
    return (dispatch, getState) => {
        if (flag !== undefined) {
            const prevValue = getState().app.isLoading;
            flag = !prevValue;
        }
        dispatch(setLoading(flag));
        let popout = flag ? <ScreenSpinner/> : null;
        dispatch(showPopout(popout));
    }
}

export const showAlert = (props) => {
    let {
        message,
        title = 'Внимание',
        icon = null,
        actions = null,
        actionsLayout = "vertical"
    } = props;

    return (dispatch) => {
        if (!actions) {
            actions = [{
                title: 'Продолжить',
                autoclose: true,
                mode: 'cancel'
            }]
        }

        dispatch(showPopout(
            <Alert
                actions={actions}
                actionsLayout={actionsLayout}
                onClose={() => dispatch(hidePopout())}
            >
                {icon && <AlertIcon>{icon}</AlertIcon>}
                <AlertTitle>{title}</AlertTitle>
                <Spacing size={12}/>
                <AlertText>{message}</AlertText>
            </Alert>
        ))
    }
}

export const showAlertAreYouSure = (props) => {
    const {onAccept, acceptText = 'Да', message, title = 'Подтвердите действие'} = props;
    return (dispatch) => {
        const actions = [
            {
                title: acceptText,
                autoclose: true,
                mode: "destructive",
                action: onAccept ? () => onAccept() : undefined,
            },
            {
                title: "Отмена",
                autoclose: true,
                mode: "cancel",
            }
        ]

        dispatch(showPopout(
            <Alert
                actions={actions}
                actionsLayout="horizontal"
                onClose={() => dispatch(hidePopout())}
            >
                <AlertTitle>{title}</AlertTitle>
                {
                    message
                    &&
                    <>
                        <Spacing size={12}/>
                        <AlertText>{message}</AlertText>
                    </>
                }
            </Alert>
        ))
    }
}