import {appAPI} from "../../api/app/appAPI";
import {setSettings} from "../../redux/root/rootReducer";
import {useDispatch} from "react-redux";

const useLoadSettings = () => {
    const dispatch = useDispatch();

    async function loadSettings() {
        const res = await appAPI.loadSettings();
        dispatch(setSettings(res.settings, res.group, res.viewer));
        return true;
    }

    return loadSettings;
};

export default useLoadSettings;