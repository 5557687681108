import React from "react";
import {PANELS} from "../../config/routes";
import {View} from "@vkontakte/vkui";
import WidgetsList from "./common/components/WidgetsList";
import ListChooseTypeWidget from "./panels/ListChooseTypeWidget";
import WidgetScreen from "./panels/WidgetScreen";
import * as PropTypes from "prop-types";

const WidgetsView = props => {
    return (
        <View id={props.id} activePanel={props.activePanel} onSwipeBack={props.onSwipeBack} history={props.history}>
            <WidgetsList id={PANELS.WIDGETS}/>
            <ListChooseTypeWidget id={PANELS.WIDGETS_SELECT}/>
            <WidgetScreen id={PANELS.WIDGET}/>
        </View>
    )
}

WidgetsView.propTypes = {
    id: PropTypes.string.isRequired,
    activePanel: PropTypes.string,
}

export default WidgetsView;