import {APIBaseConfig} from "../../config/api/index";
import {getSearchObject, objectToFormData} from "./functions";
import {showSnackbar} from "../../redux/root/rootReducer";
import store from "../../redux/store";

export const buildQuery = (params) => {
    if (Object.keys(params).length === 0) return '';
    return Object.entries(params).map((item) => item.join('=')).join('&');
};

export const requestAjaxPost = async (method, object = {}, snackbarOnError = true) => {
    try {
        return await ajaxPostWithSearch(method, objectToFormData(object));
    } catch (e) {
        console.log(e);
        if (snackbarOnError) {
            store.dispatch(showSnackbar({
                text: e.text ?? 'Произошла ошибка. Обновите страницу и попробуйте позже'
            }));
        }
        throw e;
    }
}

export const requestAjaxGet = async (method, object = {}, snackbarOnError = true) => {
    try {
        return await ajaxGetWithSearch(method, object);
    } catch (e) {
        console.log(e);
        if (snackbarOnError) {
            store.dispatch(showSnackbar({
                text: e.text ?? 'Произошла ошибка. Обновите страницу и попробуйте позже'
            }));
        }
        throw e;
    }
}

export const ajaxGet = async (method = '', data = {}) => {
    const json = await fetch(getSchemeODR() + APIBaseConfig.host + '/ajax?' + buildQuery({method, ...data}));

    const response = await json.json();
    if (!response.status) {
        throw response.error;
    }

    return response;
};

export const ajaxGetWithSearch = async (method = '', data = {}) => {
    return ajaxGet(method, {...data, searchParams: JSON.stringify(getSearchObject())});
};

export const ajaxPost = async (method = '', formData = new FormData()) => {
    const json = await fetch(getSchemeODR() + APIBaseConfig.host + '/ajax?' + buildQuery({method}), {
        method: 'POST',
        body: formData
    });

    const response = await json.json();
    if (!response.status) {
        throw response.error;
    }

    return response;
};

export const ajaxPostWithSearch = async (method = '', formData = new FormData()) => {
    formData.append('searchParams', JSON.stringify(getSearchObject()));
    return ajaxPost(method, formData);
};

const getSchemeODR = () => {
    return (window.scheme ?? 'https') + '://';
}