import React, {useEffect} from 'react';
import CurrentWidget from "./CurrentWidget/CurrentWidget";
import TextWidgetPanelContainer from "./TextWidgetPanel/TextWidgetPanelContainer";
import ListWidgetPanelContainer from "./ListWidgetPanel/ListWidgetPanelContainer";
import {
    COMPACT_LIST_WIDGET,
    COVER_LIST_WIDGET,
    CURRENT_WIDGET,
    LIST_WIDGET,
    PUBLISH,
    SAVING,
    setSavingType,
    TEXT_WIDGET,
    TILES_SQUARE_WIDGET,
    TILES_WIDGET
} from "../../../redux/parts/widgetReducer";
import {useDispatch, useSelector} from "react-redux";
import TilesWidgetPanelContainer from "./TilesWidgetPanel/TilesWidgetPanelContainer";
import CompactListWidgetPanelContainer from "./CompactListWidgetPanel/CompactListWidgetPanelContainer";
import CoverListWidgetPanelContainer from "./CoverListWidgetPanel/CoverListWidgetPanelContainer";
import WidgetTitle from "../common/components/WidgetTitle";
import withOpenModals from "../../../common/hoc/withOpenModals";
import WidgetFooter from "../common/components/WidgetFooter";
import s from "./widgetStyles.module.css";
import stlTiles from "./TilesWidgetPanel/TilesWidgetStyles.module.css";
import {Button, Card, Footer, Panel, Text} from "@vkontakte/vkui";
import Icon24Advertising from '@vkontakte/icons/dist/24/advertising';
import Icon24Document from '@vkontakte/icons/dist/24/document';
import THPanelHeader from "../../../common/components/THPanelHeader";
import {setArrayEmoji} from "../../../redux/root/rootReducer";
import WidgetsService from "../../../common/logic/WidgetsService";
import {getEmojiArray} from "../../../api/mailerAPI";

const mapModeToScreen = new Map()
    .set(CURRENT_WIDGET, CurrentWidget)
    .set(TEXT_WIDGET, TextWidgetPanelContainer)
    .set(LIST_WIDGET, ListWidgetPanelContainer)
    .set(COMPACT_LIST_WIDGET, CompactListWidgetPanelContainer)
    .set(TILES_SQUARE_WIDGET, TilesWidgetPanelContainer)
    .set(TILES_WIDGET, TilesWidgetPanelContainer)
    .set(COVER_LIST_WIDGET, CoverListWidgetPanelContainer);

const WidgetScreen = (props) => {
    const widgetState = useSelector(state => state.widget);
    const type = widgetState.type;
    const title = widgetState.title;
    const titleCount = widgetState.titleCount;
    const footerText = widgetState.footerText;
    const widgetType = WidgetsService.getWidgetType(widgetState);

    const dispatch = useDispatch();
    const Screen = mapModeToScreen.get(type);

    useEffect(() => {
        const fetchData = async () => {
            const emojiArray = await getEmojiArray();
            dispatch(setArrayEmoji(emojiArray));
        };
        fetchData();
    }, []);

    const saveWithoutPublish = () => {
        dispatch(setSavingType(SAVING));
        props.openModals.name();
    };

    const saveWithPublish = () => {
        dispatch(setSavingType(PUBLISH));
        props.openModals.name();
    };

    const TilesInfo = <Footer style={{marginBottom: 0}}>
        <Text weight={"2"}>
            В веб-версии VK и в предпросмотре всегда будет показываться только 3 элемента
            <br />
            В мобильных приложениях возможно отображение до 10 элементов
        </Text>
    </Footer>;

    return (
        <Panel id={props.id}>
            <THPanelHeader/>
            <div
                className={
                    `${s.container}
                    ${(widgetType === 'tiles' || widgetType === 'tiles_square') && stlTiles.container}`
                }
            >
                <Card mode={"shadow"}>
                    <WidgetTitle
                        openModalTitle={props.openModals.mainTitle}
                        title={title}
                        titleCount={titleCount}
                    />
                    <Screen/>
                    <WidgetFooter
                        openModalFooter={props.openModals.footer}
                        footerText={footerText}
                    />
                </Card>
            </div>
            {(widgetType === 'tiles' || widgetType === 'tiles_square') && TilesInfo}
            <div className={s.buttonsContainer}>
                <Button
                    size="m"
                    onClick={saveWithoutPublish}
                    className={s.saveBtn}
                    style={{width: "100%"}}
                    before={<Icon24Document/>}
                >
                    <Text weight={"regular"}>Сохранить</Text>
                </Button>
                <Button
                    size="m"
                    onClick={saveWithPublish}
                    className={s.saveBtn}
                    style={{width: "100%"}}
                    before={<Icon24Advertising/>}
                >
                    <Text weight={"regular"}>Опубликовать</Text>
                </Button>
            </div>
        </Panel>
    )
};
export default withOpenModals(WidgetScreen);
