import React from 'react';
import {Snackbar} from "@vkontakte/vkui";
import {useDispatch} from "react-redux";
import Icon16Done from '@vkontakte/icons/dist/16/done';
import Icon16Cancel from '@vkontakte/icons/dist/16/cancel';
import {hidePopout} from "../../../redux/root/rootReducer";
import * as PropTypes from "prop-types";

const PopoutSnackbar = (props) => {
    const dispatch = useDispatch();
    const duration = props.duration ?? 1500;
    const icon = props.success ? <Icon16Done fill="green"/> : <Icon16Cancel fill="red"/>;
    const textColor = props.success ? "green" : "red";

    return <Snackbar
        duration={duration}
        layout="vertical"
        onClose={() => dispatch(hidePopout())}
        before={icon}
        children={<span style={{color: textColor}}>{props.text}</span>}
    />;
};

PopoutSnackbar.propTypes = {
    text: PropTypes.string.isRequired,
    success: PropTypes.bool,
    duration: PropTypes.number,
}

export default PopoutSnackbar;
