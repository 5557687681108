import React from "react";
import {nl2br} from "../../../../../common/helpers/functions";

export const DivForText = (props) => {
    const classname = props.classname;

    const text = props.text ? nl2br(props.text) : "<span><i>Пусто</i></span>";

    return <div
        onClick={() => {
            props.onClick()
        }}
        style={props.style}
        dangerouslySetInnerHTML={{__html: text}}
        className={classname}
    />
}
