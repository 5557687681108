import React from "react";
import {Button, ButtonGroup, Div, Headline, IconButton, Spacing, Text} from "@vkontakte/vkui";
import {Icon20CopyOutline, Icon20DeleteOutline, Icon20SlidersOutline, Icon20WriteOutline} from "@vkontakte/icons";
import useIsDesktop from "../../../../common/hooks/useIsDesktop";
import {useRouter} from "@happysanta/router";
import {PAGES} from "../../../../config/routes";
import {getCurrentAppUrl} from "../../../../common/helpers/functions";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion";
import {copyText} from "../../../../common/helpers/bridge";
import {useDispatch} from "react-redux";
import {showSnackbar} from "../../../../redux/root/rootReducer";

const ItemAdminFeatures = ({item, onDelete}) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const isDesktop = useIsDesktop();
    const id = item._id.$oid;

    const goToEditor = () => router.pushPage(PAGES.SUBSCRIPTION_EDITOR, {
        item: id
    });

    const onDeleteItem = () => {
        onDelete(id, item.name);
    }

    const linkToPage = getCurrentAppUrl() + `#s=${id}`;
    const copyLink = () => {
        copyText(linkToPage)
            .then(() => {
                dispatch(showSnackbar({success: true, text: 'Ссылка скопирована в буфер обмена'}));
            })
            .catch((r) => {
                dispatch(showSnackbar({text: 'Не удалось скопировать ссылку. Попробуйте выделить текст и скопировать вручную'}));
                console.log(r);
            })
    }

    return (
        <Div style={{padding: "0 16px 12px"}}>
            <Spacing separator={"top"}/>
            <Accordion allowZeroExpanded={true} style={{paddingTop: "8px"}}>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <Button stretched size={"s"} mode={"tertiary"}
                                    before={<Icon20SlidersOutline/>}>УПРАВЛЕНИЕ</Button>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <Spacing size={12}/>
                        <ButtonGroup mode={isDesktop ? "horizontal" : "vertical"} stretched={true}>
                            <Button onClick={goToEditor}
                                    size={"m"}
                                    mode={"secondary"}
                                    stretched={true}
                                    before={<Icon20WriteOutline/>}
                            >
                                <Text weight={"regular"}>Редактировать</Text>
                            </Button>
                            <Button onClick={onDeleteItem}
                                    size={"m"}
                                    mode={"secondary"}
                                    stretched={true}
                                    appearance={"negative"}
                                    before={<Icon20DeleteOutline/>}
                            >
                                <Text weight={"regular"}>Удалить</Text>
                            </Button>
                            {
                                !isDesktop
                                &&
                                <Button
                                    onClick={copyLink}
                                    stretched
                                    size={"m"}
                                    mode={"secondary"}
                                    before={<Icon20CopyOutline/>}>
                                    Ссылка на страницу
                                </Button>
                            }
                        </ButtonGroup>
                        {
                            isDesktop
                            &&
                            <div>
                                <div style={{padding: "12px 0 4px"}}>
                                    <Headline level={2} className={"fs-small text-muted text-center"}>Ссылка на
                                        страницу</Headline>
                                </div>
                                <div
                                    className={"flex-centered text-center text-muted fs-small word-break hover-accent cursor-pointer"}
                                    onClick={copyLink}>
                                    <div className={"me-1"}>{linkToPage}</div>
                                    <IconButton style={{height: "unset"}}>
                                        <Icon20CopyOutline/>
                                    </IconButton>
                                </div>
                            </div>
                        }
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </Div>
    )
}

export default ItemAdminFeatures;