import React from "react";
import {PanelHeader, PanelHeaderBack, PanelHeaderButton, Title} from "@vkontakte/vkui";
import {Icon28HomeOutline} from "@vkontakte/icons";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import {useRouter} from "@happysanta/router";
import useGoBack from "../hooks/useGoBack";
import {PAGES} from "../../config/routes";

const THPanelHeader = (props) => {
    const router = useRouter();
    const goBack = useGoBack();
    let {title = null, hasHomeBtn = false, hasBackBtn = true, separator = true} = props;

    if (!title) {
        title = props.panelHeaderTitle;
    }

    const goHome = () => {
        router.replacePage(PAGES.HOME)
    }

    let left = hasBackBtn && <PanelHeaderBack onClick={goBack}/>;
    if (hasHomeBtn) {
        left = (<PanelHeaderButton aria-label={'Домой'} onClick={goHome}>
            <Icon28HomeOutline/>
        </PanelHeaderButton>);
    }

    return (
        <PanelHeader left={left} separator={separator}>
            <Title level={"2"} weight={"3"}>{title}</Title>
        </PanelHeader>
    )
}

THPanelHeader.propTypes = {
    title: PropTypes.string,
    hasHomeBtn: PropTypes.bool,
    hasBackBtn: PropTypes.bool,
    separator: PropTypes.bool,
}

let mapStateToProps = state => ({
    panelHeaderTitle: state.router.panelHeaderTitle,
    history: state.router.history,
})

export default connect(mapStateToProps)(THPanelHeader);