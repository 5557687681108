import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Div, File, FormItem, FormLayoutGroup, Spinner, Text,} from "@vkontakte/vkui";
import {Icon24Camera} from "@vkontakte/icons";
import {limitText} from "../../helpers/functions";
import {appAPI} from "../../../api/app/appAPI";
import useIgnoreChangeFragmentOnAndroid from "../../hooks/useIgnoreChangeFragmentOnAndroid";

const BannerUploadField = props => {
    const ignoreChangeFragmentOnAndroid = useIgnoreChangeFragmentOnAndroid();
    const {banner, setBanner, title} = props;
    const [bannerLoading, setBannerLoading] = useState(false);
    const [refBanner, setRefBanner] = useState(null);
    const [loadedFile, setLoadedFile] = useState(null);

    const onChooseBanner = async (e) => {
        const file = e.target.files[0];
        if (file === undefined) {
            onRemoveBanner();
            setBannerLoading(false);
            return;
        }

        if (loadedFile === file) return;
        setLoadedFile(file);
        setBannerLoading(true);
        try {
            const res = await appAPI.uploadBanner(file);
            setBannerLoading(false);
            setBanner(res.url);
        } catch (e) {
            onRemoveBanner();
            setBannerLoading(false);
        }
    }

    const onRemoveBanner = () => {
        setBanner('');
        refBanner.value = "";
        setLoadedFile(null);
    }

    let bannerText = '';
    if (banner) {
        bannerText = banner.replace(/\?.*/, '');
        const parts = bannerText.split('/').pop().split('.');
        const fileExt = parts.pop();
        const fileName = parts.join();
        bannerText = fileExt.toUpperCase() + ': ' + limitText(fileName, 20);
    }
    return (
        <>
            <FormItem top={title} bottom={"Рекомендуемый формат: 16х9"}>
                <File before={<Icon24Camera/>}
                      accept="image/jpeg,image/png,image/gif"
                      controlSize="m"
                      onChange={onChooseBanner}
                      onClick={ignoreChangeFragmentOnAndroid}
                      getRef={(ref) => setRefBanner(ref)}
                >
                    <Text weight={"regular"}>Открыть галерею</Text>
                </File>
            </FormItem>
            {
                bannerLoading ?
                    <Spinner/>
                    :
                    <FormLayoutGroup
                        mode="horizontal"
                        removable
                        style={{paddingTop: 0, display: (banner || bannerLoading ? "block" : "none")}}
                        onRemove={onRemoveBanner}
                    >
                        <Text className={"text-muted"} weight={"regular"}>
                            {bannerText}
                        </Text>
                        <Div id={'bannerPreview'}
                             style={{width: '100%', paddingRight: 0, paddingTop: 0, paddingBottom: 0}}>
                            <img src={banner} style={{width: '100%'}} alt={"Баннер"}/>
                        </Div>
                    </FormLayoutGroup>
            }
        </>
    );
};

BannerUploadField.propTypes = {
    banner: PropTypes.string,
    setBanner: PropTypes.func,
};

export default BannerUploadField;
