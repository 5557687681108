import React, {useEffect, useState} from "react";
import {Button, Panel, Placeholder, Spinner, Text} from "@vkontakte/vkui";
import bridge from "@vkontakte/vk-bridge";
import {showPopout} from "../../../../redux/root/rootReducer";
import {connect} from "react-redux";
import {getSearchObject} from "../../../../common/helpers/functions";
import {Icon24Add, Icon56UsersOutline} from "@vkontakte/icons";
import {appAPI} from "../../../../api/app/appAPI";
import THPanelHeader from "../../../../common/components/THPanelHeader";
import * as PropTypes from "prop-types";
import useLoadSettings from "../../../../common/hooks/useLoadSettings";
import {useRouter} from "@happysanta/router";
import {PAGES} from "../../../../config/routes";

const GroupConnection = (props) => {
    const {isBridgeInit} = props;
    const router = useRouter();
    const loadSettings = useLoadSettings();
    const [isError, setIsError] = useState(false);

    const getGroupInfo = async () => {
        const search = getSearchObject();
        return await bridge.send("VKWebAppGetGroupInfo", {
            "group_id": +search.vk_group_id,
        });
    }

    const init = async () => {
        setIsError(false);
        let res;

        setTimeout(async () => {
            try {
                res = await getGroupInfo();
            } catch (e) {
                setIsError(true);
                return;
            }
            try {
                await appAPI.connectGroup();
                await loadSettings();
            } catch (e) {
                setIsError(true);
                return;
            }
            router.replacePage(PAGES.HOME);
        }, 0); // Зависает экран, если слишком быстро проходят операции
    }

    useEffect(() => {
        if (!isBridgeInit) return;
        init();
    }, [isBridgeInit]);

    if (isError) {
        return (
            <Panel id={props.id}>
                <THPanelHeader hasBackBtn={false}/>
                <Placeholder
                    icon={<Icon56UsersOutline/>}
                    header="Для полноценной работы приложения необходимо подключить сообщество"
                    action={
                        <Button
                            size="m"
                            before={<Icon24Add/>}
                            onClick={init}>
                            <Text weight={"regular"}>Подключиться</Text>
                        </Button>}
                >
                    Это позволит вам создавать собственные рассылки и страницы подписок на эти рассылки, а также
                    различные типы виджетов прямо в приложении<br/>
                    Нажмите кнопку ниже, чтобы это сделать
                </Placeholder>
            </Panel>
        );
    }

    return <Spinner/>;
}

GroupConnection.propTypes = {
    id: PropTypes.string.isRequired,
}

let mapStateToProps = state => ({
    isBridgeInit: state.app.isBridgeInit,
})

export default connect(mapStateToProps, {showPopout})(GroupConnection);