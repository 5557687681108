const SET_CACHED_LIST = 'SET_CACHED_LIST';
const SET_CACHED_ITEM = 'SET_CACHED_ITEM';
const DROP_CACHE = 'DROP_CACHE';

const initialState = {
    cachedList: {},
    cachedItem: {},
}

const subscriptionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CACHED_LIST:
            const cachedList = {...state.cachedList};
            cachedList[action.mode] = action.list;
            return {
                ...state,
                cachedList: cachedList
            };
        case SET_CACHED_ITEM:
            const cachedItem = {};
            cachedItem[action.id] = action.item;
            return {
                ...state,
                cachedItem: cachedItem
            };
        case DROP_CACHE:
            return initialState;
        default:
            return state;
    }
}

export default subscriptionsReducer;

export const dropCache = () => ({type: DROP_CACHE});
export const setCachedItem = (item, id) => ({type: SET_CACHED_ITEM, item, id});
export const setCachedList = (list, mode) => ({type: SET_CACHED_LIST, list, mode});