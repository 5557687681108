import React, {useEffect, useState} from 'react';
import {
    Alert,
    Button,
    Card,
    Cell,
    CellButton,
    Checkbox,
    Div,
    FixedLayout,
    Group,
    Header,
    List,
    Panel,
    Separator,
    Spacing,
    Text,
    Title
} from "@vkontakte/vkui";
import {connect, useDispatch, useSelector} from "react-redux";
import {deleteWidgetsFromList, setListWidgetData, widgetsLoad,} from "../../../../redux/parts/widgetListReducer";
import Icon24Add from '@vkontakte/icons/dist/24/add';
import {hidePopout, showPopout,} from "../../../../redux/root/rootReducer";
import {
    CURRENT_WIDGET,
    setCurrent,
    setGeneralWidgetData,
    setGeneralWidgetToDefault,
    setTextWidgetData,
    setTextWidgetToDefault,
    setType
} from "../../../../redux/parts/widgetReducer";
import s from "../../panels/widgetStyles.module.css";
import list_preview from '../../../../assets/img/widgetPreview/list.png'
import compact_list_preview from '../../../../assets/img/widgetPreview/compact_list.png'
import cover_list_preview from '../../../../assets/img/widgetPreview/cover_list.png'
import text_preview from '../../../../assets/img/widgetPreview/text.png'
import tiles_preview from '../../../../assets/img/widgetPreview/tiles.png'
import tiles_square_preview from '../../../../assets/img/widgetPreview/tiles_square.png'
import Icon24DismissSubstract from '@vkontakte/icons/dist/24/dismiss_substract';
import Icon24CheckBoxOn from '@vkontakte/icons/dist/24/check_box_on';
import Icon24CheckBoxOff from '@vkontakte/icons/dist/24/check_box_off';
import {PAGES} from "../../../../config/routes";
import THPanelHeader from "../../../../common/components/THPanelHeader";
import {replaceQuotes} from "../../../../common/helpers/functions";
import {useRouter} from "@happysanta/router";
import {AlertText, AlertTitle} from "../../../../common/components/Alerts/Components";

const objTypeToData = {
    'text': {
        title: "Важное сообщение",
        text: "Текст",
        src: text_preview
    },
    'list': {
        title: "Списки",
        text: "Список",
        src: list_preview
    },
    'compact_list': {
        title: "Списки",
        text: "Компактный список",
        src: compact_list_preview
    },
    'cover_list': {
        title: "Обложки",
        text: "Обложка",
        src: cover_list_preview
    },
    'tiles': {
        title: "Плитки",
        text: "Прямоугольная плитка",
        src: tiles_preview
    },
    'tiles_square': {
        title: "Плитки",
        text: "Квадратная плитка",
        src: tiles_square_preview
    }
};

const WidgetList = (props) => {
    const [editMode, setEditMode] = useState(false);
    const [arrayForDelete, setArrayForDelete] = useState([]);
    const widgets = useSelector(state => state.listWidget.widgets) ?? []; // TODO fix
    const activeWidget = useSelector(state => state.widget.activeWidgetId);
    const dispatch = useDispatch();
    const router = useRouter();

    useEffect(() => {
        setArrayForDelete([]);
    }, [editMode]);


    useEffect(() => {
        dispatch(widgetsLoad());
    }, []);

    const tempArr = widgets.map(elem => {
        if (elem.type === 'tiles_square')
            return 'tiles';
        if (elem.type === 'compact_list')
            return 'list';
        else
            return elem.type
    });
    const arrayTypes = [...new Set(tempArr)];

    let flag = false;

    const setWidget = (element) => {
        if (!flag) {
            element = replaceQuotes(JSON.stringify(element));
            dispatch(setCurrent(JSON.parse(element)));
            router.pushPage(PAGES.WIDGET);
            dispatch(setType(CURRENT_WIDGET))
        }
        flag = false
    };

    const deleteWidgets = () => {
        if (arrayForDelete.length !== 0) {
            props.deleteWidgetsFromList(arrayForDelete, setEditMode);
        } else {
            dispatch(showPopout(<Alert
                    actions={[{
                        title: 'ОК',
                        autoclose: true,
                        mode: 'cancel'
                    }]}
                    onClose={() => {
                        dispatch(hidePopout())
                    }}
                >
                    <AlertTitle>Ничего не выбрано</AlertTitle>
                    <Spacing size={12}/>
                    <AlertText>Вы не выбрали ни одного виджета. Попробуем еще раз?</AlertText>
                </Alert>
            ))
        }
    };

    const getId = (widget) => {
        return widget._id.$oid;
    };


    const editWidgetFunction = (e, id) => {
        if (arrayForDelete.includes(id)) {
            const index = arrayForDelete.indexOf(id);
            arrayForDelete.splice(index, 1);
            setArrayForDelete([...arrayForDelete]);
        } else {
            setArrayForDelete([...arrayForDelete, id]);
        }
    };

    const checkboxToggleAll = () => {
        if (arrayForDelete.length === widgets.length) {
            setArrayForDelete([]);
        } else {
            const tempArrayForDelete = [];
            widgets.map((elem) => {
                tempArrayForDelete.push(getId(elem));
                return 0;
            });
            setArrayForDelete(tempArrayForDelete);
        }
    };

    return (
        <Panel id={props.id}>
            <THPanelHeader hasHomeBtn={true}/>
            <Group separator={"hide"}>
                <CellButton
                    before={<Icon24Add/>}
                    onClick={() => {
                        dispatch(setTextWidgetToDefault());
                        dispatch(setGeneralWidgetToDefault());
                        router.pushPage(PAGES.WIDGETS_SELECT);
                    }}
                >
                    Добавить виджет
                </CellButton>
                <Spacing separator={true}/>
            </Group>

            <div className={s.header}>
                <Title level={1}>Мои виджеты</Title>
                <Title level={3} className={s.header__editBtn}>
                    <Button
                        size={"m"}
                        mode={"tertiary"}
                        onClick={() => {
                            setEditMode(!editMode)
                        }}
                    >
                        <Text weight={"regular"}>Изменить</Text>
                    </Button>
                </Title>
            </div>
            {
                widgets.length === 0 ?
                    <Div>Здесь будет список ваших виджетов</Div>
                    :
                    arrayTypes.map((elem, key) => {
                        if (!objTypeToData[elem]) return;
                        return (
                            <Group
                                key={key}
                                header={<Header mode="secondary">{objTypeToData[elem].title}</Header>}
                            >
                                <List className={`${editMode && s.List_widgets}`}>
                                    {
                                        widgets.map((widgetElement, index) => {
                                        return ((widgetElement.type === elem)
                                            || (widgetElement.type === 'compact_list' && elem === 'list')
                                            || (widgetElement.type === 'tiles_square' && elem === 'tiles')) &&
                                            <div key={index}>
                                                <div>
                                                    {editMode ?
                                                        <>
                                                            <Checkbox
                                                                style={{padding: "0 12px", alignItems: "center"}}
                                                                className={`List__Item ${getId(widgetElement) === activeWidget && s.activeItem}`}
                                                                onChange={(e) => {
                                                                    editWidgetFunction(e, getId(widgetElement))
                                                                }}
                                                                checked={arrayForDelete.includes(getId(widgetElement))}
                                                            >
                                                                <Cell
                                                                    className={`List__Item`}
                                                                    multiline
                                                                    before={
                                                                        <Card style={{marginRight: "15px"}} size="m">
                                                                            <div className={s.cardImageContainer}>
                                                                                <img
                                                                                    src={objTypeToData[widgetElement.type].src}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                        </Card>
                                                                    }
                                                                    size="l"
                                                                    description={objTypeToData[widgetElement.type].text}
                                                                >
                                                                    {widgetElement[`name`]}
                                                                </Cell>
                                                            </Checkbox>
                                                            <FixedLayout
                                                                filled={true}
                                                                vertical="bottom"
                                                                className={s.footer__container__editMode}
                                                            >
                                                                <Separator wide/>
                                                                <div style={{display: "flex"}}>
                                                                    <div
                                                                        className={s.footer__enableCheckboxBtn__editMode}
                                                                    >
                                                                        <CellButton
                                                                            onClick={checkboxToggleAll}
                                                                            before={arrayForDelete.length === widgets.length ?
                                                                                <Icon24CheckBoxOn/> :
                                                                                <Icon24CheckBoxOff/>
                                                                            }>
                                                                            {arrayForDelete.length === widgets.length ? "Снять выделение" : "Отметить все"}
                                                                        </CellButton>
                                                                    </div>
                                                                    <div
                                                                        className={s.footer__deleteBtn__editMode}
                                                                        onClick={deleteWidgets}
                                                                    >
                                                                        <CellButton
                                                                            style={{color: "red"}}
                                                                            before={<Icon24DismissSubstract
                                                                                style={{paddingRight: "5px"}}/>}
                                                                        >
                                                                            Удалить
                                                                        </CellButton>
                                                                    </div>
                                                                </div>
                                                            </FixedLayout>
                                                        </>
                                                        :
                                                        <Cell
                                                            onClick={() => {
                                                                setWidget(widgetElement);
                                                            }}
                                                            multiline
                                                            className={
                                                                `List__Item 
                                                    ${getId(widgetElement) === activeWidget && s.activeItem}`
                                                            }
                                                            before={
                                                                <Card style={{marginRight: "15px"}} size="m">
                                                                    <div className={s.cardImageContainer}>
                                                                        <img
                                                                            src={objTypeToData[widgetElement.type].src}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </Card>
                                                            }
                                                            size="l"
                                                            description={objTypeToData[widgetElement.type].text}
                                                        >
                                                            {widgetElement[`name`]}
                                                        </Cell>
                                                    }
                                                </div>
                                                <br/>
                                            </div>
                                    })
                                }
                            </List>
                        </Group>
                    )
                })
            }
        </Panel>
    )
};

export default connect(null, {
    setGeneralWidgetData,
    setListWidgetData,
    setTextWidgetData,
    deleteWidgetsFromList
})(WidgetList);
