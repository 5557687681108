import React, {useState} from "react";
import * as PropTypes from "prop-types";
import {useSelectorSettings} from "../../../../../redux/selectors";
import SubscriptionBanner from "../../../common/components/SubscriptionBanner";
import SubListContainer from "../components/SubListContainer";
import {HIDDEN, PUBLIC} from "../../../../../config/SubscriptionTypes";
import {PullToRefresh} from "@vkontakte/vkui";

const SubListTab = (props) => {
    const {mode, forUser = false} = props;
    const [isFetching, setIsFetching] = useState(false);
    const settings = useSelectorSettings();
    const showBanner = mode === PUBLIC && settings?.banner;
    if (showBanner && props.setSeparator) {
        props.setSeparator(false);
    }
    return (
        <>
            <PullToRefresh
                onRefresh={() => setIsFetching(true)}
                isFetching={isFetching}
            >
                {
                    showBanner
                    &&
                    <SubscriptionBanner item={{banner: settings.banner}}/>
                }
                <SubListContainer mode={mode} forUser={forUser} isFetching={isFetching} setIsFetching={setIsFetching}/>
            </PullToRefresh>
        </>
    );
}

SubListTab.propTypes = {
    mode: PropTypes.oneOf([PUBLIC, HIDDEN, null]),
    forUser: PropTypes.bool,
    unsubAllBtn: PropTypes.bool,
}

export default SubListTab;