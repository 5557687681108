import React, {useEffect, useState} from "react";
import Subscription from "./Subscription";
import {connect, useDispatch} from "react-redux";
import {hidePopout, showPopout, showSnackbar} from "../../../../redux/root/rootReducer";
import {resizeWindow} from "../../../../common/helpers/bridge";
import {useToggleSubscribe} from "../../common/hooks/useToggleSubscribe";
import {subscriptionsAPI} from "../../../../api/subscriptions/subscriptionsAPI";
import {useSelectorGroup} from "../../../../redux/selectors";
import {Panel, PanelSpinner} from "@vkontakte/vkui";
import {hit} from "../../../../common/helpers/analytics";
import * as PropTypes from "prop-types";
import useIsDesktop from "../../../../common/hooks/useIsDesktop";
import {useFirstPageCheck} from "@happysanta/router";
import THPanelHeader from "../../../../common/components/THPanelHeader";
import useGoBack from "../../../../common/hooks/useGoBack";

const SubscriptionContainer = props => {
    const dispatch = useDispatch();
    const goBack = useGoBack();
    const {hashObject} = props;
    const toggleSubscribe = useToggleSubscribe(hashObject);
    const [item, setItem] = useState(null);
    const [triggeredSub, setTriggeredSub] = useState(false);
    const pageTitle = useSelectorGroup()?.name ?? 'Здравствуйте';
    const showHeader = !useFirstPageCheck();
    const isDesktop = useIsDesktop();

    const isNotCurrentPage = () => {
        return !hashObject?.s;
    }

    const isAlreadyFetched = () => {
        return item !== null && item?._id?.$oid === hashObject.s;
    }

    const isChangedHashItem = () => {
        return item !== null && item?._id?.$oid !== hashObject.s;
    }

    if (isChangedHashItem()) {
        setItem(null);
        setTriggeredSub(false);
    }

    useEffect(() => {
        if (isNotCurrentPage() || isAlreadyFetched()) return;
        const subId = hashObject.s;
        if (!subId) {
            goBack();
            return;
        }

        const fetchSubscription = async () => {
            try {
                const r = await subscriptionsAPI.getSubscription(subId);
                if (!r?.subscriptions?.length) goBack();
                const data = r.subscriptions[0];
                setItem(data);
            } catch (e) {
                goBack();
            }
            dispatch(hidePopout());
            hit('view');
            hit('view_' + subId);
        }

        fetchSubscription();
    }, [hashObject, item]);

    const onSub = async (item) => {
        const newItem = await toggleSubscribe(item);
        if (!newItem) {
            return;
        }
        setTriggeredSub(true);
        setItem(newItem);
    };

    useEffect(() => {
        if (!item || triggeredSub || isNotCurrentPage()) return;
        if (+hashObject.force !== 1) return;
        if (item && !item.isSubscribed) onSub(item);
    }, [hashObject, item]);

    if (!item) {
        return (
            <Panel id={props.id}>
                <THPanelHeader title={'Загрузка...'} hasBackBtn={false}/>
                <PanelSpinner/>
            </Panel>
        )
    }

    return (
        <Panel id={props.id}>
            <Subscription
                item={item}
                showHeader={!isDesktop || showHeader}
                hasBackBtn={showHeader}
                pageTitle={pageTitle}
                onLoadBanner={props.onLoadBanner}
                resizeWindow={props.resizeWindow}
                onSub={onSub}
            />
        </Panel>
    );
}

SubscriptionContainer.propTypes = {
    id: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    hashObject: state.router.hashObject,
})

let mapDispatchToProps = dispatch => ({
    onLoadBanner: () => {
        resizeWindow();
        //dispatch(hidePopout());
    },
    resizeWindow: () => {
        resizeWindow();
    },
    showPopout: (Component) => {
        dispatch(showPopout(Component));
    },
    showSnackbar: ({text, success}) => {
        dispatch(showSnackbar({text, success}));
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionContainer);