import React from 'react';
import {hidePopout, setActiveModal, showPopout, showSnackbar} from "../root/rootReducer";
import {setActiveWidget, setGeneralWidgetToDefault} from "./widgetReducer";
import PopoutErrorWidget from "../../common/components/popouts/PopoutErrorWidget";
import {Alert} from "@vkontakte/vkui";
import {deleteWidgetFunctionV2} from "../../views/widgets/common/functions";
import {widgetsAPI} from "../../api/widgets/widgetsAPI";
import WidgetsService from "../../common/logic/WidgetsService";
import {showWidgetPreviewBox} from "../../common/helpers/bridge";
import {AlertText, AlertTitle} from "../../common/components/Alerts/Components";
import WidgetsCodeService from "../../views/widgets/common/WidgetsCodeService";
import {router} from "../../router";

const SET_WIDGETS = 'SET_WIDGETS';
const SET_ROWS = 'SET_ROWS';
const SET_ROW_TITLE = 'SET_ROW_TITLE';
const SET_ROW_TITLE_URL = 'SET_ROW_TITLE_URL';
const SET_ROW_BUTTON = 'SET_ROW_BUTTON';
const SET_ROW_BUTTON_URL = 'SET_ROW_BUTTON_URL';
const SET_ROW_ICON_ID = 'SET_ROW_ICON_ID';
const SET_ROW_ICON_URL = 'SET_ROW_ICON_URL';
const SET_ROW_ICON_TYPE = 'SET_ROW_ICON_TYPE';
const SET_ROW_DESCR = 'SET_ROW_DESCR';
const SET_ROW_ADDRESS = 'SET_ROW_ADDRESS';
const SET_ROW_TIME = 'SET_ROW_TIME';
const SET_ROW_TEXT = 'SET_ROW_TEXT';
const ADD_ROW = 'ADD_ROW';
const UPDATE_ROWS = 'UPDATE_ROWS';
const DELETE_ROW = 'DELETE_ROW';
const SET_CURRENT_ROW = 'SET_CURRENT_ROW';
const SET_LIST_WIDGET_TO_DEFAULT = 'SET_LIST_WIDGET_TO_DEFAULT';
const SET_WIDGET_TOKEN = 'SET_WIDGET_TOKEN';

const newRow = {
    title: "Заголовок списка",
    title_url: "vk.com",
    button: "Кнопка",
    button_url: "vk.com",
    icon_id: null,
    icon_type: null,
    descr: "Краткое описание",
    address: "Адрес",
    time: "Время",
    text: "Подробное описание",
    icon_url: "vk.com"
};

const initialState = {
    widgets: [],
    rows: [
        {...newRow}
    ],
    currentRow: null
};

const widgetReducer = (state = initialState, action) => {

    const setField = (key, value) => {
        return state.rows.map((elem, index) =>
            index === action.key ? {...elem, [key]: value} : elem
        );
    };

    switch (action.type) {
        case SET_WIDGET_TOKEN:
            return {
                ...state,
                widgetsToken: action.token
            };
        case SET_WIDGETS:
            return {
                ...state,
                widgets: action.widgets
            }
        case SET_ROWS:
            return {
                ...state,
                rows: action.rows
            };
        case SET_ROW_TITLE:
            return {
                ...state,
                rows: setField("title", action.title)
            };
        case SET_ROW_TITLE_URL:
            return {
                ...state,
                rows: setField("title_url", action.titleUrl)
            };
        case SET_ROW_BUTTON:
            return {
                ...state,
                rows: setField("button", action.btn)
            };
        case SET_ROW_BUTTON_URL:
            return {
                ...state,
                rows: setField("button_url", action.btnUrl),
            };
        case SET_ROW_ICON_ID:
            return {
                ...state,
                rows: setField("icon_id", action.id),
            };
        case SET_ROW_ICON_URL:
            return {
                ...state,
                rows: setField("icon_url", action.id),
            };
        case SET_ROW_ICON_TYPE:
            return {
                ...state,
                rows: setField("icon_type", action.iconType),
            };
        case SET_ROW_DESCR:
            return {
                ...state,
                rows: setField("descr", action.descr),
            };
        case SET_ROW_ADDRESS:
            return {
                ...state,
                rows: setField("address", action.address)
            };
        case SET_ROW_TIME:
            return {
                ...state,
                rows: setField("time", action.time)
            };
        case SET_ROW_TEXT:
            return {
                ...state,
                rows: setField("text", action.text)
            };
        case ADD_ROW:
            return {
                ...state,
                rows: [...state.rows.map(elem => elem), {...newRow}]
            };
        case UPDATE_ROWS:
            const draggingList = [...state.rows];
            draggingList.splice(action.from, 1);
            draggingList.splice(action.to, 0, state.rows[action.from]);
            return {
                ...state,
                currentRow: action.to,
                rows: draggingList
            };
        case DELETE_ROW:
            return {
                ...state,
                rows: [
                    ...state.rows.slice(0, action.key),
                    ...state.rows.slice(action.key + 1)
                ]
            };
        case SET_CURRENT_ROW:
            return {
                ...state,
                currentRow: action.currentRow
            };
        case SET_LIST_WIDGET_TO_DEFAULT:
            return {
                ...state,
                rows: [{...newRow}],
                currentRow: null
            };
        default:
            return state;
    }
};
export default widgetReducer;

export const setWidgetsTokenAction = (token) => ({type: SET_WIDGET_TOKEN, token});
export const setWidgetsAction = (widgets) => ({type: SET_WIDGETS, widgets});
export const setRowTitle = (key, title) => ({type: SET_ROW_TITLE, key, title});
export const setRows = (rows) => ({type: SET_ROWS, rows});
export const setRowTitleUrl = (key, titleUrl) => ({type: SET_ROW_TITLE_URL, key, titleUrl});
export const setRowButton = (key, btn) => ({type: SET_ROW_BUTTON, key, btn});
export const setRowButtonUrl = (key, btnUrl) => ({type: SET_ROW_BUTTON_URL, key, btnUrl});
export const setRowIconId = (key, id) => ({type: SET_ROW_ICON_ID, key, id});
export const setRowIconUrl = (key, id) => ({type: SET_ROW_ICON_URL, key, id});
export const setRowIconType = (key, iconType) => ({type: SET_ROW_ICON_TYPE, key, iconType});
export const setRowDescr = (key, descr) => ({type: SET_ROW_DESCR, key, descr});
export const setRowAddress = (key, address) => ({type: SET_ROW_ADDRESS, key, address});
export const setRowTime = (key, time) => ({type: SET_ROW_TIME, key, time});
export const setRowText = (key, text) => ({type: SET_ROW_TEXT, key, text});
export const addRowItem = () => ({type: ADD_ROW});
export const updateRows = (from, to) => ({type: UPDATE_ROWS, from, to});
export const deleteRow = (key) => ({type: DELETE_ROW, key});
export const setCurrentRow = (currentRow) => ({type: SET_CURRENT_ROW, currentRow});
export const setListWidgetToDefault = () => ({type: SET_LIST_WIDGET_TO_DEFAULT});

export const saveOnServer = async (dispatch, getState, status = false) => {
    const widgetState = getState().widget;
    const widgetListState = getState().listWidget;
    const appState = getState().app;
    const widgetType = WidgetsService.getWidgetType(widgetState);

    await WidgetsService.saveNONTextWidgetOnServer(widgetState, widgetListState, appState, widgetType, status);
    router.popPage();
    dispatch(showSnackbar({
        success: true,
        text: status ? "Виджет сохранен" : "Виджет опубликован"
    }));
    dispatch(setActiveWidget(widgetState.id));
    dispatch(setGeneralWidgetToDefault());
    dispatch(setListWidgetToDefault());
};

export const publishListWidgetSettings = () => {
    return async (dispatch, getState) => {
        const appState = getState().app;
        const widgetState = getState().widget;
        const widgetListState = getState().listWidget;
        const widgetType = WidgetsService.getWidgetType(getState().widget);

        const widgetsCodeService = new WidgetsCodeService({appState, widgetState, widgetListState});
        const code = await widgetsCodeService.genCode(widgetType);

        if (typeof (code) != 'object') {
            try {
                const response = await widgetsAPI.getVariablesCode(code);
                await showWidgetPreviewBox(response.code, widgetType);
            } catch (e) {
                console.log('publish list widget error', e);
                if (+e.error_data.error_code === 4) return;
                dispatch(showSnackbar({text: 'Произошла ошибка. Проверьте поля виджета и попробуйте ещё раз'}));
                return;
            }
            try {
                await saveOnServer(dispatch, getState, false)
            } catch (e) {
            }
        } else {
            dispatch(showPopout(PopoutErrorWidget(dispatch, code)));
        }
    }
};

// функция сохранения без публикации
export const saveListWidgetSettings = () => {
    return async (dispatch, getState) => {
        const appState = getState().app;
        const widgetState = getState().widget;
        const widgetListState = getState().listWidget;
        const widgetType = WidgetsService.getWidgetType(getState().widget);

        const widgetsCodeService = new WidgetsCodeService({appState, widgetState, widgetListState});
        const code = await widgetsCodeService.genCode(widgetType);
        if (typeof (code) != 'object') {
            try {
                await saveOnServer(dispatch, getState, true)
            } catch (e) {
            }
        } else {
            dispatch(showPopout(PopoutErrorWidget(dispatch, code)));
        }
    }
};

export const setListWidgetData = (data) => {
    return (dispatch) => {
        dispatch(setRows(data.rows));
    }
};

export const widgetsLoad = () => {
    return async (dispatch) => {
        let response = await widgetsAPI.getList();
        if (!response.token) {
            try {
                await WidgetsService.getToken();
            } catch (e) {
                dispatch(showSnackbar({text: 'Для создания виджетов необходим доступ'}))
                router.popPage();
                //dispatch(Router.forward(PANELS.HOME))
                return;
            }

            response = await widgetsAPI.getList();
        }

        if (response.widgets) {
            dispatch(setWidgetsTokenAction(response.token));
            dispatch(setWidgetsAction(response.widgets));
            response.widgets.map((elem) => {
                if (elem.status === 1) {
                    dispatch(setActiveWidget(elem._id.$oid));
                }
                return 0;
            });
        }
    }
};

export const deleteWidgetsFromList = (widgetArray, setEditMode) => {
    return (dispatch, getState) => {
        const activeWidget = getState().widget.activeWidgetId;

        const isContainActiveWidget = () => {
            let contain = false;
            widgetArray.map((elem) => {
                if (elem === activeWidget) {
                    contain = true
                }
                return 0;
            });
            return contain;
        };

        dispatch(showPopout(
            <Alert
                actions={[{
                    title: 'Отмена',
                    autoclose: true,
                    mode: 'cancel'
                }, {
                    title: 'Удалить',
                    mode: 'destructive',
                    autoclose: true,
                    action: async () => {
                        if (isContainActiveWidget()) {
                            try {
                                await showWidgetPreviewBox('', "text");
                            } catch (e) {
                                dispatch(hidePopout());
                                dispatch(setActiveModal(null));
                                return;
                            }
                        }

                        const result = await deleteWidgetFunctionV2(dispatch, getState, widgetArray.join(','));
                        if (result) {
                            const list = await widgetsAPI.getList();
                            dispatch(setWidgetsAction(list.widgets));
                            dispatch(showSnackbar({
                                success: true,
                                text: "Выбранные виджеты успешно удалены"
                            }));
                            setEditMode && setEditMode(false);
                        } else {
                            dispatch(showSnackbar({
                                success: false,
                                text: "Произошла ошибка. Виджеты не удалены"
                            }));
                        }
                    },
                }]}
                onClose={() => {
                    dispatch(hidePopout())
                }}
            >
                <AlertTitle>Подтвердите действие</AlertTitle>
                {isContainActiveWidget() ?
                    <AlertText>Удаление списка виджета приведет к отключению текущего?</AlertText> :
                    <AlertText>Удалить выбранные виджеты?</AlertText>
                }
            </Alert>
        ))
    }
};