import React, {useEffect} from 'react';
import {connect, useSelector} from "react-redux";
import TextWidgetPanelContainer from "../TextWidgetPanel/TextWidgetPanelContainer";
import ListWidgetPanelContainer from "../ListWidgetPanel/ListWidgetPanelContainer";
import {setGeneralWidgetData, setTextWidgetData} from "../../../../redux/parts/widgetReducer";
import {setListWidgetData} from "../../../../redux/parts/widgetListReducer";
import TilesWidgetPanelContainer from "../TilesWidgetPanel/TilesWidgetPanelContainer";
import CompactListWidgetPanelContainer from "../CompactListWidgetPanel/CompactListWidgetPanelContainer";
import CoverListWidgetPanelContainer from "../CoverListWidgetPanel/CoverListWidgetPanelContainer";

const mapComponents = new Map()
    .set('text', TextWidgetPanelContainer)
    .set('list', ListWidgetPanelContainer)
    .set('compact_list', CompactListWidgetPanelContainer)
    .set('tiles', TilesWidgetPanelContainer)
    .set('tiles_square', TilesWidgetPanelContainer)
    .set('cover_list', CoverListWidgetPanelContainer);

const CurrentWidget = (props) => {
    const currentWidget = useSelector(state => state.widget.current);

    let Widget = mapComponents.get(currentWidget.type);
    const switchTypeWidget = () => {
        switch (currentWidget.type) {
            case 'text':
                props.setGeneralWidgetData(currentWidget);
                props.setTextWidgetData(currentWidget);
                break;

            default:
                props.setGeneralWidgetData(currentWidget);
                props.setListWidgetData(currentWidget);
                break;
        }
    };
    useEffect(() => {
        switchTypeWidget();
    }, [currentWidget]);

    return (
        <Widget/>
    )
};

export default connect(null, {
    setGeneralWidgetData,
    setListWidgetData,
    setTextWidgetData
})(CurrentWidget);
