const THConfig = {
    system: {
        url: 'https://smm.targethunter.ru',
        supportUrl: 'https://vk.me/th.help',
        docsUrl: 'https://smm.targethunter.help',
    },
    app: {
        height: 700,
        width: 750
    }
}
export default THConfig;