import React from "react";
import {Icon28ListCheckOutline} from "@vkontakte/icons";
import {SimpleCell} from "@vkontakte/vkui";
import {useRouter} from "@happysanta/router";
import {PAGES} from "../../../../config/routes";

const UserNavigation = props => {
    const router = useRouter();
    return (
        <>
            <SimpleCell
                onClick={() => router.pushPage(PAGES.USER_SUBSCRIPTIONS)}
                expandable
                before={<Icon28ListCheckOutline/>}
            >
                Мои подписки
            </SimpleCell>
        </>
    )
}

export default UserNavigation;