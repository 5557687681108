import React, {useEffect, useState} from 'react';
import stl from './TilesWidgetStyles.module.css';
import s from './../../common/styles/WidgetCommonStyles.module.css';
import Image from "../../common/components/WidgetIcon";
import {useSelector} from "react-redux";
import DeleteButton from "../../common/components/Buttons/DeleteButton";
import WidgetsService from "../../../../common/logic/WidgetsService";
import {unescapeHtml} from "../../../../common/helpers/functions";
import {Spacing} from "@vkontakte/vkui";
import AddNewItemButton from "../../common/components/Buttons/AddNewItemButton";

const TilesWidgetPanel = (props) => {
    const [items, setItems] = useState(props.rows);
    const widgetState = useSelector(state => state.widget);
    const widgetType = WidgetsService.getWidgetType(widgetState);

    useEffect(() => {
        setItems(props.rows);
    }, [props.rows]);

    const canAddNewRow = () => {
        return items.length < 10
    };

    const onClick = (func, key) => {
        return () => {
            props.setCurrentRow(key);
            func();
        }
    };
    const emptyText = () => {
        return <span style={{color: "red"}}>Текст отсутствует</span>
    };

    const rows = items.map((elem, key) => {
        const title = unescapeHtml(elem.title.trim());
        const descr = unescapeHtml(elem.descr.trim());
        const button = unescapeHtml(elem.button.trim());
        return (
            <div className={stl.tilesItem} key={key}>
                {items.length > 3 &&
                <div className={stl.deleteButton}>
                    <DeleteButton styles={{top: 15}} deleteFunc={props.deleteRow(key)}/>
                </div>}
                <Image
                    onClick={onClick(props.openModals.icon, key)}
                    elem={elem}
                    type={widgetType}
                    styleClass={`avatar ${stl.avatar} ${widgetType === 'tiles_square' && stl.avatar_square}`}
                />

                <Spacing size={10}/>
                <div
                    onClick={onClick(props.openModals.title, key)}
                    className={s.textLink}
                    style={{fontWeight: 700}}
                >
                    {title ? title : emptyText()}
                </div>

                <div
                    className={s.textDescr}
                    onClick={onClick(props.openModals.descr, key)}
                >
                    {descr ? descr : emptyText()}
                </div>

                <div
                    onClick={onClick(props.openModals.button, key)}
                    className={s.textLink}
                >
                    {button ? button : emptyText()}
                </div>
            </div>
        )
    });

    return (
        <>
            <div className={stl.list}>
                {rows}
                {
                    canAddNewRow()
                    &&
                    <AddNewItemButton addRowItem={props.addRowItem}/>
                }
            </div>
        </>
    )
};

export default TilesWidgetPanel;
