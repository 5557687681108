import React from 'react';
import {connect, useDispatch, useSelector} from "react-redux";

import {hidePopout, setActiveModal, showPopout, showSnackbar} from "../../../redux/root/rootReducer";
import {
    CURRENT_WIDGET,
    deleteWidget,
    disableWidget,
    PUBLISH,
    publishWidgetSettings,
    saveWidgetSettings,
    SAVING,
    setFooter,
    setLinkTitle,
    setName,
    setTitle,
    setType,
    TEXT_WIDGET
} from "../../../redux/parts/widgetReducer";

import {
    publishListWidgetSettings,
    saveListWidgetSettings,
    setRowButton,
    setRowButtonUrl,
    setRowDescr,
    setRowIconId,
    setRowIconType,
    setRowIconUrl,
    setRowTitle,
    setRowTitleUrl
} from "../../../redux/parts/widgetListReducer";

import ModalContentUploadPhoto from "../common/components/ModalContentUploadImg";

import {Icon24Cancel, Icon24Delete, Icon24View, Icon24Write} from "@vkontakte/icons";

import {
    ActionSheet,
    ActionSheetItem,
    Button,
    FormItem,
    FormLayout,
    Input,
    ModalPage,
    ModalPageHeader,
    ModalRoot,
    PanelHeaderButton,
    ScreenSpinner,
    Text
} from "@vkontakte/vkui";

import ModalContent, {ModalContentDesc} from "../common/components/ModalContent";
import {getSearchObject} from "../../../common/helpers/functions";
import WidgetsService from "../../../common/logic/WidgetsService";
import {getIconById} from "../../../common/helpers/bridge";
import {router} from "../../../router";

const WidgetModalRoot = () => {
    const activeModal = useSelector(state =>
        state.app.activeModal
    );

    const rows = useSelector(state => state.listWidget.rows);
    const title = useSelector(state => state.widget.title);
    const linkTitle = useSelector(state => state.widget.linkTitle);
    const footerText = useSelector(state => state.widget.footerText);
    const footerLink = useSelector(state => state.widget.footerLink);
    const currentRow = useSelector(state => state.listWidget.currentRow);
    const search = getSearchObject();
    const name = useSelector(state => state.widget.name);
    const widgetType = useSelector(state => state.widget.type);
    const widgetState = useSelector(state => state.widget);
    const currentWidget = useSelector(state => state.widget.current);
    const token = useSelector(state => state.listWidget.widgetsToken);
    const savingType = useSelector(state => state.widget.savingType);
    const dispatch = useDispatch();
    const isTextWidget = () => widgetType === TEXT_WIDGET;

    const onSaveFooter = (text, link) => {
        if ((text.length === 0 && link.length === 0) || (text.length !== 0 && link.length !== 0)) {
            dispatch(setFooter(text, link));
            dispatch(setActiveModal(null));
        } else {
            console.log(`${text} + ${link}`)
        }
    };
    const onSaveTitle = (text, link) => {
        if (text.length !== 0) {
            dispatch(setTitle(text.trim()));
            dispatch(setLinkTitle(link));
            dispatch(setActiveModal(null));
        }
    };
    const onSaveName = () => {
        if (!name) return;
        const forCheckName = name.trim();
        if (forCheckName.length < 1) return;

        dispatch(setActiveModal(null));
        switch (savingType) {
            case PUBLISH:
                if (widgetType === CURRENT_WIDGET) {
                    let type = currentWidget.type;
                    if (type === 'text') {
                        dispatch(publishWidgetSettings());
                    } else {
                        dispatch(publishListWidgetSettings());
                    }
                } else if (isTextWidget()) {
                    dispatch(publishWidgetSettings());
                } else if (!isTextWidget()) {
                    dispatch(publishListWidgetSettings());
                }
                return;
            case SAVING:
                dispatch(setActiveModal(null));
                if (widgetType === CURRENT_WIDGET) {
                    let type = currentWidget.type;
                    if (type === 'text') {
                        dispatch(saveWidgetSettings());
                    } else {
                        dispatch(saveListWidgetSettings());
                    }
                } else if (isTextWidget()) {
                    dispatch(saveWidgetSettings());
                } else if (!isTextWidget()) {
                    dispatch(saveListWidgetSettings());
                }
                return;
        }
    };
    const onSaveRowTitle = (text, link) => {
        if (text.length !== 0) {
            dispatch(setRowTitle(currentRow, text.trim()));
            dispatch(setRowTitleUrl(currentRow, link));
            dispatch(setActiveModal(null));
        }
    };
    const onSaveRowButton = (text, link) => {
        if ((text.length === 0 && link.length === 0) || (text.length !== 0 && link.length !== 0)) {
            dispatch(setRowButton(currentRow, text.trim()));
            dispatch(setRowButtonUrl(currentRow, link));
            dispatch(setActiveModal(null));
        }
    };
    const onSaveRowDescr = (text) => {
        if (text.length !== 0) {
            dispatch(setRowDescr(currentRow, text.trim()));
            dispatch(setActiveModal(null));
        }
    };
    const onSaveRowImgById = async (type, value = null) => {
        dispatch(showPopout(<ScreenSpinner/>));
        const widgetType = WidgetsService.getWidgetType(widgetState);
        let response;
        const setData = (response) => {
            dispatch(setRowIconId(currentRow, response.icon_id));
            (widgetType === 'tiles' || widgetType === 'tiles_square') ?
                dispatch(setRowIconUrl(currentRow, response.icon_url_tiles)) :
                dispatch(setRowIconUrl(currentRow, response.icon_url_list));
            dispatch(setActiveModal(null));
            dispatch(hidePopout());
        };
        dispatch(setRowIconType(currentRow, type));

        try {
            switch (type) {
                case "user_avatar":
                    response = await getIconById("user_avatar", search.vk_user_id, token);
                    setData(response);
                    return;

                case "some_user_avatar":
                    if (!value) {
                        dispatch(hidePopout());
                        return;
                    }
                    response = await getIconById("some_user_avatar", value, token);
                    setData(response);
                    return;

                case "group_avatar":
                    response = await getIconById("group_avatar", search.vk_group_id, token);
                    setData(response);
                    return;

                case "some_group_avatar":
                    response = await getIconById("some_group_avatar", value, token);
                    setData(response);
                    return;
                default:
                    return 0;
            }
        } catch (e) {
            dispatch(showSnackbar({text: 'Не удалось выполнить действие. Обновите страницу и попробуйте ещё раз'}))
        }
    };
    const close = () => {
        dispatch(setActiveModal(null));
    };

    return <ModalRoot onClose={close} activeModal={activeModal}>
        <ModalPage
            id="TEXT_name"
            dynamicContentHeight
            onClose={close}
            header={
                <ModalPageHeader
                    right={<PanelHeaderButton onClick={close}>{'Отмена'}</PanelHeaderButton>}
                    children={"Название"}
                />
            }
        >
            <FormLayout>
                <FormItem
                    top={"Введите название виджета"}
                    bottom={"Не более 60 символов"}
                >
                    <Input
                        value={name ? name : ""}
                        maxLength={60}
                        onChange={(e) => {
                            dispatch(setName(e.target.value))
                        }}
                        placeholder={"Название"}
                        required={true}
                    />
                </FormItem>
                <FormItem>
                    <Button size="l" stretched onClick={onSaveName}>
                        <Text weight={"regular"}>Сохранить</Text>
                    </Button>
                </FormItem>
            </FormLayout>
        </ModalPage>

        <ModalPage
            id="TEXT_title"
            dynamicContentHeight
            onClose={close}
            header={
                <ModalPageHeader
                    right={<PanelHeaderButton onClick={close}>{'Отмена'}</PanelHeaderButton>}
                    children={"Редактирование"}
                />
            }
        >
            <ModalContent
                nameLabel="Заголовок"
                limit={100}
                type={'input'}
                name={title}
                link={linkTitle}
                onSave={onSaveTitle}
                required={true}
            />
        </ModalPage>

        <ModalPage
            id="TEXT_footer"
            dynamicContentHeight
            onClose={close}
            header={
                <ModalPageHeader
                    right={<PanelHeaderButton onClick={close}>{'Отмена'}</PanelHeaderButton>}
                    children={"Редактирование"}
                />
            }
        >
            <ModalContent
                nameLabel="Футер"
                name={footerText}
                type={'input'}
                limit={100}
                link={footerLink}
                onSave={onSaveFooter}
            />
        </ModalPage>

        <ModalPage
            id="ROW_title"
            dynamicContentHeight
            onClose={close}
            header={
                <ModalPageHeader
                    right={<PanelHeaderButton onClick={close}>{'Отмена'}</PanelHeaderButton>}
                >
                    Редактирование
                </ModalPageHeader>
            }
        >
            <ModalContent
                limit={100}
                type={'input'}
                required={true}
                nameLabel="Заголовок элемента"
                name={rows[currentRow] ? rows[currentRow].title : ""}
                link={rows[currentRow] ? rows[currentRow].title_url : ""}
                onSave={onSaveRowTitle}
            />
        </ModalPage>

        <ModalPage
            id="ROW_button"
            dynamicContentHeight
            onClose={close}
            header={
                <ModalPageHeader
                    right={<PanelHeaderButton onClick={close}>{'Отмена'}</PanelHeaderButton>}
                >
                    Редактирование
                </ModalPageHeader>
            }
        >
            <ModalContent
                type={'input'}
                limit={50}
                nameLabel="Текст кнопки"
                name={rows[currentRow] ? rows[currentRow].button : ""}
                link={rows[currentRow] ? rows[currentRow].button_url : ""}
                onSave={onSaveRowButton}
            />
        </ModalPage>

        <ModalPage
            id="ROW_descr"
            dynamicContentHeight
            onClose={close}
            header={
                <ModalPageHeader
                    right={<PanelHeaderButton onClick={close}>{'Отмена'}</PanelHeaderButton>}
                >
                    Редактирование описания
                </ModalPageHeader>
            }
        >
            <ModalContentDesc
                limit={50}
                type={'input'}
                nameLabel="Текст описания"
                name={rows[currentRow] ? rows[currentRow].descr : ""}
                onSave={onSaveRowDescr}
            />
        </ModalPage>

        <ModalPage
            id="ROW_img"
            dynamicContentHeight
            onClose={close}
            header={
                <ModalPageHeader
                    right={<PanelHeaderButton onClick={close}>{'Отмена'}</PanelHeaderButton>}
                >
                    Добавление фото
                </ModalPageHeader>
            }
        >
            <ModalContentUploadPhoto
                onSave={onSaveRowImgById}
            />
        </ModalPage>
    </ModalRoot>
};
export default WidgetModalRoot;


const MoreWidgetSettingComponent = (props) => {
    const dispatch = useDispatch();
    const widgetId = useSelector(state => state.widget.activeWidgetId);

    const widgetType = useSelector(state => state.widget.type);
    const isTextWidget = () => widgetType === 'text';
    const editWidget = () => {
        router.popPage();
        //dispatch(Router.forward(PANELS.WIDGET));
        dispatch(setType(CURRENT_WIDGET));
        dispatch(setActiveModal(null))
    };
    const deleteWidgetFunc = () => {
        dispatch(showPopout(<ScreenSpinner/>));
        props.deleteWidget();
    };
    const enableWidget = () => {
        dispatch(showPopout(<ScreenSpinner/>));
        dispatch(setActiveModal(null));

        if (isTextWidget()) props.saveWidgetSettings();
        else props.saveListWidgetSettings();
    };
    const disableWidget = () => {
        props.disableWidget();
    };

    const close = () => {
        dispatch(hidePopout());
    };

    return <>
        <ActionSheet onClose={close}>
            <ActionSheetItem
                onClick={editWidget}
                autoclose
                before={<Icon24Write/>}
            >
                Редактировать
            </ActionSheetItem>

            {widgetId === props.id ?
                <ActionSheetItem
                    onClick={disableWidget}
                    autoclose
                    before={<Icon24Cancel/>}
                    mode="destructive"
                >
                    Отключить
                </ActionSheetItem> :
                <ActionSheetItem
                    onClick={enableWidget}
                    autoclose
                    before={<Icon24View/>}
                >
                    Включить
                </ActionSheetItem>
            }
            <ActionSheetItem
                onClick={deleteWidgetFunc}
                autoclose
                before={<Icon24Delete/>}
                mode="destructive"
            >
                Удалить
            </ActionSheetItem>

            <ActionSheetItem
                autoclose
                mode="cancel"
            >
                Отменить
            </ActionSheetItem>
        </ActionSheet>
    </>

};

export const MoreWidgetSetting = connect(null, {
    deleteWidget,
    saveListWidgetSettings: publishListWidgetSettings,
    saveWidgetSettings: publishWidgetSettings,
    disableWidget
})(MoreWidgetSettingComponent);
