import React from "react";
import {Alert, Spacing} from "@vkontakte/vkui";
import {compose} from "redux";
import PropTypes from "prop-types";
import {Icon56CancelCircleOutline, Icon56CheckCircleOutline} from "@vkontakte/icons";
import {AlertIcon, AlertText, AlertTitle} from "../../../../common/components/Alerts/Components";
import withIOSPlatform from "../../../../common/hoc/withIOSPlatform";

const SubscriptionAlert = props => {
    let {title, message = '', icon = 'green', okText = 'Продолжить', onClose = null, redirectUrl = null} = props;

    let actions = [{
        title: okText,
        autoclose: true,
        mode: 'cancel'
    }];

    if (redirectUrl) {
        actions = [{
            title: okText,
            href: redirectUrl,
            target: '_top'
        }];
    }

    const getIcon = () => {
        switch (icon) {
            case "red":
                return <Icon56CancelCircleOutline fill={"red"} style={{textAlign: 'center'}}/>;
            case "green":
                return <Icon56CheckCircleOutline fill={"green"} style={{textAlign: 'center'}}/>;
            default:
                return null;
        }
    }

    const _icon = getIcon();

    return (
        <Alert
            actions={actions}
            actionsLayout="vertical"
            onClose={onClose}
        >
            {_icon && <AlertIcon>{_icon}</AlertIcon>}
            <AlertTitle>{title}</AlertTitle>
            <Spacing size={12}/>
            <AlertText>{message}</AlertText>
        </Alert>
    )
}

SubscriptionAlert.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    okText: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    redirectUrl: PropTypes.string,
    icon: PropTypes.oneOf(['green', 'red', null]),
}

export default compose(
    withIOSPlatform
)(SubscriptionAlert);