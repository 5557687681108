import React from 'react';
import s from "../../styles/WidgetCommonStyles.module.css";

const FlatButton = props => {
    return (
        <a
            {...props}
            className={s.FlatButton}>
            <span className={s.FlatButton__in}>
                <span className={s.FlatButton__content}>
                    {props.children}
                </span>
            </span>
        </a>
    );
};

FlatButton.propTypes = {

};

export default FlatButton;
