import {PANELS, VIEWS} from "../../config/routes";
import {getHashObject, getSearchObject} from "../../common/helpers/functions";

const hashObject = getHashObject();
const searchObject = getSearchObject();

const SET_HASH_OBJECT = 'SET_HASH_OBJECT';
const OPEN_PANEL = 'OPEN_PANEL';
const OPEN_VIEW = 'OPEN_VIEW';
const SET_PANEL_HEADER_TITLE = 'SET_PANEL_HEADER_TITLE';
const SET_HISTORY = 'SET_HISTORY';
const SET_IS_ANIMATE = 'SET_IS_ANIMATE';

const initialState = {
    hashObject,
    searchObject,
    activePanel: PANELS.LOADING,
    activeView: VIEWS.MAIN,
    panelHeaderTitle: 'Главная',
    history: [],
    isAnimate: true,
}

const routerReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_HASH_OBJECT:
            return {
                ...state,
                hashObject: action.hashObject
            }
        case OPEN_PANEL:
            return {
                ...state,
                activePanel: action.to,
                ...action.props
            };
        case OPEN_VIEW:
            const newState = {
                ...state,
                activePanel: action.panel,
                ...action.props
            };

            if (state.activeView !== action.view) {
                newState.activeView = action.view;
            }
            return newState;
        case SET_IS_ANIMATE:
            if (state.isAnimate === action.flag) return state;

            return {
                ...state,
                isAnimate: action.flag
            }
        case SET_PANEL_HEADER_TITLE:
            return {
                ...state,
                panelHeaderTitle: action.panelHeaderTitle
            }
        case SET_HISTORY:
            return {
                ...state,
                history: action.history
            }
        default:
            return state;
    }
}

export default routerReducer;

export const setHashObject = (hashObject) => ({type: SET_HASH_OBJECT, hashObject});
export const setIsAnimate = (flag) => ({type: SET_IS_ANIMATE, flag});
export const changeView = ({panel, view}, props = {}) => ({type: OPEN_VIEW, panel, view, props});
export const setPanelHeaderTitle = (panelHeaderTitle) => ({type: SET_PANEL_HEADER_TITLE, panelHeaderTitle});
export const setHistory = (history) => ({type: SET_HISTORY, history});