import React, {useEffect} from 'react';
import s from '../../common/styles/Subscription.module.css';

import {Div, Spinner, Title} from '@vkontakte/vkui';
import {unescapeHtml} from "../../../../common/helpers/functions";
import * as PropTypes from "prop-types";
import SubscriptionActionButton from "../../common/components/SubscriptionActionButton";
import UserStack from "../../../../common/components/UserStack/UserStack";
import THPanelHeader from "../../../../common/components/THPanelHeader";
import SubscriptionBanner from "../../common/components/SubscriptionBanner";

const Subscription = props => {
    const {item} = props;

    if (!item) {
        return <Spinner/>;
    }

    const onSub = async () => {
        await props.onSub(item);
    }

    let banner = item && item.banner &&
        <SubscriptionBanner item={item} onLoad={props.onLoadBanner}/>;

    let header = props.showHeader &&
        <THPanelHeader hasBackBtn={props.hasBackBtn} title={props.pageTitle} separator={!banner}/>;

    let description = item && item.description &&
        <Div className={s.descriptionWrapper} style={{textAlign: item.description_align ?? 'left'}}>
            <Div style={{paddingTop: 0}} className={s.description}
                 dangerouslySetInnerHTML={{__html: item.description}}/>
        </Div>;

    useEffect(() => {
        setTimeout(() => {
            props.resizeWindow();
        }, 500);
    }, []);

    return (
        <>
            {header}
            {banner}
            <Div className={s.wrapper}>
                <Div>
                    <Title className={"text-center"} level={2}>{unescapeHtml(item.title || item.name)}</Title>
                </Div>
                {description}
                {!item.hide_count && <UserStack item={item}/>}
                <div style={{textAlign: "center"}}>
                    <SubscriptionActionButton
                        item={item}
                        onClick={onSub}
                        text={item.isSubscribed ? item.unsubscribe_text : item.subscribe_text}
                    />
                </div>
            </Div>
        </>
    );
}

Subscription.propTypes = {
    pageTitle: PropTypes.string,
    item: PropTypes.object,
    showHeader: PropTypes.bool.isRequired,
    hasBackBtn: PropTypes.bool.isRequired,

    onSub: PropTypes.func.isRequired, resizeWindow:
    PropTypes.func.isRequired,
};

export default Subscription;
