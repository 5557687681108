import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import s from './FieldWithSmiles.module.css';
import {Input, Textarea} from "@vkontakte/vkui";
import Icon24SmileOutline from "@vkontakte/icons/dist/24/smile_outline";
import {escapeHtml, getStrWithCode, replaceSmilesToCode, unescapeHtml} from "../../../../../common/helpers/functions";
import "../../../../../assets/img/smiles/sprite.css"
import "../../../../../assets/img/smiles/sprite2.css"

const useOutsideClick = (ref1, callback) => {
    const handleClick = e => {
        if (ref1.current && !ref1.current.contains(e.target)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);
    });
};

const FieldWithSmiles = (props) => {
    const inputRef = useRef(null);
    const refContainer = useRef(null);
    const [strWithCode, setStrWithCode] = useState(props.text);
    const [rangeData, setRangeData] = useState(null);
    const [value, setValue] = useState(props.text);
    const emojiArray = useSelector(state => state.app.emoji);
    const activeModal = useSelector(state => state.app.activeModal);
    const popout = useSelector(state => state.app.popout);
    const [focus, setFocus] = useState(false);


    useEffect(() => {
        let strWithoutEmoji = replaceSmilesToCode(emojiArray, strWithCode);
        setStrWithCode(getStrWithCode(strWithoutEmoji));
    }, []);


    useOutsideClick(refContainer, () => {
        let newValue = inputRef.current.value;
        props.setText(escapeHtml(newValue));
        props.onBlurFunc && props.onBlurFunc();
    });


    const onChangeHandler = (event) => {
        let newStr = replaceSmilesToCode(emojiArray, event.target.value);
        if (newStr.length <= props.limit) {
            (popout || activeModal) && props.setText(escapeHtml(event.target.value));
            setValue(event.target.value);
            setStrWithCode(newStr);
            props.updateModalHeight && props.updateModalHeight();
        }
    };

    return (
        <div
            className={s.container}
            ref={refContainer}
        >
            {props.type === 'textarea' ?
                <Textarea
                    required={props.required}
                    getRef={inputRef}
                    onClick={() => {
                        setFocus(true)
                    }}
                    onBlur={() => {
                        setFocus(false)
                    }}
                    value={unescapeHtml(value)}
                    onChange={onChangeHandler}
                    className={`${s.container__input} container__textarea`}
                /> :
                <Input
                    required={props.required}
                    getRef={inputRef}
                    onClick={() => {
                        setFocus(true)
                    }}
                    onBlur={() => {
                        setFocus(false)
                    }}
                    value={unescapeHtml(value)}
                    onChange={onChangeHandler}
                    className={s.container__input}
                />
            }
            <div
                className={activeModal ? s.container__limitModal : s.container__limitBlock}
            >
                <span>{unescapeHtml(strWithCode).length} / {props.limit}</span>
            </div>

            <Emoji
                inputRef={inputRef}
                limit={props.limit}
                rangeData={rangeData}
                setRangeData={setRangeData}
                emojiArray={emojiArray}
                focus={focus}
                setText={props.setText}
                setValue={setValue}
                strWithCode={strWithCode}
                setStrWithCode={setStrWithCode}
            />

            <div
                className={s.container__buttons}
            >
                <Buttons
                    inputRef={inputRef}
                    limit={props.limit}
                    rangeData={rangeData}
                    setRangeData={setRangeData}
                    emojiArray={emojiArray}
                    focus={focus}
                    setText={props.setText}
                    setValue={setValue}
                    strWithCode={strWithCode}
                    setStrWithCode={setStrWithCode}
                    type={props.type}
                />
            </div>
        </div>
    );

};
export default FieldWithSmiles;

const Buttons = (props) => {
    const activeModal = useSelector(state => state.app.activeModal);
    const popout = useSelector(state => state.app.popout);

    const buttonsArray = {
        first_name: {
            title: "Имя",
            text: `%first_name%`
        },
        first_last_name: {
            title: "Фамилия",
            text: `%last_name%`
        },
        city: {
            title: "Город",
            text: `[city]{city}|Ваш город[/city]`
        },
        country: {
            title: "Страна",
            text: `[country]{country}|Ваша страна[/country]`
        },
        relation: {
            title: "Семейное положение",
            text: `[relation]{relation}|нет[/relation]`
        },
        gender: {
            title: "Мужчинам/Женщинам",
            text: `[gender]М|Ж[/gender]`
        },
    };

    const buttonsKeys = Object.keys(buttonsArray);

    const onMouseOverBtn = () => {
        if (props.focus) {
            const selection = window.getSelection();
            +selection.rangeCount && props.setRangeData(selection.getRangeAt(0));
        }
    };

    const addButtonContent = (text) => {
        const range = props.rangeData;
        const emojiArray = props.emojiArray;
        let strWithCode = props.strWithCode;

        if (strWithCode.length + text.length >= props.limit) {
            return 0;
        }

        if (!!range) {
            const start = props.inputRef.current.selectionStart;
            const end = props.inputRef.current.selectionEnd;
            const finText = props.inputRef.current.value.substring(0, start)
                + text +
                props.inputRef.current.value.substring(end);
            props.inputRef.current.focus();

            let pos = (start === end) ? (end + text.length) : end;
            setTimeout(() => props.inputRef.current.setSelectionRange(pos, pos), 10);

            (activeModal || popout) && props.setText(finText);
            props.setValue(finText);
            let x = replaceSmilesToCode(emojiArray, finText);
            props.setStrWithCode(x);
        } else {
            const finText = props.inputRef.current.value + text;
            props.inputRef.current.focus();
            (activeModal || popout) && props.setText(finText);
            props.setValue(finText);
            let x = replaceSmilesToCode(emojiArray, finText);
            props.setStrWithCode(x);
        }
    };

    const buttons = buttonsKeys.map((elem, index) => {
        return (
            <div
                key={index}
                onClick={() => {
                    addButtonContent(buttonsArray[elem].text)
                }}
                onMouseOver={onMouseOverBtn}
                className={s.lineBtns_childBtn}
            >
                <span style={{fontWeight: 400}}>{buttonsArray[elem].title}</span>
            </div>
        )
    });

    return (
        <div className={s.lineBtns__childBtnContainer}>
            {buttons}
        </div>
    )
};

const Emoji = (props) => {
    const [showEmoji, setShowEmoji] = useState(false);
    const [images, setImages] = useState([]);
    const activeModal = useSelector(state => state.app.activeModal);
    const popout = useSelector(state => state.app.popout);

    const emojiArray = props.emojiArray;
    let strWithCode = props.strWithCode;

    const addEmoji = (event) => {
        let range = props.rangeData;

        let code = event.currentTarget.dataset.code;

        if (strWithCode.length + code.length >= props.limit) {
            return 0;
        } else {
            if (!!range) {
                event.preventDefault();
                const start = props.inputRef.current.selectionStart;
                const end = props.inputRef.current.selectionEnd;
                const finText = props.inputRef.current.value.substring(0, start)
                    + event.currentTarget.dataset.alt +
                    props.inputRef.current.value.substring(end);
                props.inputRef.current.focus();

                let pos = (start === end) ? (end + 2) : end;
                setTimeout(() => props.inputRef.current.setSelectionRange(pos, pos), 10);

                (activeModal || popout) && props.setText(finText);
                props.setValue(finText);
                let x = replaceSmilesToCode(emojiArray, finText);
                props.setStrWithCode(x);
                strWithCode = x;
            } else {
                const finText = props.inputRef.current.value + event.currentTarget.dataset.alt;
                props.inputRef.current.focus();
                (activeModal || popout) && props.setText(finText);
                props.setValue(finText);
                let x = replaceSmilesToCode(emojiArray, finText);
                props.setStrWithCode(x);
                strWithCode = x;
            }
        }
    };

    useEffect(() => {
        const fetchData = async () => {

            let imagesTemp = emojiArray.map((elem) =>
                <div
                    ref={props.divSmiles}
                    className={s.emoji__bg}
                    key={elem.filename}
                >
                    <i
                        className={`icon icon-${elem.filename}`}
                        data-filename={elem.filename}
                        data-code={elem.code}
                        data-alt={elem.alt}
                        onClick={addEmoji}
                    />
                </div>);

            setImages(imagesTemp);
        };
        fetchData();
    }, [props.rangeData]);

    const onMouseLeaveEmoji = () => {
        setShowEmoji(false);
    };

    const onMouseOver = () => {
        if (props.focus) {
            const selection = window.getSelection();
            +selection.rangeCount && props.setRangeData(selection.getRangeAt(0));
        }
        !showEmoji && setShowEmoji(true)
    };

    return <>
        <Icon24SmileOutline
            width={24}
            height={24}
            onMouseOver={onMouseOver}
            className={activeModal ? s.container__emojiModal : s.container__emojiBlock}
        />
        {showEmoji && <div
            className={s.emoji__list}
            onMouseLeave={onMouseLeaveEmoji}
        >
            {images}
        </div>}
    </>
};
