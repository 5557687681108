import React from "react";
import {Card, Div, Text, Title} from "@vkontakte/vkui";

const HelloCard = ({header, text, icon}) => {
    return (
        <Card
            mode={"shadow"}
            style={{minHeight: "130px"}}
        >
            <Div style={{paddingBottom: 0, color: 'var(--accent)'}}>
                {icon}
            </Div>
            <Div style={{paddingBottom: '4px'}}>
                <Title level={3}>{header}</Title>
            </Div>
            <Div style={{paddingTop: 0}}>
                <Text weight={"regular"} className={"text-muted"}>{text}</Text>
            </Div>
        </Card>
    )
}

export default HelloCard;