import React from 'react';
import s from './DeleteButton.module.css'

const DeleteButton = ({deleteFunc, styles}) => {
    return <div
        onClick={(e) => {
            deleteFunc(e)
        }}
        className={s.container}
        style={{...styles}}
    >
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="25" height="25" rx="3" fill="#212427"/>
            <path
                d="M11.25 16.25C11.4158 16.25 11.5747 16.1842 11.6919 16.0669C11.8092 15.9497 11.875 15.7908 11.875 15.625V11.875C11.875 11.7092 11.8092 11.5503 11.6919 11.4331C11.5747 11.3158 11.4158 11.25 11.25 11.25C11.0842 11.25 10.9253 11.3158 10.8081 11.4331C10.6908 11.5503 10.625 11.7092 10.625 11.875V15.625C10.625 15.7908 10.6908 15.9497 10.8081 16.0669C10.9253 16.1842 11.0842 16.25 11.25 16.25ZM17.5 8.75H15V8.125C15 7.62772 14.8025 7.15081 14.4508 6.79917C14.0992 6.44754 13.6223 6.25 13.125 6.25H11.875C11.3777 6.25 10.9008 6.44754 10.5492 6.79917C10.1975 7.15081 10 7.62772 10 8.125V8.75H7.5C7.33424 8.75 7.17527 8.81585 7.05806 8.93306C6.94085 9.05027 6.875 9.20924 6.875 9.375C6.875 9.54076 6.94085 9.69973 7.05806 9.81694C7.17527 9.93415 7.33424 10 7.5 10H8.125V16.875C8.125 17.3723 8.32254 17.8492 8.67417 18.2008C9.02581 18.5525 9.50272 18.75 10 18.75H15C15.4973 18.75 15.9742 18.5525 16.3258 18.2008C16.6775 17.8492 16.875 17.3723 16.875 16.875V10H17.5C17.6658 10 17.8247 9.93415 17.9419 9.81694C18.0592 9.69973 18.125 9.54076 18.125 9.375C18.125 9.20924 18.0592 9.05027 17.9419 8.93306C17.8247 8.81585 17.6658 8.75 17.5 8.75ZM11.25 8.125C11.25 7.95924 11.3158 7.80027 11.4331 7.68306C11.5503 7.56585 11.7092 7.5 11.875 7.5H13.125C13.2908 7.5 13.4497 7.56585 13.5669 7.68306C13.6842 7.80027 13.75 7.95924 13.75 8.125V8.75H11.25V8.125ZM15.625 16.875C15.625 17.0408 15.5592 17.1997 15.4419 17.3169C15.3247 17.4342 15.1658 17.5 15 17.5H10C9.83424 17.5 9.67527 17.4342 9.55806 17.3169C9.44085 17.1997 9.375 17.0408 9.375 16.875V10H15.625V16.875ZM13.75 16.25C13.9158 16.25 14.0747 16.1842 14.1919 16.0669C14.3092 15.9497 14.375 15.7908 14.375 15.625V11.875C14.375 11.7092 14.3092 11.5503 14.1919 11.4331C14.0747 11.3158 13.9158 11.25 13.75 11.25C13.5842 11.25 13.4253 11.3158 13.3081 11.4331C13.1908 11.5503 13.125 11.7092 13.125 11.875V15.625C13.125 15.7908 13.1908 15.9497 13.3081 16.0669C13.4253 16.1842 13.5842 16.25 13.75 16.25Z"
                fill="white"/>
        </svg>
    </div>
};
export default DeleteButton;
