import React from 'react';
import {useDispatch} from "react-redux";
import {
    COMPACT_LIST_WIDGET,
    COVER_LIST_WIDGET,
    LIST_WIDGET,
    setType,
    TEXT_WIDGET,
    TILES_SQUARE_WIDGET,
    TILES_WIDGET
} from "../../../redux/parts/widgetReducer";
import {Card, Cell, Group, Header, List, Panel} from "@vkontakte/vkui";
import s from './widgetStyles.module.css';
import {addRowItem, setListWidgetToDefault} from "../../../redux/parts/widgetListReducer";

import list_preview from '../../../assets/img/widgetPreview/list.png'
import compact_list_preview from '../../../assets/img/widgetPreview/compact_list.png'
import cover_list_preview from '../../../assets/img/widgetPreview/cover_list.png'
import text_preview from '../../../assets/img/widgetPreview/text.png'
import tiles_preview from '../../../assets/img/widgetPreview/tiles.png'
import tiles_square_preview from '../../../assets/img/widgetPreview/tiles_square.png'
import {PAGES} from "../../../config/routes";
import THPanelHeader from "../../../common/components/THPanelHeader";
import {useRouter} from "@happysanta/router";

const ListChooseTypeWidget = (props) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const setPanel = (type) => {
        router.pushPage(PAGES.WIDGET);
        dispatch(setType(type));
    };

    const setTiles = () => {
        dispatch(addRowItem());
        dispatch(addRowItem());
    };

    const setDefault = () => {
        dispatch(setListWidgetToDefault());
    };

    return (
        <Panel id={props.id}>
            <THPanelHeader/>
            <Group header={<Header mode="secondary">Сообщения</Header>}>
                <List>
                    <Cell
                        onClick={() => {
                            setPanel(TEXT_WIDGET);
                            setDefault();
                        }}
                        className={"List__Item"}
                        before={
                            <Card style={{marginRight: "15px"}} size="m">
                                <div className={s.cardImageContainer}><img
                                    src={text_preview}
                                    alt=""/></div>
                            </Card>
                        }
                        size="l"
                        description="Опубликуйте важное сообщение с крупным текстом"
                    >
                        Важное сообщение
                    </Cell>
                </List>
            </Group>
            <Group header={<Header mode="secondary">Список</Header>}>
                <List>
                    <Cell
                        onClick={() => {
                            setPanel(LIST_WIDGET);
                            setDefault();
                        }}
                        className={"List__Item"}
                        before={
                            <Card style={{marginRight: "15px"}} size="m">
                                <div className={s.cardImageContainer}><img
                                    src={list_preview}
                                    alt=""/></div>
                            </Card>
                        }
                        size="l"
                        description="Список с произвольными полями и кнопками для действий"
                    >
                        Список
                    </Cell>
                    <br/>
                    <Cell
                        onClick={() => {
                            setPanel(COMPACT_LIST_WIDGET);
                            setDefault();
                        }}
                        className={"List__Item"}
                        before={
                            <Card style={{marginRight: "15px"}} size="m">
                                <div className={s.cardImageContainer}><img
                                    src={compact_list_preview}
                                    alt=""/></div>
                            </Card>
                        }
                        size="l"
                        description={`Выводит список элементов в компактном виде. 
                        Аналогичен виджету "Список", за исключением того, 
                        что кнопка располагается справа`}
                        multiline
                    >
                        Компактный список
                    </Cell>
                </List>
            </Group>
            <Group header={<Header mode="secondary">Плитки</Header>}>
                <List>
                    <Cell
                        onClick={() => {
                            setPanel(TILES_WIDGET);
                            setDefault();
                            setTiles();
                        }}
                        className={"List__Item"}
                        before={
                            <Card style={{marginRight: "15px"}} size="m">
                                <div className={s.cardImageContainer}><img
                                    src={tiles_preview}
                                    alt=""/></div>
                            </Card>
                        }
                        size="l"
                        description="Поможет привлечь внимание к акциям или товарам и получить больше продаж"
                    >
                        Плитка с прямоугольными изображениями
                    </Cell>
                    <br/>
                    <Cell
                        onClick={() => {
                            setPanel(TILES_SQUARE_WIDGET);
                            setDefault();
                            setTiles();
                        }}
                        className={"List__Item"}
                        before={
                            <Card style={{marginRight: "15px"}} size="m">
                                <div className={s.cardImageContainer}><img
                                    src={tiles_square_preview}
                                    alt=""/></div>
                            </Card>
                        }
                        size="l"
                        description="Второй товарный блок для более активной продажи"
                    >
                        Плитка с квадратными изображениями
                    </Cell>
                </List>
            </Group>
            <Group header={<Header mode="secondary">Обложки</Header>}>
                <List>
                    <Cell
                        onClick={() => {
                            setPanel(COVER_LIST_WIDGET);
                            setDefault();
                        }}
                        className={"List__Item"}
                        before={
                            <Card style={{marginRight: "15px"}} size="m">
                                <div className={s.cardImageContainer}>
                                    <img
                                        src={cover_list_preview}
                                        alt=""/>
                                </div>
                            </Card>
                        }
                        size="l"
                        description="До 3 горизонтальных баннеров с кнопками"
                    >
                        Обложки с акцией
                    </Cell>
                </List>
            </Group>
        </Panel>
    )
};

export default ListChooseTypeWidget;
