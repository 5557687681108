import React from 'react'
import {useDispatch} from "react-redux";
import {setActiveModal} from "../../redux/root/rootReducer";

const withOpenModals = (Component) => props => {
    const dispatch = useDispatch();
    const openModals = {
        name: () => {
            dispatch(setActiveModal("TEXT_name"));
        },
        mainTitle: () => {
            dispatch(setActiveModal("TEXT_title"));
        },
        footer: () => {
            dispatch(setActiveModal("TEXT_footer"));
        },
        title: () => {
            dispatch(setActiveModal("ROW_title"));
        },
        descr: () => {
            dispatch(setActiveModal("ROW_descr"));
        },
        button: () => {
            dispatch(setActiveModal("ROW_button"));
        },
        icon: () => {
            dispatch(setActiveModal("ROW_img"));
        }
    };

    return <Component openModals={openModals} {...props}/>
}

export default withOpenModals;