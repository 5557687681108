import {requestAjaxPost} from "../../common/helpers/requests";

export const subscriptionsAPI = {
    method: (method) => 'miniApp.subscriptions.' + method,
    async getList(params) {
        try {
            const res = await requestAjaxPost(this.method('get'), params, false);
            return res.subscriptions ?? [];
        } catch (e) {
            return [];
        }
    },

    async getSubscription(subId, rest = {}) {
        return await requestAjaxPost(this.method('get'), {sub_id: subId, ...rest});
    },

    async toggleSubscribe(data) {
        return await requestAjaxPost(this.method('toggleSubscribe'), data, false);
    },

    async unsubscribeAll() {
        return await requestAjaxPost(this.method('unsubscribeAll'));
    },

    async saveSubscription(data) {
        return await requestAjaxPost(this.method('save'), data);
    },

    async deleteSubscription(id) {
        return await requestAjaxPost(this.method('delete'), {id});
    },
};