import React from 'react';
import CompactListWidgetPanel from "./CompactListWidgetPanel";
import {connect, useDispatch} from "react-redux";
import {setLinkTitle, setTitle, setTitleCount} from "../../../../redux/parts/widgetReducer";
import {setActiveModal} from "../../../../redux/root/rootReducer";
import {
    addRowItem,
    deleteRow,
    publishListWidgetSettings,
    setCurrentRow,
    setRowAddress,
    setRowDescr,
    setRowIconId,
    setRowText,
    setRowTime,
    setRowTitle,
    setRowTitleUrl,
    updateRows
} from "../../../../redux/parts/widgetListReducer";
import withOpenModals from "../../../../common/hoc/withOpenModals";

const CompactListWidgetPanelContainer = (props) => {
    const dispatch = useDispatch();
    const setCurrentRowFunc = (key) => {
        dispatch(setCurrentRow(key));
    };

    const save = () => {
        props.openModals.name();
    };

    const deleteRowFunc = (index) => () => {
        props.deleteRow(index);
    };

    return <CompactListWidgetPanel
        id={props.id}
        {...props}
        save={save}
        setCurrentRow={setCurrentRowFunc}
        deleteRow={deleteRowFunc}
        openModals={props.openModals}
        addRowItem={props.addRowItem}
    />
};

const mapStateToProps = (state) => ({
    title: state.widget.title,
    titleCount: state.widget.titleCount,
    linkTitle: state.widget.linkTitle,
    footerText: state.widget.footerText,
    rows: state.listWidget.rows
});

export default withOpenModals(connect(mapStateToProps, {
    setTitle,
    setLinkTitle,
    setTitleCount,
    setActiveModal,
    setRowTitle,
    setRowTitleUrl,
    setRowIconId,
    setRowDescr,
    setRowAddress,
    setRowTime,
    setRowText,
    saveListWidgetSettings: publishListWidgetSettings,
    addRowItem,
    updateRows,
    deleteRow,
})(CompactListWidgetPanelContainer));
