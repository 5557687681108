import React, {useEffect} from "react";
import {Avatar, Gradient, Group, Panel, SimpleCell, SizeType, Text, Title} from "@vkontakte/vkui";
import {Icon28UsersOutline} from "@vkontakte/icons";
import {useIsAdmin} from "../../../../redux/selectors";
import {connect} from "react-redux";
import THPanelHeader from "../../../../common/components/THPanelHeader";
import * as PropTypes from "prop-types";
import AdminNavigation from "./AdminNavigation";
import UserNavigation from "./UserNavigation";
import {setHistory} from "../../../../redux/router/routerReducer";
import {setActiveTab} from "../../../../redux/root/rootReducer";
import {useRouter} from "@happysanta/router";
import {PAGES} from "../../../../config/routes";

const Home = (props) => {
    const {setHistory} = props;
    const router = useRouter();
    const isAdmin = useIsAdmin();

    useEffect(() => {
        if (!setHistory) return;
        props.setActiveTab(null);
    }, [setHistory]);

    return (
        <Panel id={props.id}>
            <THPanelHeader hasBackBtn={false}/>
            <Group>
                <Gradient
                    style={{
                        margin: props.sizeX === SizeType.REGULAR ? "-7px -7px 0 -7px" : 0,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        padding: 32,
                    }}
                >
                    <Avatar src={props.group.icon} badge="online" size={96}/>
                    <Title
                        className={"word-break"}
                        style={{marginBottom: 8, marginTop: 20}}
                        level="2"
                        weight="2"
                    >
                        {props.group.name}
                    </Title>
                    <Text
                        className={"word-break"}
                        style={{marginBottom: 24, color: "var(--text_secondary)"}}
                        weight={"regular"}
                        dangerouslySetInnerHTML={{__html: props.group.subTitle}}
                    />
                </Gradient>
                <SimpleCell
                    onClick={() => router.pushPage(PAGES.SUBSCRIPTIONS)}
                    expandable
                    before={<Icon28UsersOutline/>}
                >
                    {isAdmin ? "Страницы для рассылок" : "Список рассылок"}
                </SimpleCell>
                {
                    isAdmin ? <AdminNavigation/> : <UserNavigation/>}
            </Group>
        </Panel>
    );
}

Home.propTypes = {
    id: PropTypes.string.isRequired,
    sizeX: PropTypes.string,
}

let mapStateToProps = state => ({
    group: state.app.group,
    viewer: state.app.viewer,
    settings: state.app.settings
})

export default connect(mapStateToProps, {
    setHistory,
    setActiveTab
})(Home);