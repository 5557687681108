import React, {useEffect, useState} from 'react';
import stl from './CompactListWidgetStyles.module.css'
import s from '../../common/styles/WidgetCommonStyles.module.css';
import sList from '../../common/styles/WidgetList.module.css';
import Icon16Add from '@vkontakte/icons/dist/16/add';
import {Cell} from "@vkontakte/vkui";
import '../../../../styles/css/index.css';
import {DivForText} from "../../common/components/DivForText/DivForText";
import Image from "../../common/components/WidgetIcon";
import DeleteButton from "../../common/components/Buttons/DeleteButton";
import FieldWithSmiles from "../../common/components/FieldWithSmiles/FieldWithSmiles";
import {unescapeHtml} from "../../../../common/helpers/functions";
import AddNewItemButton from "../../common/components/Buttons/AddNewItemButton";
import FlatButton from "../../common/components/Buttons/FlatButton";

const CompactListWidgetPanel = (props) => {
    const [active, setActive] = useState(
        props.rows.map(() =>
            ({
                title: false,
                titleCount: false,
                text: false,
                time: false,
                descr: false,
                footer: false,
            })
        )
    );
    const [items, setItems] = useState(props.rows);

    useEffect(() => {
        setActive(props.rows.map(() => ({
                title: false,
                titleCount: false,
                text: false,
                time: false,
                descr: false,
                footer: false,
            })
        ));
    }, [props.rows]);

    useEffect(() => {
        setItems(props.rows);
    }, [props.rows]);

    const setActiveField = (field, key, value) => {
        return () => {
            setActive(active.map((elem, i) => i === key ? {...elem, [field]: value} : elem))
        }
    };

    const canAddNewRow = () => {

        const array = items.map((elem) => {
            return !!elem.text; // true - значит есть текст
        });
        if (array.includes(true)) {
            return items.length < 3
        } else {
            return items.length < 6
        }
    };


    const emptyText = () => {
        return <span style={{color: "red"}}>Текст отсутствует</span>
    };

    const setRowDescr = (key) => {
        return (value) => {
            props.setRowDescr(key, value);
        }
    };
    const setRowAddress = (key) => {
        return (value) => {
            props.setRowAddress(key, value);
        }
    };
    const setRowTime = (key) => {
        return (value) => {
            props.setRowTime(key, value);
        }
    };
    const setRowText = (key) => {
        return (value) => {
            props.setRowText(key, value);
        }
    };


    const onBlurFunc = (field, key) => {
        return () => {
            setActiveField(`${field}`, key, false)();
        }
    };

    const onClick = (func, key) => {
        return () => {
            props.setCurrentRow(key);
            func();
        }
    };


    const rows = items.map((elem, key) => {
        return (
            <div className={stl.listItem} key={key}>
                <Image
                    onClick={onClick(props.openModals.icon, key)}
                    elem={elem}
                    styleClass={sList.listItem__icon}
                />
                <div className={sList.listItem__content} style={{paddingRight: "10px"}}>
                    <div
                        onClick={() => {
                            props.setCurrentRow(key);
                            props.openModals.title();
                        }}
                        className={s.textTitle}
                    >
                        {elem.title ? unescapeHtml(elem.title) : emptyText()}
                    </div>

                    {active[key].descr ?
                        <FieldWithSmiles
                            updateModalHeight={null}
                            limit={100}
                            text={elem.descr}
                            setText={setRowDescr(key)}
                            type={"textarea"}
                            required={false}
                            onBlurFunc={onBlurFunc('descr', key)}
                        />
                        :
                        <DivForText
                            classname={s.textDescr}
                            text={elem.descr}
                            style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap",
                                maxWidth: "100%"}}
                            onClick={setActiveField("descr", key, true)}
                        />
                    }

                    {active[key].address ?
                        <FieldWithSmiles
                            updateModalHeight={null}
                            limit={100}
                            text={elem.address}
                            setText={setRowAddress(key)}
                            type={"input"}
                            required={false}
                            onBlurFunc={onBlurFunc('address', key)}
                        /> :
                        <DivForText
                            classname={s.address}
                            text={elem.address}
                            onClick={setActiveField("address", key, true)}
                        />
                    }

                    {active[key].time ?
                        <FieldWithSmiles
                            updateModalHeight={null}
                            limit={100}
                            text={elem.time}
                            setText={setRowTime(key)}
                            type={"input"}
                            required={false}
                            onBlurFunc={onBlurFunc('time', key)}
                        /> :
                        <DivForText
                            classname={s.time}
                            text={elem.time}
                            onClick={setActiveField("time", key, true)}
                        />
                    }
                    {active[key].text ?
                        <FieldWithSmiles
                            updateModalHeight={null}
                            limit={300}
                            text={elem.text}
                            setText={setRowText(key)}
                            type={"textarea"}
                            required={false}
                            onBlurFunc={onBlurFunc('text', key)}
                        /> :
                        <DivForText
                            classname={s.fullDescription}
                            text={elem.text}
                            onClick={setActiveField("text", key, true)}
                        />
                    }
                </div>
                <div style={{display: "flex", width: "100%", justifyContent: "end"}}>
                    <FlatButton
                        onClick={() => {
                            props.setCurrentRow(key);
                            props.openModals.button();
                        }}
                        style={{maxWidth: "174px"}}
                    >
                        {elem.button ? unescapeHtml(elem.button) : <Icon16Add/>}
                    </FlatButton>
                </div>
            </div>
        )
    });

    return (
        <>
            <div className={sList.list}>
                {rows.map((item, index) => (
                    <Cell
                        style={{width: "100%"}}
                        className={sList.Cell + " " + stl.Cell}
                        key={index}
                        draggable
                        onDragFinish={({from, to}) => {
                            props.updateRows(from, to);
                        }}
                    >
                        {
                            rows.length !== 1
                            &&
                            <DeleteButton styles={{right: 0, top: 3}} deleteFunc={props.deleteRow(index)}/>
                        }
                        {item}
                    </Cell>
                ))}
            </div>
            {
                canAddNewRow()
                &&
                <AddNewItemButton addRowItem={props.addRowItem}/>
            }
        </>
    )
};

export default CompactListWidgetPanel;
