import React, {useEffect} from 'react';
import {Root} from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import {VIEWS} from "./config/routes";
import {connect, useDispatch} from "react-redux";
import RootLayout from "./RootLayout";
import SubscriptionsView from "./views/subscriptions/SubscriptionsView";
import WidgetsView from "./views/widgets/WidgetsView";
import MainView from "./views/main/MainView";
import GuestView from "./views/guest/GuestView";
import {setModal} from "./redux/root/rootReducer";
import WidgetModalRoot from "./views/widgets/panels/WidgetModalRoot";
import {useLocation, useRouter} from "@happysanta/router";

const Views = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const router = useRouter();

    useEffect(() => {
        if (location.getViewId() === VIEWS.WIDGETS) {
            dispatch(setModal(<WidgetModalRoot/>));
        } else {
            dispatch(setModal(null));
        }
    }, [location])

    return (
        <RootLayout>
            <Root activeView={location.getViewId()}>
                <GuestView
                    id={VIEWS.GUEST}
                    activePanel={location.getViewActivePanel(VIEWS.GUEST)}
                    onSwipeBack={() => router.popPage()}
                    history={location.hasOverlay() ? [] : location.getViewHistory(VIEWS.GUEST)}
                />
                <MainView
                    id={VIEWS.MAIN}
                    activePanel={location.getViewActivePanel(VIEWS.MAIN)}
                    onSwipeBack={() => router.popPage()}
                    history={location.hasOverlay() ? [] : location.getViewHistory(VIEWS.MAIN)}
                />
                <SubscriptionsView
                    id={VIEWS.SUBSCRIPTIONS}
                    activePanel={location.getViewActivePanel(VIEWS.SUBSCRIPTIONS)}
                    onSwipeBack={() => router.popPage()}
                    history={location.hasOverlay() ? [] : location.getViewHistory(VIEWS.SUBSCRIPTIONS)}
                />
                <WidgetsView
                    id={VIEWS.WIDGETS}
                    activePanel={location.getViewActivePanel(VIEWS.WIDGETS)}
                    onSwipeBack={() => router.popPage()}
                    history={location.hasOverlay() ? [] : location.getViewHistory(VIEWS.WIDGETS)}
                />
            </Root>
        </RootLayout>
    );
}

let mapStateToProps = state => ({
    activeView: state.router.activeView,
    activePanel: state.router.activePanel,
})

export default connect(mapStateToProps)(Views);
