import {appAPI} from "../../api/app/appAPI";
import {showSnackbar} from "../../redux/root/rootReducer";
import {useDispatch} from "react-redux";
import useLoadSettings from "./useLoadSettings";
import useGoBack from "./useGoBack";

const useUpdateSettings = () => {
    const dispatch = useDispatch();
    const loadSettings = useLoadSettings();
    const goBack = useGoBack();

    async function updateSettings(params, {setLoading}) {
        setLoading(true);
        try {
            await appAPI.saveSettings(params)
            await loadSettings();
            setLoading(false);
            dispatch(showSnackbar({
                success: true,
                text: "Настройки обновлены"
            }));
            goBack();
        } catch (e) {
            setLoading(false);
        }
    }

    return updateSettings;
};

export default useUpdateSettings;