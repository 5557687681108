import {Router} from '@happysanta/router';
import {routes} from "./config/routes";

export const router = new Router(routes, {
    noSlash: true
});

/*router.on('update', (nextRote, oldRoute) => {
    nextRote.getPageId() // /product/:id([0-9]+)
    nextRote.getParams() // { id: "12" }
    nextRote.getPanelId() // panel_product
    nextRote.getViewId() // view_main
    nextRote.getLocation() // /product/12
    nextRote.isModal() // false
    nextRote.isPopup() // false
    nextRote.hasOverlay() // false

    if (oldRoute) {
        console.log(`move from page ${oldRoute.getLocation()} -> ${nextRote.getLocation()}`);
    } else {
        console.log(`enter to page ${nextRote.getLocation()}`);
    }
});*/

