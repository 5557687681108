import React, {useEffect, useState} from "react";
import {Panel, PanelSpinner} from "@vkontakte/vkui";
import {subscriptionsAPI} from "../../../../api/subscriptions/subscriptionsAPI";
import THPanelHeader from "../../../../common/components/THPanelHeader";
import * as PropTypes from "prop-types";
import SubscriptionEditor from "./SubscriptionEditor";
import {useParams} from "@happysanta/router";

const SubscriptionEditorContainer = (props) => {
    const hash = useParams();
    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(false);
    const [pageTitle, setPageTitle] = useState('Добавить подписную');

    useEffect(() => {
        if (item || !hash?.item) return;

        const fetchSubscription = async (subId) => {
            setLoading(true);
            try {
                const r = await subscriptionsAPI.getSubscription(subId, {replaceVars: false});
                if (!r?.subscriptions?.length) props.goBack();
                const data = r.subscriptions[0];
                setItem(data);
            } catch (e) {
            }

            setLoading(false);
        }

        setPageTitle('Редактирование');
        fetchSubscription(hash.item);
    }, [hash.item]);

    if (loading) {
        return (
            <Panel id={props.id}>
                <THPanelHeader title={'Загрузка...'}/>
                <PanelSpinner/>
            </Panel>
        )
    }

    return <SubscriptionEditor
        item={item}
        id={props.id}
        pageTitle={pageTitle}
    />
}

SubscriptionEditorContainer.propTypes = {
    id: PropTypes.string.isRequired,
};

export default SubscriptionEditorContainer;