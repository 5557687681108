import React from "react";
import {Button, Text} from "@vkontakte/vkui";
import * as PropTypes from "prop-types";
import {unescapeHtml} from "../../../../common/helpers/functions";

const SubscriptionActionButton = ({item, text, stretched = false, onClick}) => {
    return (
        <Button
            mode={item.isSubscribed ? 'secondary' : 'primary'}
            className={"action-btn-multiline"}
            size={'l'}
            stretched={stretched}
            onClick={onClick}
        >
            <Text weight={"regular"}>{unescapeHtml(text)}</Text>
        </Button>
    );
}

SubscriptionActionButton.propTypes = {
    item: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    stretched: PropTypes.bool,
}

export default SubscriptionActionButton;