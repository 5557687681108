import React, {useState} from "react";
import {Panel, PullToRefresh} from "@vkontakte/vkui";
import THPanelHeader from "../../../../common/components/THPanelHeader";
import SubListContainer from "../SubscriptionsList/components/SubListContainer";
import {FOR_USER} from "../../../../config/SubscriptionTypes";

const UserSubscriptions = props => {
    const [isFetching, setIsFetching] = useState(false);
    return (
        <Panel id={props.id}>
            <THPanelHeader hasHomeBtn={true} title={'Мои подписки'}/>
            <PullToRefresh
                onRefresh={() => setIsFetching(true)}
                isFetching={isFetching}
            >
                <SubListContainer
                    mode={FOR_USER}
                    isFetching={isFetching}
                    setIsFetching={setIsFetching}
                    useCache={false}
                />
            </PullToRefresh>
        </Panel>
    )
}

export default UserSubscriptions;