import {useFirstPageCheck, useRouter} from "@happysanta/router";
import {PAGES} from "../../config/routes";

const useGoBack = () => {
    const router = useRouter();
    const isFirstPage = useFirstPageCheck();

    return () => {
        if (isFirstPage) {
            router.replacePage(PAGES.HOME)
        } else {
            router.popPage()
        }
    }
};

export default useGoBack;