import React from 'react';
import CoverListWidgetPanel from "./CoverListWidgetPanel";
import {connect, useDispatch} from "react-redux";
import {setLinkTitle, setTitle, setTitleCount} from "../../../../redux/parts/widgetReducer";
import {hidePopout, setActiveModal, showPopout} from "../../../../redux/root/rootReducer";
import {
    addRowItem,
    deleteRow,
    publishListWidgetSettings,
    setCurrentRow,
    setRowDescr,
    setRowIconId,
    setRowIconUrl,
    setRowText,
    setRowTitle,
    setRowTitleUrl,
    updateRows
} from "../../../../redux/parts/widgetListReducer";
import {Alert, ScreenSpinner} from "@vkontakte/vkui";
import withOpenModals from "../../../../common/hoc/withOpenModals";
import WidgetsService from "../../../../common/logic/WidgetsService";

const CoverListWidgetPanelContainer = (props) => {

    const dispatch = useDispatch();
    const setCurrentRowFunc = (key) => {
        dispatch(setCurrentRow(key));
    };

    const showPopoutMessage = (title, message = '') => {
        const onClose = () => dispatch(hidePopout());
        dispatch(showPopout(<Alert
            actions={[{
                title: 'Ок',
                autoclose: true,
                mode: 'cancel'
            }]}
            onClose={onClose}
        >
            <h2>{title}</h2>
            <p>{message}</p>
        </Alert>));
    };

    const onChangeImg = async (event, key) => {
        const targetFile = event.target.files[0];
        const allowTypes = ['image/png', 'image/jpeg'];

        if (!targetFile) {
            showPopoutMessage('Файл не выбран');
            return;
        }

        if (!allowTypes.includes(targetFile.type)) {
            showPopoutMessage('Недопустимый файл', 'Для загрузки файла выберите изображение в формате (jpg, jpeg, png)');
            return;
        }

        props.setCurrentRow(key);
        props.showPopout(<ScreenSpinner/>);
        try {
            const response = await WidgetsService.uploadCoverListIcon(targetFile);
            props.hidePopout();
            props.setRowIconId(key, response.icon);
            props.setRowIconUrl(key, response.images[2].url);
            props.setActiveModal(null);
            return true;
        } catch (e) {
            showPopoutMessage('Ошибка', e.text);
        }
    };

    const deleteRowFunc = (index) => () => {
        props.deleteRow(index);
    };

    return <CoverListWidgetPanel
        id={props.id}
        {...props}
        deleteRow={deleteRowFunc}
        setCurrentRow={setCurrentRowFunc}
        openModals={props.openModals}
        addRowItem={props.addRowItem}
        onChangeImg={onChangeImg}
    />
};

const mapStateToProps = (state) => ({
    title: state.widget.title,
    titleCount: state.widget.titleCount,
    linkTitle: state.widget.linkTitle,
    footerText: state.widget.footerText,
    search: state.app.searchObject,
    currentRow: state.listWidget.currentRow,
    rows: state.listWidget.rows
});

export default withOpenModals(connect(mapStateToProps, {
    setTitle,
    setLinkTitle,
    setTitleCount,
    setActiveModal,
    setRowTitle,
    setRowTitleUrl,
    setRowIconId,
    setRowDescr,
    setRowText,
    setCurrentRow,
    saveListWidgetSettings: publishListWidgetSettings,
    addRowItem,
    updateRows,
    deleteRow,
    showPopout,
    hidePopout,
    setRowIconUrl,
})(CoverListWidgetPanelContainer));
