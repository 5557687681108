import React, {useState} from "react";
import {Button, Footer, FormItem, FormLayout, Group, Input, Link, Panel, Spacing, Text} from "@vkontakte/vkui";
import THPanelHeader from "../../../../common/components/THPanelHeader";
import {useSelectorGroup} from "../../../../redux/selectors";
import useUpdateSettings from "../../../../common/hooks/useUpdateSettings";
import {isValid} from "../../../../common/helpers/functions";
import {showSnackbar} from "../../../../redux/root/rootReducer";
import {useDispatch} from "react-redux";

const AnalyticsSettings = props => {
    const dispatch = useDispatch();
    const group = useSelectorGroup();
    const updateSettings = useUpdateSettings();
    const [loading, setLoading] = useState(false);
    const [vkPixel, setVKPixel] = useState(group.vkPixel ?? '');
    const [yaMetrika, setYAMetrika] = useState(group.yaMetrika ?? '');

    const pixelPattern = new RegExp(/^VK-RTRG-\d{1,8}-[A-Za-z\d]{1,7}$/);
    const metrikaPattern = new RegExp(/^\d{1,10}$/);

    const validationRules = [
        vkPixel.length > 0 ? vkPixel.match(pixelPattern) : true,
        yaMetrika.length > 0 ? yaMetrika.match(metrikaPattern) : true,
    ]

    const onSubmit = async () => {
        setLoading(true);
        if (!isValid(validationRules)) {
            dispatch(showSnackbar({
                text: "Проверьте правильность заполнения полей"
            }));
            setLoading(false);
            return;
        }
        await updateSettings({
            vkPixel,
            yaMetrika,
        }, {setLoading});
    }

    return (
        <Panel id={props.id}>
            <THPanelHeader/>
            <Group>
                <FormLayout>
                    <FormItem
                        top="Пиксель VK"
                        status={
                            vkPixel && !vkPixel.match(pixelPattern) ? "error" : ""
                        }
                        bottom={"Код счетчика. Пример: VK-RTRG-1405321-eNoGx"}
                    >
                        <Input
                            type="text"
                            name="name"
                            value={vkPixel ? vkPixel : ""}
                            onChange={(e) => {
                                setVKPixel(e.target.value)
                            }}
                            maxLength={25}
                        />
                    </FormItem>
                    <FormItem
                        top="Яндекс Метрика"
                        status={
                            yaMetrika && !yaMetrika.match(metrikaPattern) ? "error" : ""
                        }
                        bottom={"Код счетчика. Пример: 885880500"}
                    >
                        <Input
                            type="text"
                            name="desc"
                            value={yaMetrika ? yaMetrika : ""}
                            onChange={(e) => {
                                setYAMetrika(e.target.value)
                            }}
                            maxLength={12}
                        />
                    </FormItem>

                    <FormItem>
                        <Button size="l" stretched onClick={onSubmit} loading={loading}>
                            <Text weight={"regular"}>Сохранить</Text>
                        </Button>
                    </FormItem>
                </FormLayout>
            </Group>
            <Spacing size={1} separator={"bottom"}/>
            <Footer className={"text-muted"} style={{padding: "0 16px"}}>
                В документации есть <Link href={"https://smm.targethunter.help/miniapp/analitika"} target={"_blank"}>подробная инструкция</Link> по настройке
            </Footer>
        </Panel>
    )
}

export default AnalyticsSettings;