import React, {useState} from 'react';
import s from '../widgetStyles.module.css';
import FieldWithSmiles from "../../common/components/FieldWithSmiles/FieldWithSmiles";
import {nl2br} from "../../../../common/helpers/functions";

const TextWidgetPanel = (props) => {
    const [active, setActive] = useState({
        name: false,
        title: false,
        titleCount: false,
        text: false,
        description: false,
        footer: false,
    });

    const emptyText = () => {
        return <span style={{color: "red"}}>Текст отсутствует</span>
    };

    const onBlurFunc = (key) => {
        return () => {
            setActive({...active, [key]: false});
        }
    };

    return (
        <>
            <div className={s.container__descriptionBlock}>
                {!active.text ?
                    (
                        props.text ?
                            <div
                                className={s.descriptionBlock__text}
                                onClick={() => {
                                    setActive({...active, text: true})
                                }}
                                dangerouslySetInnerHTML={{__html: nl2br(props.text)}}
                            /> :
                            <div className={s.descriptionBlock__text}
                                 onClick={() => {
                                     setActive({...active, text: true})
                                 }}>
                                {emptyText()}
                            </div>
                    ) :

                    <FieldWithSmiles
                        updateModalHeight={null}
                        limit={200}
                        text={props.text}
                        setText={props.setText}
                        type={"textarea"}
                        required={false}
                        onBlurFunc={onBlurFunc('text')}
                    />
                }
                {!active.description ?
                    (props.description ? <div
                                className={s.descriptionBlock__description}
                                onClick={() => {
                                    setActive({...active, description: true})
                                }}
                                dangerouslySetInnerHTML={{__html: nl2br(props.description)}}
                            /> :
                            <div className={s.descriptionBlock__description}
                                 onClick={() => {
                                     setActive({...active, description: true})
                                 }}>
                                {emptyText()}
                            </div>
                    ) :
                    <FieldWithSmiles
                        updateModalHeight={null}
                        limit={200}
                        text={props.description}
                        setText={props.setDescription}
                        type={"textarea"}
                        required={false}
                        onBlurFunc={onBlurFunc('description')}
                    />
                }
            </div>
        </>
    )
};

export default TextWidgetPanel;
