import React, {useEffect} from "react";
import {
    Alert,
    Avatar,
    Button,
    CardGrid,
    CellButton,
    Div,
    Group,
    List,
    Panel,
    Placeholder,
    Spacing,
    Subhead,
    Text,
    Title
} from "@vkontakte/vkui";
import bridge from "@vkontakte/vk-bridge";
import {hidePopout, showPopout} from "../../../../redux/root/rootReducer";
import {
    Icon16Add,
    Icon28CoffeeSteamOutline,
    Icon28GridLayoutOutline,
    Icon28LikeOutline,
    Icon28MailOutline,
    Icon56CheckCircleOutline
} from "@vkontakte/icons";
import Logo from '../../../../assets/img/moderator_logo.jpg';
import {useDispatch} from "react-redux";
import {resizeWindow} from "../../../../common/helpers/bridge";
import {AlertIcon, AlertTitle} from "../../../../common/components/Alerts/Components";
import THPanelHeader from "../../../../common/components/THPanelHeader";
import * as PropTypes from "prop-types";
import HelloCard from "./HelloCard";
import {getSearchObject} from "../../../../common/helpers/functions";
import useIgnoreChangeFragmentOnAndroid from "../../../../common/hooks/useIgnoreChangeFragmentOnAndroid";

const Hello = (props) => {
    const dispatch = useDispatch();
    const ignoreChangeFragmentOnAndroid = useIgnoreChangeFragmentOnAndroid();
    const onAddToCommunity = async () => {
        const search = getSearchObject();
        try {
            const res = await bridge.send("VKWebAppAddToCommunity", {});
            const appUrl = "https://vk.com/app" + search.vk_app_id + '_-' + res.group_id;
            dispatch(showPopout(
                <Alert
                    actions={[{
                        title: 'Продолжить',
                        mode: 'cancel',
                        autoclose: true
                    }]}
                    onClose={() => dispatch(hidePopout())}
                >
                    <AlertIcon>
                        <Icon56CheckCircleOutline fill={"green"} style={{textAlign: 'center'}}/>
                    </AlertIcon>
                    <AlertTitle>Приложение добавлено</AlertTitle>
                    <Spacing size={12}/>
                    <Text weight={"regular"}>Для продолжения работы, нажмите кнопку ниже</Text>
                    <Spacing size={24}/>
                    <Button size={'m'} mode={'primary'} stretched={true}
                            href={appUrl}
                            target={'_top'}>
                        <Text weight={"regular"}>Перейти в сообщество</Text>
                    </Button>
                </Alert>
            ));
        } catch (e) {/* пользователь отказался добавлять сервис в сообщество*/
        }
    };

    useEffect(() => {
        resizeWindow(null, 650);
    }, []);

    return (
        <Panel id={props.id}>
            <THPanelHeader hasBackBtn={false}/>
            <Group>
                <Placeholder
                    icon={<Avatar src={Logo} badge="online" size={96}/>}
                    header="TH Модератор"
                    action={
                        <Button
                            size="m"
                            sizeY={'regular'}
                            appearance={'positive'}
                            before={<Icon16Add/>}
                            onClick={onAddToCommunity}
                        >
                            <Text weight={"regular"}>Установить в сообщество</Text>
                        </Button>}
                >
                    Сервис для автоматизации бизнеса от компании TargetHunter<br/>
                    Установите в сообщество, чтобы начать работу
                </Placeholder>
            </Group>
            <Group>
                <Div>
                    <Title level={2} style={{textAlign: "center"}}>Наши возможности</Title>
                    <Subhead style={{textAlign: "center", marginTop: '8px'}} className={"text-muted"}>
                        Ваше конкурентное преимущество
                    </Subhead>
                </Div>
                <Spacing size={24}/>
                <CardGrid size="m" style={{display: "flex", alignItems: "stretch"}}>
                    <HelloCard
                        icon={<Icon28MailOutline/>}
                        header="Рассылки"
                        text="для быстрого контакта с вашей аудиторией"
                    />
                    <HelloCard
                        icon={<Icon28GridLayoutOutline/>}
                        header="Виджеты"
                        text="создаются за пару кликов в удобном конструкторе"
                    />
                </CardGrid>
                <CardGrid size="m" style={{display: "flex", alignItems: "stretch"}}>
                    <HelloCard
                        icon={<Icon28CoffeeSteamOutline/>}
                        header="24/7"
                        text="круглосуточная техническая поддержка по всем вопросам"
                    />
                    <HelloCard
                        icon={<Icon28LikeOutline/>}
                        header="Сообщество"
                        text="> 400 000 участников и тысячи полезных материалов"
                    />
                </CardGrid>
            </Group>
            <Group>
                <Div>
                    <Title level={2} style={{textAlign: "center"}}>Информация</Title>
                    <Subhead style={{textAlign: "center", marginTop: '8px'}} className={"text-muted"}>Полезные ссылки на
                        наши ресурсы</Subhead>
                </Div>
                <Spacing size={12}/>
                <List>
                    <CellButton
                        centered
                        onClick={ignoreChangeFragmentOnAndroid}
                        href={'https://smm.targethunter.ru'}
                        target={'_blank'}
                    >Полная версия сервиса</CellButton>
                    <CellButton
                        centered
                        onClick={ignoreChangeFragmentOnAndroid}
                        href={'https://vk.com/th.moderator'}
                        target={'_blank'}
                    >Сообщество TH Модератор</CellButton>
                    <CellButton
                        centered
                        multiline
                        onClick={ignoreChangeFragmentOnAndroid}
                        href={'https://smm.targethunter.help/'}
                        target={'_blank'}
                    >Документация по использованию сервиса</CellButton>
                    <CellButton
                        centered
                        onClick={ignoreChangeFragmentOnAndroid}
                        href={'https://vk.me/join/HaAoxyJ6UWFAYobJyvP2UqU3d4eJm6vadjg='}
                        target={'_blank'}
                    >Чат взаимопомощи</CellButton>
                    <CellButton
                        centered
                        onClick={ignoreChangeFragmentOnAndroid}
                        href={'https://vk.com/th.help'}
                        target={'_blank'}
                    >Поддержка TargetHunter</CellButton>
                    <CellButton
                        centered
                        onClick={ignoreChangeFragmentOnAndroid}
                        href={'https://vk.com/targethunter'}
                        target={'_blank'}
                    >Сообщество TargetHunter</CellButton>
                </List>
            </Group>
        </Panel>
    );
}

Hello.propTypes = {
    id: PropTypes.string.isRequired,
}

export default Hello;